import { getFamilyList, getFamilyReceive, getFamilyReport } from '@/services/api';

const familyTask = {
  namespace: 'familyTask',
  state: {
    familyList: {},
  },
  reducers: {
    updateList(state, { payload }) {
      return {
        ...state,
        familyList: {
          ...payload,
          countdown: payload.countdown * 1000,
        },
      };
    },
  },
  effects: {
    *getFamilyList({ payload }, { call, put }) {
      const { data } = yield call(getFamilyList, payload);
      yield put({
        type: 'updateList',
        payload: data,
      });
      return data;
    },
    *getFamilyReceive({ payload }, { call }) {
      const { data = [] } = yield call(getFamilyReceive, payload);
      return data;
    },
    *getFamilyReport({ payload }, { call }) {
      const res = yield call(getFamilyReport, payload);
      return res;
    },
  },
};

export default familyTask;

