import ajax from './ar/ajax';
import refresh from './ar/refresh';
import expection from './ar/expection';
import country from './ar/country';
import share from './ar/share';

const AR = {
  ...ajax,
  ...refresh,
  ...expection,
  ...country,
  ...share,
  blank: '<p>لا يوجد المحتوى المتعلق بها</p> <p>يرجى التحقق من القنوات الأخرى</p>',
  commonErr: 'النظام مشغول،</p><p>يرجى إعادة المحاولة لاحقا.</p>',
  loading: 'جاري تحميل...',
  retry: 'إعادة المحاولة',
  viewMore: 'عرض المزيد',
  more: 'المزيد',
  'month.select': 'اختر الشهر',
  hot: 'رائج',
  all: 'الكل',
  'month.select.day': 'حدد التاريخ',
  'coin.merchant.title': 'وكيل الشحن',
  'coin.merchant.set.password': 'قم بضبط كلمة السر الخاصة بالشحن',
  'coin.merchant.confirm.again': 'قم بتأكيد كلمة السر الخاصة بالشحن',
  'coin.merchant.enter.password': 'قم بادخال كلمة سر الشحن',
  'coin.merchant.enter.original.password': 'قم بادخال كلمة السر القديمة',
  'coin.merchant.enter.new.password': 'قم بادخال كلمة سر جديدة',
  'coin.merchant.confirm.new.again': 'قم بتأكيد كلمة السر الجديدة',
  'coin.merchant.change.password': 'تعديل كلمة السر',
  'coin.merchant.history.title': 'سجل المعاملات',
  'coin.merchant.user': 'المستخدم',
  'coin.merchant.chat': 'دردشة',
  'coin.merchant.history': 'السجل',
  'fission.relation.by.time': 'حسب الوقت',
  'fission.relation.by.amount': 'حسب المبلغ',
  'coin.merchant.coins.num': 'مخزون الكوينزات',
  'coin.merchant.transfer.coins': 'ارسال كوينزات',
  'coin.merchant.enter.user.id': 'قم بادخال ايدي المستخدم',
  'coin.merchant.search': 'بحث',
  'coin.merchant.not.found': 'لم يتم العثور علي المستخدم',
  'coin.merchant.enter.amount': 'قم بادخال الكمية',
  'coin.merchant.tips': 'سوف تقوم بارسال كوينزات الي هذا المستخدم ،\n قم بالتأكد من الاسم والعدد ، لايمكن التراجع عن \nهذة العملية ، اذا كان يوجد مشكلة بالبيانات ، التطبيق غير مسئول عنها',
  'coin.merchant.enter.transaction.password': 'قم بادخال كلمة السر الخاصة بالشحن',
  'common.cap.Confirm': 'تأكيد',
  'coin.merchant.enter': 'دخول',
  'coin.merchant.to': 'الى:',
  'coin.merchant.amount': 'مقدار:',
  'coin.merchant.id': 'ايدي',
  'coin.merchant.password.tips': 'يجب أن تتكون كلمة المرور من 8 أحرف على الأقل ، ويجب أن تتكون من أحرف وأرقام',
  'coin.merchant.password.error': 'كلمة السر خاطئة يرجى المحاولة مرة أخرى. ',
  'coin.merchant.success': 'نجاح التحويلات',
  'coin.merchant.list.title': 'اختيار الكمية',
  'coin.merchant.list.desc': 'إرسال الكوينزات يعادل الشحن ، سوف يزيد من خبرة VIP للمستخدم ايضا',
  'coin.merchant.transfer': 'إرسال',
  'coin.merchant.recharge.title': 'شحن بائع العملات',
  'coin.merchant.min.input': 'الحد الأدنى 1000',
  'coin.merchant.der.channel': 'طريقة الشحن:',
  'coin.merchant.der.channel.tips': '   إذا لم تتمكن من إتمام عملية الدفع بالطرق التالية، يرجى الاتصال برقم واتس آب التابع للفريق الرسمي للاستفسار عن عملية الشراء:',
  'coin.merchant.der.channel.tips.link': 'نسخ',
  'coin.merchant.der.current.country': 'تستخدم حاليًا قناة الدفع الخاصة بـ ',
  'coin.merchant.der.change.country': 'اختيار الدولة',
  'coin.merchant.der.modal.title': 'طرق الدفع',

  'common.cap.Recharge': 'الشحن',
  'coin.merchant.der.id': 'ايدي TalkTalk',
  'coin.merchant.der.name': 'اللقب في TalkTalk:',
  'coin.merchant.der.error': '*لا يوجد نتائج',
  'coin.merchant.der.amount': ' كمية الشحن:',
  'coin.merchant.der.redeem': 'تأكيد',
  'coin.merchant.der.fail': ' فشل الشحن',
  'coin.merchant.der.fail.tips': ' فشل شحن الحساب من فضلك تواصل معنا على TT_support@molelive.com',
  'coin.merchant.der.complete': ' تم الشحن',
  'coin.merchant.der.complete.tips': ' تم إرسال {number} كوينز بنجاح لـ{name}! قم بتسجيل الدخول لتحظى بالمزيد من المرح!',
  'coin.merchant.der.another': 'شحن حساب اخر',
  'coin.merchant.der.more': 'اكمال الشحن في هذا الحساب',
  'coin.merchant.der.problem': ' واجهتك مشاكل في الشحن ؟',
  'coin.merchant.der.bind.phone': 'ربط رقم الهاتف المحمول',
  'coin.merchant.der.sms.code': 'أدخل رقم رمز التحقق',
  'coin.merchant.der.sms.tips': 'تم الكشف عن نشاط غير طبيعي. لضمان أمان حسابك ، يرجى إنهاء عملية التحقق أدناه.',
  'coin.merchant.der.bind.phone.placeholder': 'رقم الهاتف',
  'coin.merchant.der.resend': 'إعادة إرسال',
  'common.cap.Continue': 'اكمل الهجوم',
  'coin.merchant.der.code.tips': 'إذا لا يمكنك الحصول على رمز التحقق، يرجى الاتصال بالفريق الرسمي بواتس أب:',
  'total.rank.gift': 'نجم<br />الأسبوع',
  'total.rank.hour': 'كل ساعة',
  'total.rank.day': 'يومي',
  'total.rank.week': 'اسبوعي',
  'total.rank.month': 'شهري',
  'total.rank.last.hour': 'توب 3 منذ ساعة',
  'total.rank.last.day': 'توب 3  منذ يوم',
  'total.rank.last.week': 'توب 3  منذ اسبوع',
  'total.rank.last.month': 'توب 3  منذ شهر',
  'total.rank.this.hour': 'الساعة الحالية',
  'total.rank.this.day': 'اليوم',
  'total.rank.this.week': 'الاسبوع الحالي',
  'total.rank.this.month': 'الشهر الحالي',
  'total.rank.gap': 'الفرق الخاص بك {X} ',
  'total.rank.hide': 'الاخفاء في التصنيفات',
  'total.rank.hide.name': 'المستخدم المجهول',
  'total.rank.hide.info': 'المعلومات المخفية',
  'total.rank.help.title': 'قواعد التصنيفات',
  'total.rank.help': `
  <h1>نوع التصنيفات</h1>
  <p>تصنيف الغرف : كل الغرف داخل التطبيق سيتم تصنيفها حسب كمية الهدايا المرسلة داخل الغرفة</p>
  <p>تصنيف ارسال الهدايا : جميع المستخدمين داخل التطبيق سيتم تصنيفهم حسب عدد الكوينزات المستخدمة في ارسال الهدايا</p>
  <p>تصنيف جذابية : جميع المستخدمين داخل التطبيق سيتم تصنيفهم حسب عدد الكوينزات التي حصلوا عليها</p>
  <h1>مدة التصنيفات</h1>
  <p>مدة التصنيفات عادة تكون : كل ساعة، كل يوم، كل اسبوع، كل شهر </p>
  <p>سوف يظهر التصنيفات توب 3 من التصنيف السابق ، وتوب 50 من التصنيف الحالي ، يتم تحديثهم كل دقيقة</p>
  <p>تجديد التصنيفات : يتم تجديد التصنيف اليومي الساعة ٥ فجرا بتوقيت السعودية ، يتم تجديد التصنيف الاسبوعي كل اثنين الساعة 5 فجرا ، يتم تجديد التصنيف الشهري كل يوم 1 بداية الشهر الساعة 5 فجرا</p>
  <h1>حماية الخصوصية</h1>
  <p>على الرغم ان بعض التصنيفات لن تظهر ارقام محددة ، فان الفرق بين التصنيفات السابقة سيتم عرضها. لن يتم عرض الفرق بين توب 3</p>`,
  'total.rank.help.eu.ios.hide': `
  <h1>مدة التصنيفات</h1>
  <p>سوف يظهر التصنيفات توب 3 من التصنيف السابق ، وتوب 50 من التصنيف الحالي ، يتم تحديثهم كل دقيقة</p>
  <p>تجديد التصنيفات : يتم تجديد التصنيف اليومي الساعة ٥ فجرا بتوقيت السعودية ، يتم تجديد التصنيف الاسبوعي كل اثنين الساعة 5 فجرا ، يتم تجديد التصنيف الشهري كل يوم 1 بداية الشهر الساعة 5 فجرا</p>
  <h1>حماية الخصوصية</h1>
  <p>على الرغم ان بعض التصنيفات لن تظهر ارقام محددة ، فان الفرق بين التصنيفات السابقة سيتم عرضها. لن يتم عرض الفرق بين توب 3</p>`,
  'total.rank.help.eu': `
  <h1>نوع التصنيفات</h1>
  <p>تصنيف ارسال الهدايا : جميع المستخدمين داخل التطبيق سيتم تصنيفهم حسب عدد الكوينزات المستخدمة في ارسال الهدايا</p>
  <p>تصنيف جذابية : جميع المستخدمين داخل التطبيق سيتم تصنيفهم حسب عدد الكوينزات التي حصلوا عليها</p>
  <h1>مدة التصنيفات</h1>
  <p>سوف يظهر التصنيفات توب 3 من التصنيف السابق ، وتوب 50 من التصنيف الحالي ، يتم تحديثهم كل دقيقة</p>
  <p>تجديد التصنيفات : يتم تجديد التصنيف اليومي الساعة ٥ فجرا بتوقيت السعودية ، يتم تجديد التصنيف الاسبوعي كل اثنين الساعة 5 فجرا ، يتم تجديد التصنيف الشهري كل يوم 1 بداية الشهر الساعة 5 فجرا</p>
  <h1>حماية الخصوصية</h1>
  <p>على الرغم ان بعض التصنيفات لن تظهر ارقام محددة ، فان الفرق بين التصنيفات السابقة سيتم عرضها. لن يتم عرض الفرق بين توب 3</p>`,
  'common.cap.Ranking': 'تصنيفات',
  'total.rank.room': 'الغرف',
  'total.rank.sent': 'الداعمين',
  'common.up.RECEIVED': 'جذابية',
  'total.rank.daily': 'يومي',
  'common.up.RULES': 'القواعد',
  'common.up.DAYS': 'يوم',
  'common.up.HOURS': 'ساعة',
  'common.up.MINS': 'دقيقة',
  'common.cap.Successfully.Collected': 'تم الجمع بنجاح',
  'common.cap.Countdown.to.Start': 'الحدث على وشك البدء',
  'common.cap.Countdown': 'العد التنازلي',
  'common.cap.Day': 'أيام',
  'common.cap.Hour': 'ساعات',
  'common.cap.Min': 'دقيقة',
  'common.cap.Sec': 'ثانية',
  'common.cap.Rules': 'القواعد',
  'common.cap.event.over': 'لقد انتهي هذا الحدث',
  'recharge.event.tab.1': 'تقدم الشحن',
  'recharge.event.amount.reached': 'كمية الكوينز المشحونة ',
  'recharge.event.ranking.list': 'قائمة الترتيب',
  'recharge.event.group': 'المجموعة',
  'recharge.event.next.1': 'تحتاج فقط إلى ',
  'recharge.event.next.2': ' عملة للوصول إلى المستوى التالي',
  'recharge.event.rewards.for': 'مكافات ',
  'recharge.event.trouble': 'هل تواجه مشكلة في الشحن؟',
  'recharge.event.done': 'مبروك! لقد وصلت إلى أعلى مستوى! استمتع بالمكافآت الخاصة بك الآن!',
  'fission.trouble': 'هل تواجه مشكلة في الشحن؟',
  'common.cap.No': 'لا توجد مشكلة',
  'common.cap.Unfortunately.Yes': 'نعم للأسف',
  'common.cap.Waiting': 'في انتظار',
  'common.cap.update': 'يرجى تحديث الإصدار لاستخدام الميزات الجديدة',
  'common.cap.Customer.Server': 'خدمة العملاء',
  'common.cap.Ranking.click': 'انقر على',
  'common.cap.Ranking.click.text': 'للتحقق من الجوائز المختلفة',
  'common.cap.Collected': 'تم الجمع',
  'common.cap.Limited.Gifts': 'هدايا محدودة',
  'common.cap.Collect': 'استلام',
  'family.football.target': 'تارجت هدايا الكوينز',
  'dream.city.rule.reward': 'القواعد \nوالمكافأت',
  'dream.city.city.map': ' خريطة المدينة',
  'dream.city.rules': 'قواعد الحدث',
  'dream.city.supply.reward': 'مكافآت محطة الإمداد',
  'dream.city.wealth': 'مدينة الثروة',
  'dream.city.charm': 'مدينة الجاذبية',
  'dream.city.wealth.rank': 'الثروة',
  'dream.city.charm.rank': 'الجاذبية',
  'dream.city.current.coins': 'الكوينز الحالية',
  'dream.city.current.diamonds': 'الماس الحالي',
  'dream.city.go': 'اذهب',
  'dream.city.will.get': 'سوف تحصل على',
  'dream.city.received': 'تهانينا! ، لقد استلمت',
  'dream.city.top5': 'سوف يحصل أفضل 5 مستخدمين يصلون إلى محطة الإمداد في أسرع وقت ممكن على مكافآت الأمداد.',
  'dream.city.waiting': 'بالانتظار',
  'common.cap.Go': 'اذهب',
  'lucky.darw.ruls.1': 'أرسل الهدايا في غرف الدردشة الصوتية لتحصل على فرصة السحب ',
  'lucky.darw.ruls.2': 'كل 5000 كوينز قمت بارسالها كا هدايا في الغرفه = فرصة سحب واحده ',
  'lucky.darw.ruls.3': ' مثال : ان قمت بارسال هدايا داخل الغرف بقيمة 50,000 كوينز = 10 فرص للسحب ، 100,000 كوينز = 20 فرص للسحب  . كلما ساهمت اكثر في الغرفه زادت عدد سحوباتك',
  'lucky.darw.ruls.4': 'اذا انتهى اليوم ولم تستعمل سحوباتك ، فستتم إزالة جميع السحوبات والعودة إلى 0 في نهاية النشاط',
  'lucky.darw.number': 'عدد السحوبات الخاصة بي ',
  'lucky.darw.reset.time': 'العد التنازلي',
  'lucky.darw.ranking': 'قائمة الترتيب',
  'lucky.darw.use': 'استخدم الان',
  'lucky.darw.no.times': 'ليس لديك سحوبات كافية ، اذهب للغرف و ارسل الهدايا',
  'lucky.darw.end': 'النشاط ليس قيد التقدم',
  'lucky.darw.coin.one.times': 'دوران 1 مرة بقيمة',
  'lucky.darw.coin.draw': 'دورة',
  'lucky.darw.coin.more.times': ' مرة بقيمة ',
  'lucky.darw.coin.more.will.cost': 'سيكلف',
  'fission.coins': 'كوينز',
  'common.recharge.text': 'الكوينزات غير كافية، هل ترغب باعادة الشحن؟',
  'fission.cancel': 'إلغاء',
  'lucky.darw.title': 'اسحب واربح',
  'gacha.play.1': 'افتح',
  'gacha.play.2': 'مرات',
  'gacha.play.3': 'مرة',
  'common.cap.Congratulations': 'تهانينا',
  'common.up.OK': 'تم',
  'lucky.darw.coin.one.times.two': 'اختر وانقر على اذهب',
  'common.Cap.Dating': 'المواعدة',
  'cp.list.limit.tips': 'إرسل الهدايا المطلوبة لكي تصل إلى المستوى، فيمكنك أن تشغّل موقع المواعدة.',
  'cp.list.unlock.contributor': 'مساهم الموقع',
  'common.Cap.Top1': 'Top1',
  'cp.list.cur.reward.text': 'افتح هذا الموقع لتحصل على المكافآت التالية.',
  'iftar.total.ranking': 'القائمة الاجمالية',
  'iftar.congratulations.unlocking': 'مبروك علي مشاركتك في فتح الطبق:',
  'iftar.you.received': 'لقد استلمت:',
  'activity.gift.list.send': 'المساهمين',
  'iftar.daily.ranking': 'القائمة اليومية',
  'iftar.reward.target': 'الهدف من مكافآت الكوينزات',
  'common.cap.Rewards': 'الجوائز',
  'common.up.SECS': 'ثانية',
  'lucky.recharge.draw.start': 'ابدأ اليانصيب',
  'lucky.recharge.draw.num': 'تذاكر السحب المتاحة لي',
  'lucky.recharge.draw.record': 'عرض سجل الفوز الخاص بي',
  'lucky.recharge.tab.wheel': 'عجلة الحظ',
  'lucky.recharge.tab.grid': 'تجمع جوائز الثروة',
  'lucky.recharge.wheel.tip.text.1': 'في كل مرة تقوم فيها بإعادة الشحن إلى مبلغ معين ، يمكنك الحصول على تذكرة يانصيب ، وربح 100%!',
  'lucky.recharge.wheel.tip.text.2': 'كلما زادت السحوبات ، زادت فرصة الفوز بالجائزة الكبرى!',
  'lucky.recharge.ticket': 'تذكرة\nاليانصيب',
  'lucky.recharge.recharged': 'إعادة الشحن ',
  'lucky.recharge.recharge.next': 'أشحن {N} <div> </div> للحصول على {X} من تذاكر اليانصيب',
  'lucky.recharge.recharge.done': 'لقد فزت بكل تذاكر الدوار',
  'lucky.recharge.hide.info': 'إخفاء المعلومات الشخصية',
  'lucky.recharge.recharge.gift.bag': 'باقة اعادة\nالشحن',
  'lucky.recharge.recharge.gift.bag.text': 'اشحن {N} كل يوم لتحصل على',
  'lucky.recharge.recharge.gift.bag.text.1': 'تذكرة يانصيب مجموعة الجوائز * 1',
  'lucky.recharge.recharge.no': 'تذاكر يانصيب غير كافية',
  'common.Cap.Uncompleted': 'غير مكتمل',
  'common.Collect': 'تجميع',
  'break.through.text.14': 'تهانينا! لقد تم مكافئتك',
  'world.cup.records': 'سجلات الدعم',
  'world.cup.time': 'الوقت',
  'common.number': 'الارقام',
  'recharge.result.pending': 'اكتملت العملية',
  'recharge.result.tips': 'إذا لم تصل الكوينزات في الوقت المناسب، فلا تقلق، فقد يكون هناك تأخير في الشبكة. وإذا كانت لديك أي أسئلة، فيرجي الاتصال بخدمة العملاء الرسمية لـ SOYO.',
  'recharge.result.tips.click': 'انقر للعودة إلى التطبيق',
  'new.task.go': 'اذهب',
  'new.task.done': 'اكتمل',
  'task.center.title': 'مركز المهمات',
  'task.center.common.level': 'مستوى',
  'common.cap.Event.To.Start': 'الحدث على وشك البدء',
  'task.center.limit.task': 'Limited Task',
  'task.center.normal.task': 'Normal Task',
  'task.center.limit.today': 'Today',
  'task.center.limit.Total': 'Total',
  'task.center.limit.Diamonds.Received': 'Diamonds Received',
  'task.center.limit.Reward.Received': 'Reward Received',
  'task.center.limit.receive': 'Receive',
  'task.center.limit.extra': 'Extra',
  'task.center.limit.to.start': 'Event has not started yet',
  'gift.wall.title': 'الهدايا',
  'gift.wall.help.text1': 'ستظهر الهدايا التي تتجاوز قيمتها {num} من الكوينزات في المرة الواحدة على حائط الهدايا',
  'gift.wall.help.text2': 'كلما زادت قيمة الهدية كلما تم عرضها في الصفوف الاولى',
  'gift.wall.help.text3': 'سيتم الاحتفاظ بالسجل الموجود على حائط الهدايا لمدة 24 ساعة',
  'level.user': 'مستوى المستخدم',
  'level.halo': 'مستوى<br />الجاذبية',
  'level.halo.question1.title': 'مميزات المستوى العالي',
  'level.halo.question1.answer1': 'كلما ازداد المستوى ، كلما اصبحت اكثر جاذبية',
  'level.halo.question2.title': 'عرض ايقونة المستوى',
  'level.halo.question2.answer1': 'كلما ازداد المستوى ، كلما اصبحت الايقونة مميزة اكثر',
  'level.halo.question3.title': 'مكافآة رفع المستوى',
  'level.halo.question3.answer1': 'قم بترقية المستوى لتحصل على جوائز افضل',
  'level.halo.question4.title': 'طرق ترقية المستوى',
  'level.halo.question4.answer1': 'هدية قيمتها واحد كوينز تساوي واحد خبرة ، كلما ازدادت الخبرة ، يتم ترقية مستواك بشكل اسرع',
  'level.user.question1.title': 'مزايا المستوى العالي',
  'level.user.question1.answer1': 'سوف تحصل على عناصر خاصة ومكافأة',
  'level.user.question2.title': 'طرق رفع المستوى',
  'level.user.question2.answer1': 'الدردشة في الغرفة واللعب وإرسال الهدايا',
  'level.user.question2.answer2': 'أكمل المهام اليومية واكتسب الخبرة لتصل إلى المستوى الأعلى',
  'level.user.question2.answer7': 'قم بالاحتفال في الغرفة',
  'level.user.question2.answer8': 'إرسال هدايا عن طريق الكوينز',
  'level.user.question2.answer9': 'أكمل المهام واحصل على الخبرة',
  'level.user.question3.title': 'عرض ايقونة المستوى',
  'level.user.question3.answer1': 'كلما ازداد المستوى ، كلما اصبحت الايقونة مميزة اكثر',
  'level.user.question4.title': 'مكافآة رفع المستوى',
  'level.user.question4.answer1': 'قم بترقية المستوي لتحصل علي جوائز افضل',
  'level.user.level': 'المستوى',
  'level.user.icon': 'الأيقونة',
  'level.user.tips': 'بينما تستمر في اكتساب نقاط الخبرة ، سيزداد مستواك تدريجياً',
  'level.user.way.up': 'طرق زيادة نقاط الخبرة:',
  'about.badges.title': 'حول الشارة',
  'about.badges.0': 'ارتداء شارة',
  'about.badges.1': 'يمكن ارتداء جميع الأوسمة ، ويمكنك ارتداء خمس أوسمة في نفس الوقت.',
  'about.badges.2': 'وسام الشرف',
  'about.badges.3': 'يتم احتساب الأوسمة التي تم فتحها بالقيمة التراكمية الشهرية في اليوم الأول من كل شهر. صالحة لمدة شهر واحد ',
  flowWater: 'تفاصيل الكوينز',
  coinBack: 'الكوينز المردودة',
  tips: 'لا مزيد من البيانات',
  'vip.privileges.title.privacy': 'الخصوصية والأمان',
  'vip.privileges.label.privacy': 'لا يمكن للأخرين البحث عن حسابك / غرفتك',
  'vip.privileges.title.invisible': 'مود مخفي',
  'vip.privileges.label.invisible': 'البقاء مخفي غير مرئي في توصيات الأخرين',
  'vip.privileges.title.blockMessages': 'وضع الصامت',
  'vip.privileges.label.blockMessages': 'حظر رسائل الغرباء منع الإزعاج',
  'vip.privileges.title': 'امتيازات VIP',
  'vip.privileges.activate': 'تفعيل {N} VIP',
  'vip.privileges.stealth.text': 'إخفاء آثاري',
  'vip.privileges.stealth.open': 'فتح',
  'vip.privileges.stealth.close': 'غلق',
  'vip.privileges.title.icon': 'شارة VIP',
  'vip.privileges.label.icon': 'شارة VIP ممتاز ، هوية VIP حصرية ، تظهر كم انت نبيل في كل مكان',
  'vip.privileges.title.visitor': 'سجل الزوار',
  'vip.privileges.label.visitor': 'شاهد سجل جميع الزائرين ، فلن تفوت الأشخاص المهتمين والمعجبين',
  'vip.privileges.title.list': 'أولوية العرض',
  'vip.privileges.label.list': 'تظهر أولا في نتائج البحث',
  'vip.privileges.title.follower': 'زيادة حد المتابعة',
  'vip.privileges.label.follower':
    'يمكنك أن تصبح VIP وتضيف المزيد من الأصدقاء. يمكن للحساب العادي متابعة 1000 شخص. سيزيد VIP1-3 الف اشخاص لكل منهم، وVIP4-6 سيزيد الفين اشخاص لكل منهم، وVIP7 وما فوق سيزيد 3000 لكل منهمم.',
  'vip.privileges.title.sign': 'هدية تسجيل دخول VIP',
  'vip.privileges.label.sign': 'يمكن لـ VIP الاستمتاع بهدايا تسجيل الدخول المضاعف',
  'vip.privileges.title.stealth': 'الدخول في وضع التخفي',
  'vip.privileges.label.stealth': 'عند زيارة مستخدمين آخرين ، لن يتم ترك أي سجل.',
  'vip.privileges.title.name': 'تمييز اللقب',
  'vip.privileges.label.name': 'سوف يحصل المستخدمون على تأثيرات خاصة لتزيين اللقب',
  'vip.privileges.title.avatar': 'إطارصورVIP',
  'vip.privileges.label.avatar': 'الصورة رمزية حصرية لـ VIP',
  'vip.privileges.title.bubble': 'فقاعة VIP',
  'vip.privileges.label.bubble': 'فقاعات دردشة حصرية لـ VIP ',
  'vip.privileges.title.room': 'بطاقة VIP',
  'vip.privileges.label.room': 'بطاقة غرفة VIP حصرية',
  'vip.privileges.title.picture': 'يمكن إرسال صور في الدردشة',
  'vip.privileges.label.picture': 'خاصية إرسال الصور على الشات العام',
  'vip.privileges.title.message': 'تثبيت الرسائل بالأعلى',
  'vip.privileges.label.message': 'يمكنك تثبيت شات المستخدم الآخر في أعلى قائمة الرسائل',
  'vip.privileges.title.vehicle': 'دخولية VIP ',
  'vip.privileges.label.vehicle': 'دخولية VIP حصرية',
  'vip.privileges.title.rank': 'الرجل الغامض',
  'vip.privileges.label.rank': 'يمكنك تشغيل خاصية التخفي والظهور كشخص غامض في القائمة',
  'vip.privileges.title.broadcast': 'إشعار ترقية للسيرفر كله',
  'vip.privileges.label.broadcast': 'عندما تصل إلى VIP8 أو أعلى ، يمكن ارسال شريط ترقية داخل التطبيق',
  'vip.privileges.title.id': 'رقم حصري',
  'vip.privileges.label.id': `احصل على ايدي شخصي مميز واجعل الآخرين يتذكروك في لحظة!
  تحتاج إلى التواصل مع خدمة العملاء VIP الخاصة بك لتحصل على ايدي مميز
   صيغة الارقام 
  vip8: ستة ارقام ABABAB
  vip9:- ستة أرقام AAABBB 
  vip10:- أربعة أرقام ABAB 
  vip11:- أربعة أرقام ABAB 
  vip11:- أربعة أرقام AABB 
  سيتم الاستعادة بعد انخفاض المستوى
  `,
  'vip.privileges.title.protection': 'طرد VIP',
  'vip.privileges.label.protection': 'لن يتم إنزالهم من على الميكروفون في الغرفة ، ولن يتم طردهم من الغرفة',
  'vip.privileges.title.gif': 'إطار الصورة الرمزية المتحركة',
  'vip.privileges.label.gif': `قم بتخصيص إطار الصورة الرمزية المتحركة لإظهار تميزك! عندما تصل إلى VIP9 ،
  يمكنك الاتصال بخدمة عملاء VIP للحصول على إطار مخصص للصورة الرمزية المتحركة ، كل مستخدم لديه فرصة واحدة فقط للتخصيص. `,
  'vip.privileges.title.gift': 'هدية مخصصة',
  'vip.privileges.label.gift': `خصص هديتك الحصرية وقدمها لصديقك أو صديقتك المفضل! عندما تصل إلى VIP10 ،
  يمكنك الاتصال بخدمة عملاء VIP للحصول على هدايا مخصصة ، فلكل مستخدم فرصة واحدة فقط للتخصيص. `,
  'vip.privileges.title.exclusivevehicles': 'دخولية مخصصة',
  'vip.privileges.label.exclusivevehicles': `قم بتخصيص الدخولية للحصول على تأثيرات دخول فريدة! عندما تصل إلى VIP11 ،
  يمكنك الاتصال بخدمة عملاء VIP للحصول على دخوليات مخصصة ، لكل مستخدم فرصة واحدة فقط للتخصيص. `,
  'vip.privileges.title.open': 'تخصيص صفحة البداية',
  'vip.privileges.label.open':
    'افتح التطبيق انه انت ، رائع جدًا! عندما تصل إلى VIP12 ، اذا اردت الحصول على صفحة بداية مخصصة لمدة 24 ساعة يمكنك الاتصال بخدمة عملاء VIP ، كل مستخدم لديه فرصة واحدة فقط للتخصيص. ',
  'vip.privileges.update.info': 'تم تحديث خاصية VIP ، إصدار التطبيق قديم جدا ، لعرض احدث خاصية ، يرجي التحديث لاحدث اصدار للاستمتاع بخاصية vip الجديدة ',
  'aristocracy.vip.title': 'الارستقراطية ',
  'vip.help.1': 'وصف قواعد VIP',
  'vip.help.2': 'ما هو VIP',
  'vip.help.3': `VIP هو تجسيد لهوية خاصة في SOYO. يتضمن 11 مستوى من الهوية ،
  مع VIP  يمكنك الحصول على درجات الامتياز والصلاحيات المناسبة. `,
  'vip.help.4': 'كيفية ترقية مستوى VIP',
  'vip.help.5': `سيحصل المستخدمون على نقطة خبرة VIP مقابل كل دولار واحد يتم شحنه.</br>
  إذا وصلت خبرة المستخدم المتراكمة في خلال الشهر إلى ترقية VIP ، فسيتم ترقية مستوى VIP على الفور.</br>
  في اليوم الأول من كل شهر بعد أن تصبح VIP ، سيتم تصفية الخبرة المتراكمة للشهر السابق.
  وسيعاد تحديد مستوى VIP للمستخدم بناءً على الخبرة المتراكمة في الشهر الماضي `,
  'vip.help.6': 'قواعد ترقية مستوى VIP',
  'vip.help.7': 'قواعد الترقية',
  'vip.help.8': `بمجرد أن تصل إلى شروط الترقية لهذا الشهر ، سيتم تحديث مستوى VIP الخاص بك على الفور ،
  يتم الحفاظ على المستوى حتى نهاية الشهر المقبل. على سبيل المثال: يصل المستخدم إلى VIP4 في الخامس من يونيو ،
  سيستمر عمل VIP4 الخاص به حتى 31 يوليو `,
  'vip.help.9': 'قواعد الاحتفاظ / خفض المستوى',
  'vip.help.10': `إذا وصلت نقاط الخبرة المتراكمة الخاصة بك إلى نقاط الخبرة المطلوبة لمستوى VIP الحالي الخاص بك هذا الشهر ،
  ستحتفظ بمستوى VIP للشهر التالي ؛ وإلا ، سيتم تخفيض مستوى VIP الخاص بك للشهر التالي.
  على سبيل المثال: إذا كنت VIP5 في مايو ، لكنك راكمت نقاط خبرة VIP3 فقط في مايو، سينخفض ​​مستوى VIP الخاص بك إلى VIP3 في اليوم الأول من شهر يونيو `,
  'vip.help.11': 'عوائد مستوى الـVIP',
  'vip.help.12': 'في الأول من كل شهر ، سنعيد قدرًا معينًا من نقاط خبرة VIP وفقًا لمستوى VIP للمستخدم الحالي ، بناءً على مستوى VIP قبل إعادة تعيينها في اليوم الأول من كل شهر ',
  'vip.help.13': 'المستوى',
  'vip.help.14': 'نطاق قيمة الخبرة',
  'vip.help.15': 'اعادة نقاط الخبرة',
  'vip.help.update.info': 'تم تحديث خاصية VIP ، إصدار التطبيق قديم جدا ، لعرض احدث خاصية ، يرجي التحديث لاحدث اصدار للاستمتاع بخاصية vip الجديدة ',
  'to.exchange.page.button': 'تبادل',
  details: 'تفاصيل الماس',
  miss: 'الماس المفقود',
  profit: 'إحصاءات الدخل',
  missToday: 'المفقود اليوم',
  cumulativeMiss: 'إجمالي المفقود',
  distributionOfDiamondIncome: 'توزيع دخل الماس',
  getDiamondsMonth: 'دخل الماس هذا الشهر:',
  getDiamondsDay: 'دخل الماس اليوم:',
  daily: 'اليوم',
  month: 'الشهر',
  cancel: 'إلغاء',
  confirm: 'تأكيد',
  tips: 'لا مزيد من البيانات',
  voiceChat: 'تقييم المستخدمين <br /> عن الدردشة الصوتية مع المضيف',
  voiceChat1: 'تقييم المستخدمين <br /> عن الدردشة الصوتية معي',
  quickCall: 'تقييم المستخدمين <br />  عن الاتصال السريع مع المضيف',
  quickCall1: 'تقييم المستخدمين <br /> عن الاتصال السريع معي',
  chatting: 'بيانات الدردشة <br />  بين المضيف والآخرين',
  chatting1: 'بيانات دردشتي مع الآخرين',
  chatSpeed: 'السرعة التي يرد المضيف بها على الدردشة',
  chatSpeed1: 'سرعتي للرد على الدردشة مع الآخرين',
  like: 'يحب',
  boring: 'ممل',
  Hate: 'يكره',
  chatConversation: '💬جولات الدردشة',
  reached: '🙋‍♀️ عدد المستخدمين',
  count: '🙋‍♀️ عدد الرسائل',
  speed: '⏰ سرعة الرد على الرسائل',
  quality: 'جودة الدردشة',
  speed: 'سرعة الرد',
  call: 'الاتصال والتقييم',
  currentCycle: 'الدورة الحالية',
  speedTitle: 'قواعد الرد على الرسالة',
  roundTitle: 'قواعد كسب الماس في جولات الدردشة',
  modalTips: 'كلما زادت سرعة ردك على الرسائل فسيمكن تحسن تجربة المستخدمين ، ويزيد عدد الماس الذي تكسبه',
  modalTipsTwo: 'كلما زادت جولات الدردشة وتحسن محتوى الرد فسيمكن تحسن تجربة المستخدمين  ، ويزيد عدد الماس الذي تكسبه:',
  modalSpeed: 'سرعة الرد',
  modalIncome: 'نسبة الدخل',
  modalRound: 'جولات الدردشة',
  modalReward: 'مكافآت إضافية',
  modalBtn: 'استمر في دردشة',
  cycleDiamonds: 'دخل الماس للدورة الحالية',
  1: 'يناير',
  2: 'فبراير',
  3: 'مارس',
  4: 'أبريل',
  5: 'مايو',
  6: 'يونيو',
  7: 'يوليو',
  8: 'أغسطس',
  9: 'سبتمبر',
  10: 'اكتوبر',
  11: 'نوفمبر',
  12: 'ديسمبر',
  'my.earning.title': 'الدخل الخاص بي',
  'my.earning.tips': 'رصيد الماس',
  'my.earning.daimonds': 'الماس',
  'my.earning.withdrawal.rules': 'قواعد السحب',
  'my.earning.salary.rules': 'قواعد الراتب',
  'ranking.daily': 'اليومية',
  'ranking.weekly': 'الاسبوعية',
  'ranking.contributed': 'ساهمت بي:',
  'ranking.blank.daily': 'لم يرسل احد هدايا اليوم',
  'ranking.blank.weekly': 'لم يرسل احد هدايا هذا الاسبوع',
  'lucky.packet.title': 'محفظة الحظ',
  'lucky.packet.1': 'إرسال محفظة الحظ',
  'lucky.packet.2': 'يمكن لجميع المستخدمين في الغرفة استخدام خاصية محفظة الحظ واستلامها',
  'lucky.packet.3': 'عندما يكون هناك كثير من الأظرف في الغرفة، سيتم تكديسها وعرضها، وكلما تم إرسال ظرف الحظ مبكراً، كان ترتيبها بالأمام',
  'lucky.packet.4': 'القيود والشروط',
  'lucky.packet.5': 'يدعم الكوينزات فقط',
  'lucky.packet.6':
    'الحد الافتراضي هو 3000 كوينز، والحد الأدنى هو 1000 كوينز، والحد الأقصى هو 9999999 كوينز. عدد المشاركين الافتراضي لمحفظة الحظ هو 10 أشخاص ، والحد الأدنى هو 5 أشخاص ، والحد الأقصى هو 500 شخص.',
  'lucky.packet.7': 'يجب أن يكون نصيب الفرد (كمية الكوينزات / عدد الأشخاص ) أكبر من أو يساوي 100',
  'lucky.packet.8': 'عندما تكون كمية كوينز ظرف الحظ أكبر من 10000 وكان عدد الأشخاص أكبر من 10، سوف يتم تشغيل الشريط، وسيتم عرض الرمز الخاص في قائمة الغرفة',
  'lucky.packet.9': 'كيفية اللعب',
  'lucky.packet.10': 'محفظة الحظ بكلمة المرور - تحتاج إلى إرسال كلمة المرور في الغرفة لتحصل عليها',
  'lucky.packet.11': 'محفظة الحظ بالمتابعة - عليك متابعة الشخص الذي أرسل محفظة الحظ للحصول عليها',
  'lucky.packet.12': 'مشاكل شائعة',
  'lucky.packet.13': 'عندما يقوم ظرف الحظ بتشغيل الشريط، أو عندما يتم عرض رمز ظرف الحظ  في القائمة، يمكن للمستخدمين الدخول إلى غرفتك بالنقر عليه',
  'lucky.packet.14': 'إذا كانت غرفتك مغلقة ، فلن يقوم بتشغيل الشريط أو تأثيرات العرض الخارجية الأخرى',
  efficient_text_2: 'وقت فعال',
  efficient_text_5: 'يوم فعال ',
  efficient_text_6: '1. وقت فعال  في الغرفة',
  efficient_text_7:
    'في الغرفة الصوتية، إذا كان الوقت على المايك - الوقت خارج المايك >= %$ دقيقة، فسيتم اعتباره وقت فعال على المايك .  نحن نشجع الدردشه الصوتيه على المايك والتفاعل بشكل نشط مع الأصدقاء لفترة طويلة، مثل:',
  efficient_text_8: 'الوقت على المايك',
  efficient_text_9: 'الوقت خارج المايك ',
  efficient_text_10: 'تم الوصول للتارجت ام لا',
  efficient_text_11: 'توضيح',
  efficient_text_12: 'تم تحقيق التارجت ',
  efficient_text_13: 'لم يتم تحقيق التارجت',
  efficient_text_14: 'الوقت المحسوب حتى أمس',
  efficient_text_15: 'الوقت خارج المايك - الوقت على المايك =< %$ دقيقه',
  efficient_text_16: 'الوقت خارج المايك - الوقت على المايك >= %$ دقيقه',
  efficient_text_17: '12/1 9:00:00 الوقت على المايك  لم يصل إلى التارجت المطلوب ولا يمكن إعتباره وقت فعال',
  efficient_text_18: '12/1 23:00:00 بما أن الوقت على المايك أستمر حتى  2/12 ولم ينقطع  لمدة 90 دقيقة، سيتم  احتسابه كوقت فعال ليوم 1/12',
  efficient_text_19: '2. الأيام الفعالة في الغرفة',
  efficient_text_20:
    'في الغرفة الصوتية، إذا كان إجمالي الأوقات الفعالة  في اليوم >= %$ دقيقة، فسيتم اعتباره يوم فعال. كلما زاد عدد الأيام الفعالة، وزاد نشاطك، سيؤثر هذا بشكل مباشر على التارحت  و المكافآت التي تتلقاها.',
  efficient_text_21: 'إجمالي الأوقات الفعالة',
  efficient_text_22:
    'في 1/12، تم أستخدام المايك 3 مرات : 15 دقيقة + 35 دقيقة + 90 دقيقة، وبما أن 15 دقيقة لم تحقق تارجت  الوقت الفعال المحدد، فإن الوقت الفعلي = 90 + 35 = 125 دقيقة = 02 ساعة 05 دقيقة',
  efficient_text_23: '1/12  إجمالي الأوقات الفعالة في اليوم = 02 ساعة و 05 دقيقة  > = %$ دقيقة لذلك سيحسب كا يوم واحد فعال',
  efficient_text_24: '3. الوقت على المايك',
  efficient_text_25:
    'الوقت على المايك يجب أن يساوي الوقت الفعال المحدد أو بزبد عنه،  الوقت الفعال هو >= %$ دقيقة. يتم حساب الوقت الفعال على المايك  عند وجود صوت ودردشه  على المايك  في الغرفة. سيتم احتساب >=%$ دقيقة  على المايك كا وقت فعال ، على سبيل المثال:',
  efficient_text_26: '4. تفاصيل البيانات',
  efficient_text_27: 'سيتم عرض البيانات وفقًا للدورة المحددة',
  efficient_text_28: 'الماس',
  efficient_text_29: 'دردشة',
  efficient_text_30: 'غرفة صوتية',
  efficient_text_31: 'التفاصيل',
  efficient_text_32: 'التاريخ',
  efficient_text_33: 'الوقت على المايك',
  efficient_text_34: 'الأوقات الفعالة في الغرف',
  efficient_text_35: 'أيام فعالة في الغرف',
  share_text23: 'تحميل',
  share_text24: 'انضمام إلى الغرفة',
  share_text25: 'أنا في هذه الغرفة ، تعال وانضم إلينا',
  share_text26: 'أيدي الغرفة ',
  OfficialTask_Card_common_text1: 'المهام الرسمية',
  OfficialTask_Card_common_text2: 'أكمل المهام الرسمية لفوز بمكافأة تصل ل {%s} من الماس!',
  OfficialTask_dailyCard_text1: 'مكافآت دخل الماس اليومية ({%s1}/{%s2})',
  OfficialTask_dailyCard_text2: 'تصل مكافأة دخل الماس اليومية ({%s1}) إلى {%s2}💎 وتحصل على {%s3}💎',
  OfficialTask_dailyCard_text3: 'لما تصل دخل الماس اليومية  من ({%s3}) إلى {%s1}💎 فستحصل على {%s2}💎كمكافأة إضافية',
  OfficialTask_dailyCard_text4: 'وقت المكافأة',
  OfficialTask_dailyCard_text5: 'ذهاب',
  OfficialTask_PeriodCard_text1: 'مكافآت لدخل الماس لدورة كاملة ({%s1}/{%s2})',
  OfficialTask_PeriodCard_text2: 'لما تصل دخل الالماس  لدورة كاملة من ({%s3}) إلى {%s1}💎، ستحصل على {%s2}💎 كمكافأة إضافية',
  OfficialTask_ActiveCard_text1: 'مكافأة لتفاعل الدورة الكاملة',
  OfficialTask_ActiveCard_text2: '1- إجمالي أيام تسجيل الدخول في الدورة يصل إلي {%s1} ({%s2}/{%s3}) ',
  OfficialTask_ActiveCard_text3: '2- إذا دخل الماس وصل إلى {%s1}💎 كل يوم، فيمكنك الحصول على {%s2}💎. (اليوم: {%s3}/{%s4})',
  OfficialTaskPic_Level_text1: 'مكافآت الماس اليومية (رسائل)',
  OfficialTaskPic_Level_text2: 'مستوى',
  OfficialTaskPic_Level_text3: 'تارجت ألماس',
  OfficialTaskPic_Level_text4: 'مكافأة الماس',
  OfficialTaskPic_Level_text5: 'مكافآت دخل الماس اليومي （هدايا الغرفة الصوتية: غرفة صوتية  + غرفة صوتية عائلة)',
  OfficialTaskPic_Level_text6: 'مكافآت لدخل الماس في دورة كاملة  (رسائل + جميع مجموعات الهدايا+الإتصالات +مطابقة)',
  OfficialTaskPic_Level_text7: 'مكافآت لتفاعل الدورة',
  OfficialTaskPic_Level_text8: 'سجل الدخول لمدة {%s1} يوم  واحصل على {%s2} من الماس كل يوم',
  OfficialTaskPic_Level_text9: 'المتطلبات',
  OfficialTaskPic_Level_text10: 'مكافأة الماس',
  OfficialTaskPic_Level_text11: 'ملاحظات:',
  OfficialTaskPic_Level_text12: '1. هدايا الغرف الصوتية: تتضمن الغرف الصوتية وغرف صوتية العائلة؛',
  OfficialTaskPic_Level_text13: '2. مكافآت النشاط خلال دورة العمل: بعد انتهاء الفترة، سيتم احتساب عدد الأيام النشطة، والمكافأة الإجمالية التي يمكنك الحصول عليها هو 350 الماس؛',
  OfficialTaskPic_Level_text14: '3. سيتم إرسال جميع مكافآت الماس مباشرة إلى حسابك مع الماسات دورة العمل هذه ؛',
  OfficialTaskPic_Level_text15: '4.  70 الماس = 1 $',
  OfficialTaskPic_Level_text16: 'مكافآت يومية (هدايا للدردشة، مكالمات صوتية، مطابقات صوتية)',
  OfficialTaskPic_Level_text17:
    'إذا تلقيت هدايا في الدردشة، والمكالمات الصوتية، والمطابقات الصوتية، وحققت التارجت اليومي، فستحصل على مكافآت ألماس المقابلة. سيتم إرسال المكافآت الماس تلقائيًا إلى حسابك في اليوم التالي.',
  OfficialTaskPic_Level_text18: 'المكافأة اليومية (للهدايا في الدردشات والغرف الصوتية)',
  OfficialTaskPic_Level_text19: 'مكافأة دورة العمل (لمكالمات الصوت )',
  OfficialTaskPic_Level_text20: '1. إذا تلقيت الهدايا في الدردشة و الغرف الصوتية وحققت التارجت اليومي، فستحصل على مكافآت ألماس المقابلة. يتم إرسال مكافآت الماس تلقائيًا إلى حسابك في اليوم التالي.',
  OfficialTaskPic_Level_text21:
    '2. إذا تلقيت الماس في المكالمات الصوتية  و حققت التارجت  في نهاية دورة العمل، فستحصل على المكافآت الماس المقابلة.يتم إرسال مكافآت الماس تلقائيًا إلى حسابك في اليوم التالي لانتهاء دورة العمل.',
  OfficialTaskPic_Level_text22: '3. هناك دورتين عمل شهرياً، من 1 إلى 15 من كل شهر، ومن 16 إلى نهاية الشهر.',
  OfficialTaskPic_Level_text23: 'المكافأة اليومية 1 ( الماس هدايا الغرفة الصوتية والعائلة)',
  OfficialTaskPic_Level_text24: 'المكافأة اليومية 2 (رد الدردشة)',
  OfficialTaskPic_Level_text25: 'مكافآت دورة العمل (الرسائل،الغرفة الصوتية، المكالمات الصوتية، المطابقات الصوتية)',
  OfficialTaskPic_Level_text26:
    '1.إذا تلقيت هدايا في الدردشة، والمكالمات الصوتية، والمطابقات الصوتية، وحققت التارجت الأسبوعي، فستحصل على مكافآت ألماس المقابلة. سيتم إرسال المكافآت الماس تلقائيًا إلى حسابك مع ماسات دورة العمل ذاتها.',
  OfficialTaskPic_Level_text27: '2. سعر صرف الماس: 70 ماسة = 1 $ ؛',
  OfficialTaskPic_Level_text28: '3. لن يتم احتساب الماس التي تم الحصول عليها كمكافآت يومية ضمن تارجت ألماس',
  OfficialTaskPic_Level_text29: 'نظام المكافآت الرسمي SOYO 02.2024',
  OfficialTaskPic_Level_text30: 'المهمة اليومية 1: جميع المكالمات + جميع الهدايا',
  OfficialTaskPic_Level_text31: 'المهمة اليومية 2: رد الرسالة',
  OfficialTaskPic_Level_text32: 'تارجت الماس اليومي',
  OfficialTaskPic_Level_text33: 'التارجت اليومي للألماس كما هو موضح أعلاه، ويعتمد حساب اليوم في نظام المكافآت الخاص بنا على التوقيت التركي المحلي.',
  cms_report_text3: 'سبب البلاغ:',
  cms_report_text4: 'تفاصيل البلاغ:',
  cms_report_text5: 'لقطه من الشاشة دليل للبلاغ:',
  cms_report_text6: 'يرجى التحقق من المعلومات أعلاه. و تطبيق العقوبة الازمة بعد التحقق من صحتها.',
  cms_report_text7: 'ايدي صاحب البلاغ:',
  cms_report_text8: 'ايدي المُبلغ عنه:',
  cms_report_text9: 'طريقة العقوبة:',
  cms_report_text10: 'نتيجه العقوبة:',
  cms_report_text11: 'تاريخ العقوبات',
  cms_report_text12: 'تحذير لفظي',
  cms_report_text13: 'طريقة الحظر:',
  cms_report_text14: 'أسباب الحظر:',
  cms_report_text15: 'وقت الحظر:',
  cms_report_text16: 'حساسية سياسية',
  cms_report_text17: 'مبتذل غير لائق',
  cms_report_text18: 'التعدي والإساءة',
  cms_report_text19: 'عنف دموي',
  cms_report_text20: 'الدعاية و الأعلان',
  cms_report_text21: 'يشتبه في الاحتيال',
  cms_report_text22: 'معلومات غير قانونية',
  cms_report_text23: 'تحت السن القانوني',
  cms_report_text24: 'آخر',
  cms_report_text25: 'تأكيد',
  cms_report_text26: 'عملية حساسة للحساب، يرجى التأكد من المعلومات قبل تأكيد العملية.',
  OfficialTask_dailyCard_text6: 'مكتمل',
  OfficialTask_rewardTime_text1: 'ساعات',
  OfficialTask_rewardTime_text2: 'دقائق',
  delete_account_web_title: 'عزيزتي الروح يو المستخدم. شكرا لاستخدام ودعم منتجاتنا. إذا كنت ترغب في حذف حسابك ، فيرجى التحقق من الخطوات التالية للمعالجة:',
  delete_account_web_title_bibo: 'عزيزتي الروح يو المستخدم. شكرا لاستخدام ودعم منتجاتنا. إذا كنت ترغب في حذف حسابك ، فيرجى التحقق من الخطوات التالية للمعالجة:',
  delete_account_web1: '1. افتح التطبيق وأدخل الصفحة الرئيسية ، انقر فوق أسفل اليمين لإدخال علامة التبويب لي.',
  delete_account_web2: '2. انقر فوق الإعدادات بعد إدخال علامة التبويب لي.',
  delete_account_web3: '3. انقر فوق حذف الحساب بعد إدخال الإعدادات.',
  delete_account_web4: '4. تأكيد الخطوة الأولى لحذف الحساب بشكل دائم.',
  delete_account_web5: '5. تأكيد الخطوة الثانية لتمكن من تسجيل الدخول استخدام نفس الحساب.',
  delete_account_web6: '6. تأكيد الخطوة الأخيرة لحذف جميع المعلومات الشخصية المقابلة.',
  delete_account_web7: '7. بعد الانتهاء من جميع الخطوات أعلاه. تم حذف حسابك بنجاح ، مع جميع معلوماتك الشخصية وبيانات الحساب. لن يحتفظ النظام الأساسي الخاص بنا ببياناتك في قاعدة البيانات الخاصة بنا.',
  delete_account_web: 'حذف الحساب',
  svip_introduce_1: '1. ما هو SVIP',
  svip_introduce_text1: 'SVIP هو نظام المنصة للمستوىات المتميزة. هناك إجمالي 8 مستويات، SVIP1-SVIP8. \n' +
    'كلما ارتفع المستوى، زادت المكانة تميزًا، و تمتع مالكها بمختلف الحقوق والأمكانيات الأكثر تقدمًا.',
  svip_introduce_2: '2. كيف تصبح SVIP',
  svip_introduce_text2: 'يمكنك الحصول على نقاط خبرة SVIP عن طريق شحن الكوينزات ، 1 كوينزا = 1 نقطة خبرة. عندما تصل نقاط الخبرة إلى مستويات مختلفة من SVIP، يمكنك الحصول على مستوى SVIP المقابل لهذه النقاط.',
  svip_introduce_3: '3. كيفية حساب مدة صلاحية SVIP',
  svip_introduce_text31: 'يأخذ SVIP دورته شهريا بشكل طبيعي ، وفي اليوم الأول من الشهر، يتم تجميع نقاط خبرة SVIP من 0. \n' +
    'سيتم الحصول على مستوى SVIP المقابل وفقًا للمستوى الذي وصلت إليه من نقاط الخبرة المقابة SVIP.',
  svip_introduce_text32: 'من وقت حصولك على مستوى SVIP، سيتم الاحتفاظ بمستوى وامتيازاته حتى نهاية الشهر التالي. \n' +
    '( مثال: إذا تم الوصول إلى SVIP3 في 18 مايو، فسيتم تمديد SVIP3 إلى 30 يونيو.)',
  svip_introduce_text33: 'إذا وصلت نقاط الخبرة SVIP الخاصة بك إلى مستوى أعلى خلال الشهر، فسيتم ترقيه المستوى على الفور. \n' +
    '(مثال: إذا وصلت إلى SVIP3 في 18 مايو وSVIP4 في 25 مايو، فستحصل على مستوى SVIP4 على الفور في 25 مايو وتستمر حتى نهاية 30 يونيو.)',
  svip_introduce_text34: 'عند انتهاء الشهر، سيتم الاحتفاظ بالهوية والامتيازات، ولكن سيتم تجميع نقاط خبرة SVIP للشهر التالي من 0.\n' +
    ' (مثال: إذا وصلت إلى SVIP4 في 25 مايو، فستظل تتمتع بهوية وامتيازات SVIP4 حتى نهاية شهر يونيو، لكنك ستبدأ في تجميع نقاط خبرة SVIP بدءًا من 0 في شهر يونيو)',
  svip_introduce_4: '4.احتفاظ وإنخفاض لمستوى SVIP ',
  svip_introduce_text41: 'في بداية كل شهر، إذا كان لديك مستوى SVIP وتريد الاحتفاظ به في الشهر التالي، فأنت بحاجة إلى الوصول إلى 50% من نقاط الخبرة لمستوى SVIP هذا قبل نهاية الشهر، أي أنك يمكنك الاحتفاظ بهذا المستوى وامتيازاته في الشهر المقبل،. \n' +
    '(مثال: عندما يبدأ شهر مايو،  أنت SVIP4، فأنت تحتاج فقط إلى الوصول إلى 50% من نقاط الخبرة لمستوى SVIP4 طوال شهر مايو للاحتفاظ بمستوى SVIP4 حتى نهاية الشهر التالي يونيو.)',
  svip_introduce_text42: 'إذا لم يتم الوصول إلى 50% من نقاط خبرة مستوى SVIP خلال الشهر، فسينخفض المستوى بمقدار مستوى واحد في الشهر التالي. \n' +
    '(مثال: في بداية شهر مايو، أنت SVIP4، ونقاط الخبرة في شهر مايو بأكمله لا تصل إلى 50% من مستوى SVIP4، سينخفض المستوى في شهر يونيو بمقدار مستوى واحد، بدءًا من SVIP3.)',
  noble_introduce_title: 'مقدمة لقواعد الارستقراطية',
  noble_introduce_welcome: 'نرحب بك للانضمام إلى نظام الأرستقراطية الخاص بنا! إن تصبح من الاستقراطيين سيعطيك الكثير من الامتيازات والفوائد الفريدة لتستمتع بها. فيما يلي مقدمة لنظام الاستقراطية:',
  noble_introduce_1: '1. ما هي الاستقراطية؟',
  noble_introduce_text1: 'الارستقراطية هي رمز للمكانة الخاصة والرفيعة وتنقسم إلى 7 مستويات ، وهي الشهم والفارس واللواء والجنرال والوزير والأمير والملك. تتمتع مستويات الارستقراطية المختلفة بامتيازات مختلفة رائعة.',
  noble_introduce_2: '2. كيف تصبح ارستقراطي؟',
  noble_introduce_text2: 'يمكن شراء امتياز الارستقراطية عن طريق شحن الكوينزات، ويمكن إتمام عملية الشراء عن طريق اختيار المستوى المراد في صفحة الارستقراطية . \n' +
    'وفي الوقت نفسه، يمكنك أيضًا الحصول على امتياز الارستقراطية  من خلال إرسال الهدايا للاخرين.',
  noble_introduce_3: '3. حساب مدة صلاحية الارستقراطية',
  noble_introduce_text3: 'امتياز الارستقراطية صالح لمدة 30 يومًا من تاريخ الشراء، وستستمتع بامتيازات الارستقراطية خلال فترة الصلاحية. ستنتهي الامتيازات عند نهاية فترة الصلاحية.',
  noble_introduce_4: '4. قواعد استلام الكوينزات اليومية',
  noble_introduce_text4: 'بعد شراء امتاز الارستقراطية من خلال شحن الكوينزات، ستصبح مؤهلاً لتلقي الكوينزات كهدايا يوميًا. سوف تتلقى كوينزات بعد تسجيل الدخول يوميًا. إذا لم تقم بتسجيل الدخول في يوم، فلن تتمكن من الحصول على الكوينزات لذلك اليوم. عند إهداء امتياز الارستقراطية  للآخرين، سيحصل المستلم على الحق في الحصول على الكوينزات يوميًا.',
  noble_introduce_5: '5. خصومات عمليات الشراء المستمرة',
  noble_introduce_text5: 'خلال فترة الحصول على امتياز الارستقراطية ، وفي غضون 10 أيام بعد انتهاء صلاحيتها ، يمكن شراء نفس مستوى الارستقراطية مرة أخرى بسعر أفضل. \n' +
    'خطة الشراء هذه ليست تلقائية وتتطلب منك شراؤها يدويًا لتصبح سارية المفعول.',
  noble_introduce_6: '6. ترقية الارستقراطية ',
  noble_introduce_text6: 'عندما يكون لديك  أرستقراطية بالفعل، يمكنك شراء مستوى أرستقراطية بمستوى أعلى والحصول مباشرة على المزايا الافضل و الأحدث، سيتم دمج الأيام المتبقية من مستوى الأرستقراطية الحالي مع المستوى الأعلى الجديد وفقًا للقيمة المتبقية وفترة صلاحية المتبقية للأرستقراطية الجديدة. سيتم تمديد المستوى.',
  noble_introduce_7: '7. هدايا الارستقراطية ',
  noble_introduce_text7: 'عند ارسال الارستقراطية لشخص آخر، سيتم منح السعر الأصلي للشخص الآخر، وسيتمتع المستلم بكوينزات يومية وحقوق و امكانيات أخرى تتوافق مع المستوى.\n' +
    ' إذا كان المستلم لديه مستوى ارستقراطية بالفعل، فسوف يتمتع مباشرة بمستوى ارستقراطية عالي المستوى، وسيتم تحويل المستويات الأخرى وفقًا للمدة المقابلة.',
  vip_introduce_title: 'مقدمة لقواعد VIP',
  vip_introduce_welcome: 'نرحب بك  لتصبح VIP لدينا! سوف يعطيك VIP المزيد من الامتيازات الرائعه. فيما يلي مقدمة حول VIP:',
  vip_introduce_1: '1. ما هو VIP',
  vip_introduce_text1: 'VIP هو نظام لتقدم مستوى المستخدم. يوجد حاليًا 16 مستوى، VIP1-VIP16. كلما ارتفع مستوى VIP، زادت الحقوق والامتيازات التي ستستمتع بها وأصبحت الدعائم التي تحصل عليها أكثر ثراءً و فخامة.',
  vip_introduce_2: '2. كيف تصبح VIP',
  vip_introduce_text2: 'يمكنك الحصول على نقاط خبرة VIP عن طريق شحن الكوينزات، 1 كوينزا = 1 نقطة خبرة. كلما زاد عدد الكوينزات التي تقوم بشحنها، زادت نقاط الخبرة التي تحصل عليها، وسوف يرتفع مستوى VIP الخاص بك إلى مستوى أعلى.',
  vip_introduce_3: '3.فترة صلاحية VIP',
  vip_introduce_text3: 'بمجرد الحصول على VIP، تصبح صالحة بشكل دائم ويتم تجميع نقاط خبرة VIP بشكل دائم ولن تتم محوها. مع زيادة نقاط الخبرة، سيتم ترقيتك إلى مستوى VIP أعلى وستستمتع بالمزيد من مزايا المستوى المقابل .',
  vip_introduce_4: '4. استخدام دعائم VIP ',
  vip_introduce_text4: 'سيتم دائمًا الاحتفاظ بدعائم مستوى VIP التي تحصل عليها في الحقيبة الخاصة بحسابك ويمكن ارتداؤها في أي وقت. عند الترقية، لا يزال من الممكن استخدام الدعائم ذات المستوى السابق التي حصلت عليها قبلًا.',
  svip_introduce_title: 'مقدمة لقواعد SVIP',
  svip_introduce_welcome: 'نرحب بك لتصبح SVIP لدينا! سيعطيك  SVIP مختلف الامتيازات الرائعه. فيما يلي مقدمة لـ SVIP:',
  svip_introduce_text43: 'إذا لم يتم تجميع نقاط خبرة SVIP خلال الشهر، سينخفض مستوى SVIP بمقدار 3 مستويات في الشهر التالي. (مثال: عند بداية شهر مايو، أنت SVIP4، ولم يتم تجميع اي نقاط خبرة طوال شهر مايو،  سينخفض المستوى في شهر يونيو بمقدار 3 مستويات، بدءًا من SVIP1)',
  svip_introduce_5: '5. حقوق و امكانيات  SVIP',
  svip_introduce_text5: 'كلما ارتفع مستوى SVIP، زادت الامتيازات و الامكانيات التي تتمتع بها. عندما يتغير المستوى، ستستمتع بمزايا المستوى الجديد، وسيتم استبدال مكافآت الدعائم التي تتلقاها بمكافأت المستوى الجديد. عند ترقية الدعائم المخصصة أو حفظها، تحتاج إلى التواصل بخدمة العملاء مسبقًا و تقديم متطلبات التخصيص الخاصة بك قبل إصدارها. ومع ذلك، لن يتم إصدارها إذا تم انخفاض المستوى. سيزداد عدد الرسائل المجانية والمكالمات المجانية مع ارتفاع المستوى. سيتم إرسال العدد المقابل عند ترقية المستوى أو الحفاظ عليه، ولكن لن يتم إرسال عند الانخفاض إلى مستوى أدنى. أما الاعداد الموزعة على كل مستوى فهي كما يلي:',
  svip_table1: 'مستوى SVIP',
  vip_table1: 'مستوى VIP',
  svip_table2: 'رسائل مجانية',
  svip_table3: 'مكالمات مجانية',
  Anonymity_name: 'مجهول',
  Charge_Same_1: 'لقد قمت مؤخرًا بإرسال نفس مبلغ الشحن إلى هذا المستخدم وكانت المعاملة ناجحة.  هل تريد المتابعة مرة أخرى؟',
  Charge_Same_2: 'الغاء الشحن',
  Charge_Same_3: 'إستكمال الشحن',
  Noble_Change_2: 'شروط الشراء: يمكن للمستويات الأدنى شراء مستويات أعلى، لكن لا يمكن للمستويات الأعلى شراء مستويات أقل. على سبيل المثال، إذا كنت حاليا الجنرال، فيمكنك فقط شراء الوزير أو الأمير أو الملك.',
  Noble_Change_3: 'منطق التحويل: إذا كنت تمتلك أرستقراطية بالفعل، فبعد شراء مستوى أرستقراطية أخر، سيتم تحويل فترة الصلاحية المتبقية لمستوى الأرستقراطية القديم إلى مستوى الأرستقراطية الجديد بناءً على نسبة سعر الكوينزات، وسيصبح مستوى الأرستقراطية القديم غير صالح.',
  Noble_Change_4: 'حساب فترة الصلاحية: فترة صلاحية مستوى الأرستقراطية المشتراة الجديدة = الأيام المتبقية من مستوى الأرستقراطية الحالية * (سعر مستوى الأرستقراطية الحالية / سعر مستوى الأرستقراطية الجديدة ) + 30 ، يتم تقريب العدد النهائي من الأيام.',
  Noble_Change_5: 'مثال: إذا كنت حاليًا اللواء (99990 عملة/شهر)، مع بقاء 13 يومًا لهذه الأرستقراطية، وقمت بشراء الجنرال (199990 عملة/شهر) اليوم، فبعد الدفع، ستكون فترة صلاحية الجنرال 13 * (99990/199990) + 30 = 6.5 بعد التقريب + 30 = 36 يومًا.',
  rich_list: 'ترتيب الأغنياء ',
  lucky_list: 'ترتيب المحظوظين',
  lucky_daily: 'يومي',
  lucky_weekly: 'أسبوعي',
  lucky_faq1: 'كيفيه اللعب',
  lucky_faq2: 'قواعد اللعبة',
  lucky_faq3: '1. بعد ارسال هدية الحظ، لديك فرصة للحصول على مكافأة الكوينزات بمقدار 1-1000 مرة؛',
  lucky_faq4: '2. كلما زاد عدد هدايا الحظ التي ترسلها على شكل مجموعة أو دفعة، زادت فرصة الفوز؛',
  lucky_faq5: '3. سيتم توزيع مكافآت الكوينزات لرصيد حسابك في الوقت نفسه، ويمكن الاطلاع على تفاصيل الفوز في سجل هدايا الحظ؛',
  lucky_faq6: '4. في كل مرة يتم فيها إرسال هدية الحظ، سيحصل المضيف أيضًا على نسبة معينة من الماس.',
  lucky_faq7: 'قواعد الترتيب',
  lucky_faq8: '1. قواعد الترتيب',
  lucky_faq9: 'سيرتب المستخدمون العشرة الذين بنفقون أكبر عدد من الكوينزات أثناء المشاركة في اللعب اليومي والأسبوعي في ترتيب الأغنياء، وسيرتب المستخدمون العشرة الذين حصلوا على أعلى مضاعفات الفوز في ترتيب المحظوظين.',
  lucky_faq10: '2. دورة بيانات الترتيب',
  lucky_faq11: 'الترتيب اليومي: البيانات من 0:00 إلى 24:00 كل يوم',
  lucky_faq12: 'الترتيب الأسبوعي: البيانات من 0:00 كل يوم اثنين إلى 24:00 يوم الأحد',
  lucky_faq13: '3. مكافآت الترتيب',
  lucky_faq14: 'سيحصل العشرة الأوائل في التصنيف اليومي والأسبوعي على مكافآت كما هو موضح أدناه',
  lucky_faq15: 'مكافآت الترتيب اليومية',
  lucky_faq16: 'ترتيب',
  lucky_faq17: 'مكافآت الترتيب الأسبوعية',
  Noble_Change_2: 'شروط الشراء: يمكن للمستويات الأدنى شراء مستويات أعلى، لكن لا يمكن للمستويات الأعلى شراء مستويات أقل. على سبيل المثال، إذا كنت حاليا الجنرال، فيمكنك فقط شراء الوزير أو الأمير أو الملك.',
  Noble_Change_3: 'منطق التحويل: إذا كنت تمتلك أرستقراطية بالفعل، فبعد شراء مستوى أرستقراطية أخر، سيتم تحويل فترة الصلاحية المتبقية لمستوى الأرستقراطية القديم إلى مستوى الأرستقراطية الجديد بناءً على نسبة سعر الكوينزات، وسيصبح مستوى الأرستقراطية القديم غير صالح.',
  Noble_Change_4: 'حساب فترة الصلاحية: فترة صلاحية مستوى الأرستقراطية المشتراة الجديدة = الأيام المتبقية من مستوى الأرستقراطية الحالية * (سعر مستوى الأرستقراطية الحالية / سعر مستوى الأرستقراطية الجديدة ) + 30 ، يتم تقريب العدد النهائي من الأيام.',
  Noble_Change_5: 'مثال: إذا كنت حاليًا اللواء (99990 عملة/شهر)، مع بقاء 13 يومًا لهذه الأرستقراطية، وقمت بشراء الجنرال (199990 عملة/شهر) اليوم، فبعد الدفع، ستكون فترة صلاحية الجنرال 13 * (99990/199990) + 30 = 6.5 بعد التقريب + 30 = 36 يومًا.',
  Registr_optimization_1: 'مشاكل إنشاء حساب وتسجيل الدخول',
  AnchorCenter_biList_interaction_text1: 'تفاعل',
  AnchorCenter_biList_interaction_text2: 'الدردشات التي تم بدءها (UV)',
  AnchorCenter_biList_interaction_text3: 'معدل الرد (UV)',
  AnchorCenter_biList_interaction_text4: 'معدل الرد (PV) ',
  AnchorCenter_biList_interaction_text5: 'معدل الاستجابة (UV)',
  AnchorCenter_biList_interaction_text6: 'الرسائل المستلمة (UV)',
  AnchorCenter_biList_interaction_text7: 'ردود الرسائل (UV)',
  AnchorCenter_biList_interaction_text8: 'معدل الاستجابة (UV)',
  AnchorCenter_biList_interaction_text9: 'الرسائل المرسله (UV)',
  AnchorCenter_biList_interaction_text10: 'ردود رسائلك (UV)',
  AnchorCenter_biList_interaction_text11: 'معدل الرد (UV)',
  AnchorCenter_biList_interaction_text12: 'الرسائل المرسلة (PV)',
  AnchorCenter_biList_interaction_text13: 'ردود رسائلك (PV)',
  AnchorCenter_biList_interaction_text14: 'معدل الرد (PV) ',
  AnchorCenter_biList_interaction_Popup1: "لقد تلقيت رسائل من 'x' مستخدم",
  AnchorCenter_biList_interaction_Popup2: "لقد قمت بالرد على'n'من أصل'x'مستخدمين أرسلوا لك رسائل",
  AnchorCenter_biList_interaction_Popup3: 'لقد تلقيت رسائل من 100 مستخدم,وقمت بالرد بشكل فعال على 90 منهم . معدل الرد الفعلية الخاصة بك(UV)=90/100=90%',
  AnchorCenter_biList_interaction_Popup4: 'نسبة الرد على الرسائل (UV)',
  AnchorCenter_biList_interaction_Popup5: 'نسبة الرد الفعلي: تحتسب فقط المستخدمين الذين تم الرد عليهم قبل انتهاء المدة المحددة',
  AnchorCenter_biList_interaction_Popup6: 'فهمت',
  AnchorCenter_biList_interaction_Popup7: "لقد أرسلت رسائل إلى 'x' مستخدمين",
  AnchorCenter_biList_interaction_Popup8: "من بين 'x' مستخدماً أرسلت لهم رسائل, 'n' ردّ عليك",
  AnchorCenter_biList_interaction_Popup9: 'نسبة المستخدمين الذين ردوا (UV)',
  AnchorCenter_biList_interaction_Popup10: 'مثال: لقد أرسلت رسائل إلى 100 مستخدم، ورد 50 منهم. معدل الرد الفعال (UV) = 50/100 = 50%',
  AnchorCenter_biList_interaction_Popup11: 'معدل الرد الفعّال: يحتسب فقط الردود التي تم استلامها قبل انتهاء المدة المحددة',
  AnchorCenter_biList_interaction_Popup12: "لقد أرسلت 'x' رسائل",
  AnchorCenter_biList_interaction_Popup13: "من بين'x' الرسائل التي أرسلتها, 'n' تم رد عليها فعليا",
  AnchorCenter_biList_interaction_Popup14: 'نسبة ردود رسائلك (PV)',
  AnchorCenter_biList_interaction_Popup15: 'مثال: لقد قمت بإرسال 100 رسالة، وتم الرد على 70 منها بشكل فعّال. معدل الرد الفعّال لديك (PV) = 70/100 = 70%',
  AnchorCenter_biList_interaction_Popup16: 'المعدل الفعال للرد: يحسب فقط الردود التي تم استلامها قبل انتهاء المدة المحددة',
  program_list_24_ios: 'مسح',
  withdraw_text_26: 'يرجى التحقق من إدخال معلومات الدفع الصحيحة. سوف تؤدي المعلومات غير الصحيحة إلى فشل عملية السحب، وسيتحمل المستخدم اي رسوم إضافية.',
  withdraw_text_29: 'ملاحظات:',
  withdraw_text_43: 'هناك طلبات غير مكتملة لطريقة الدفع هذه ولا يمكن تعديلها أو حذفها مؤقتًا.',
  withdraw_text_44: 'تم الإرسال بنجاح',
  payment_bind_2: 'بطاقة بنكية',
  payment_bind_2_GPAY: 'بطاقة بنكية',
  payment_bind_3: 'اسم البنك',
  payment_bind_4: 'اسم',
  payment_bind_5: 'البريد الإلكتروني',
  payment_bind_6: 'عنوان',
  payment_bind_7: '10 أرقام أو PL بالإضافة إلى 10 أرقام',
  payment_bind_8: 'يبدأ بـ TR بالإضافة إلى 24 رقما',
  payment_bind_9: 'يجب {n}',
  payment_bind_10: 'نجح الحذف',
  payment_bind_11: 'الاسم الشخصي',
  payment_bind_12: 'اسم العائلة',
  Tvwall_gift_9: ' أكمل قائمة الهدايا  بقيمة تساوي أو تزيد عن 2,000 كوينز  لعرضها على جدار الأمنيات ',
  Tvwall_gift_10: ' شروط قفل شاشة الدخولية:',
  Tvwall_gift_32: 'هل يجب عرض الهدايا على جدار الأمنيات؟',
  Tvwall_gift_33: '(انقر لتحديد ليتم التفعبل على الفور)',
  Tvwall_gift_11: 'قفل الشاشة لمدة 10 ثواني: تم إكمال هدايا الأمنيات وتتراوح قيمتها بين 2000 كوينزا و9999 كوينزا',
  Tvwall_gift_12: 'قفل الشاشة لمدة 20 ثانية: تم إكمال هدايا الأمنيات وتتراوح قيمتها بين 10000 كوينزا و49999 كوينزا',
  Tvwall_gift_13: 'قفل الشاشة لمدة 60 ثانية: تم إكمال هدايا الأمنيات وتتراوح قيمتها أكثر من 50000 كوينزا',
  Tvwall_gift_14: 'شروط الظهورعلى جدارالأمنيات العالمي',
  Tvwall_gift_15: 'سجل عادي: تم إنجاز هدايا الأمنيات وتتراوح قيمتها  بين 2000 كوينزا و9999 كوينزا',
  Tvwall_gift_16: 'سجل فاخر: تم إنجازهدايا الأمنيات وتتراوح قيمتها أكبر من أو تساوي 10000 كوينزا',
  Tvwall_gift_17: 'موجود على جدارالأمنيات ',
  Tvwall_gift_18: 'مجهول على جدارالأمنيات ',
  Tvwall_gift_19: 'ليس على جدار الأمنيات ',
  Admob_text7: 'جاري تحضير ',
  Admob_text8: 'الفيديو ليس جاهزا بعد',
  EXP: 'نقطة خبرة',
  'Terms of Service': 'شروط الخدمة',
  'Privacy Policy': 'سياسة الخصوصية',
  Experience_Card_Statement: '*بطاقات التجربة لا تتمتع بهذه الميزة',
  Official_website_payment_1: 'شحن SOYO أونلاين عبر الإنترنت',
  Official_website_payment_2: 'معرف الحساب للشحن',
  Official_website_payment_3: 'أدخل المعرف',
  Official_website_payment_4: 'ما هو المعرف؟',
  Official_website_payment_5: 'يرجى التحقق أولاً لحماية خصوصية وأمان الحساب.',
  Official_website_payment_6: 'تحقق',
  Official_website_payment_7: 'إشعار للمستخدمين',
  Official_website_payment_8: '1. لا تشتري كوينزات SOYO من خلال مواقع غير رسمية أو غير مصرح بها لتجنب النزاعات غير الضرورية.',
  Official_website_payment_9: '2. لا تثق بأي معلومات غير رسمية منشورة على مواقع إلكترونية أخرى أو قنوات أتصال أو مجموعات تواصل. كل معلومات الأحداث تكون من خلال وتخضع  للإعلان الرسمي عبر الموقع الرسمي أو إشعارات  النظام.',
  Official_website_payment_10: '3. لا يتم دعم أي معاملات تمت أوفلاين ( ليست عبر الأنترنت ).',
  Official_website_payment_11: '4. لا تكشف معلومات حسابك لأي شخص آخر لأي سبب أو بأي طريقة.',
  Official_website_payment_12: '5. لا تتحمل شركة SOYO مسؤولية أي نزاعات بين المستخدمين والطرف الثالث.',
  Official_website_payment_13: '6. إذا كانت هناك أي مشكلة في الشحن، فيرجى التواصل مع خدمة العملاء أو مراسلتنا عبر البريد الإلكتروني.',
  Official_website_payment_14: 'مكافحة غسيل الأموال',
  Official_website_payment_15: '1. يجب على المستخدم التأكد من أن لديه هوية قانونية ولديه القدرة على استخدام الخدمات التي نقدمها ولن يشارك أو يرتبط بأي نشاط إجرامي.',
  Official_website_payment_16: '2. حساب المستخدم مخصص لاستخدامه الشخصي فقط وهو ملك له شخصيًا. لا يجوز لك نقل حسابه أو تأجيره أو التصرف فيه لأي طرف ثالث.',
  Official_website_payment_17: '3. يجب على المستخدمين التأكد من أن جميع الأموال المستخدمة للشحن يتم الحصول عليها من خلال طرق قانونية وأنها ليست مرتبطة بأنشطة أو سلوك إجرامي في أي وقت.',
  Official_website_payment_18: 'طريقة الدفع',
  Official_website_payment_19: 'يتم حاليًا استخدام قناة الدفع {%s}.',
  Official_website_payment_20: 'خيار الشحن',
  Official_website_payment_21: 'تغيير',
  Official_website_payment_22: 'معرف الحساب غير موجود!',
  Official_website_payment_23: 'لا توجد طرق دفع معتمدة حتى الآن',
  Official_website_payment_24: 'قم بكتابة طريقة الدفع المطلوبة',
  Official_website_payment_25: 'الاستعلام عن نتائج المعاملات',
  Official_website_payment_26: 'سيتم تأكيد نتيجة الشحن',
  Official_website_payment_27: 'قد تتأخر نتيجة الشحن. يرجى الدخول إلى التطبيق للتحقق من سجل شحن الحساب. إذا لم يصل  لفترة طويلة، يرجى التواصل مع خدمة العملاء.',
  Official_website_payment_28: 'تحديث',
  Official_website_payment_29: 'فشل الشحن',
  Official_website_payment_30: 'معلومات للمساعدة:',
  Official_website_payment_31: '1. يرجى التأكد من صحة المبلغ وطريقة الدفع، بما في ذلك المعلومات الشخصية ورقم بطاقة الائتمان وفترة الصلاحية ورمز CVV وما إلى ذلك.',
  Official_website_payment_32: '2. يرجى التحقق مما إذا كان رصيد الحساب كافيا.',
  Official_website_payment_33: '3. تحقق مما إذا كان اتصال شبكة الأنترنت مستقرًا في بعض الأحيان قد تتسبب مشكلات الشبكة في فشل الدفع.',
  Official_website_payment_34: '4. حاول التغيير إلى طرق دفع أخرى أو حاول إعادة الشحن لاحقًا.',
  Official_website_payment_35: '5. إذا لم يتم حل المشكلة بعد، يفضل التواصل مع خدمة العملاء الخاصة بقناة الشحن للحصول على مزيد من المساعدة.',
  Official_website_payment_36: 'تم الشحن بنجاح',
  Official_website_payment_37: 'يرجى الدخول إلى التطبيق لعرض سجل الشحن للحساب',
  Official_website_payment_38: 'فشل مهلة الرابط',
  Official_website_payment_39: 'انتهت صلاحية الرابط. يرجى إعادة إنشاء رابط دفع في التطبيق.',
  payment_bind_13: 'Contract Address',
  payment_bind_14: 'Epay Email',
  payment_bind_15: 'Note: TRC-20 protocol',
  payment_bind_16: 'Note: Ensure your Epay account is verified',
  payment_bind_17: 'Contract address starts with T',
  payment_bind_18: 'Cannot exceed 40 characters',
  payment_bind_19: 'Must match your Epay account email',
  payment_bind_20: 'Enter email address',
  payment_bind_21: 'Gcash account',
  payment_bind_22: 'Must be 11 digits starting with 09',
  payment_bind_23: 'Only allow English letters, spaces, hyphens (-), periods (.), and commas (,) and less than 100 characters.',
  task_miss: 'فقد',
  re_signing: 'إعادة تسجيل',
  anchor_task_txt1: 'قم بزيارة الأصدقاء القدامى (واحصل على ردود منهم) لعدد {%s1} مرة واحصل على {%s2} 💎',
  anchor_task_txt2: 'يصل عدد الدردشات مع المستخدمين الجدد > 29 إلى {%s1} مرة، ستحصل على {%s2} 💎',
  task_txt21: 'تسجيل الدخول اليومي',
  task_txt22: 'يوم',
  task_txt23: 'تسجيل الدخول',
  task_txt24: 'قائمة المهام',
  task_txt25: 'مهام المضيف',
  task_txt31: 'تم تسجيل الدخول ',
  sign_in_text3_new: 'انقر ل2x مكافأت',
  Double_Rewards_text1: 'كن SVIP واحصل على جائزة مضاعفة!',
  chat_skill_title: 'يجب عليك قراءتها',
  program_faq_2: 'ما هو جدول الأحداث ؟',
  program_faq_3:
    'لتعزيز المتعة والتفاعل في الغرفة ، نقدم لكم خاصية جدول الاحداث. يعد جدول الأحداث بمثابة معاينة مسبقة لأحداث الغرفة القادمة. ويمكن للمشاهدين دعم وإرسال الهدايا بناءً على جدول الأحداث، للتعبير عن تفضيلاتهم وحماسهم لأحداث الغرف المختلفة .',
  program_faq_4: 'كيفيه الدخول لجدول الأحداث ؟',
  program_faq_5:
    'يمكن لمالك الغرفة أو المشرف إيجاد جدول الأحداث في قائمة الإعدادات. يمكن للمشاهدين في الغرفة الدخول إلى جدول الأحداث من خلال الضغط على الزاوية اليمنى العليا من الغرفة عندما يكون الحدث جاري.',
  program_faq_6: 'كيفيه إنشاء جدول حدث ؟',
  program_faq_7: 'انقر فوق "إضافة حدث" وأدخل اسم الحدث الذي تريد تنفيذه.',
  program_faq_8: 'بعد إنشاء جدول الحدث ومراجعته ، اضغط على "بدء جدول الحدث" لجعله مرئيًا للجمهور. بمجرد فعل هذا، سيتمكن المشاهدون من رؤية جدول الحدث.',
  program_faq_9: 'كيفية التفاعل مع جدول الحدث ؟',
  program_faq_10: 'يمكن لكل فرد في الغرفة رؤية جدول الأحداث من خلال النقر على الزاوية اليمنى العليا وإظهار الدعم لأحداثهم المفضلة من خلال النقر على "مهتم" أو "إرسال هدية" لزيادة شعبية الحدث.',
  program_faq_11: 'مسح أو غلق جدول حدث معين ',
  program_faq_12:
    'يمكن لمالك الغرفة أو مشرفيها التمرير إلى اليسار على الحدث والنقر على "حذف" لإزالة الحدث. سيتم إغلاق جدول الحدث تلقائيًا بعد 7 أيام، ولكن يمكن للمضيف أو المسؤول أيضًا إغلاقه يدويًا. سيؤدي حذف جدول الحدث أو إغلاقه  إلى مسح بيانات شعبية الحدث.',
  program_faq_13: 'ملاحظات:',
  program_faq_14: '1. يسمح جدول الأحداث بإضافة 15 حدث كحد أقصى.',
  program_faq_15: '2. لا يمكن فتح جدول الحدث الذي فشل بعد مراجعته.',
  program_faq_16: '3. سيتم إغلاق جدول الحدث تلقائيًا بعد 7 أيام من فتحه.',
  program_faq_part1: 'الجزء الأول',
  program_faq_part2: 'الجزء الثاني',
  program_faq_part3: 'الجزء الثالث',
  program_faq_part4: 'الجزء الرابع',
  'family.level.instructions': 'تعليمات على مستوى العائلة',
  'family.division.privilege': 'الرتبة و امتيازات الرتبة',
  'family.division.privilege.describe':
    'تعتمد الرتبة على الخبرة الشهرية، ويتم إعادة التعيين في اليوم الأول من كل شهر. يمكن للرتبة الترقية ،الاحتفاظ بلمستوى والتخفيض. يتطلب الاحتفاظ بالرتبة الحالية فقط تحقيق 100% من نقاط الترقية المقابلة.',
  'family.diamonds.exch': 'تحويل الالماس',
  'family.new.diamonds.exch': 'الأوسمة',
  'family.label.tag': 'شارة العائلة',
  'family.label.frame': 'اطار العائلة',
  'family.label.chatroom': 'خلفية غرف الدردشة',
  'family.label.familyBg': 'خلفية العائلة',
  'family.bronze.family': 'العائلة البرونزية',
  'family.silver.family': 'العائلة الفضية',
  'family.golden.family': 'العائلة الذهبية',
  'family.diamond.family': 'عائلة الماس',
  'family.king.family': 'عائلة الملك',
  'family.task.daily.exp': 'الخبرة اليومية',
  'family.task.monthly.exp': 'الخبرة الشهرية',
  'family.task.exp.describe': 'الخبرة التي تساهم بها في العائلة',
  'family.task.tips': 'أكمل المهام خلال أول يومين من الانضمام إلى العائلة لاكتساب خبرة مضاعفة.',
  'family.task.invite.join': 'دعوة الأصدقاء للانضمام إلى العائلة',
  'family.task.treasure.unlocked': 'إذا وصل عدد الأعضاء إلى %s، سيتم فتح صندوق كنز العائلة في الساعة 12 صباحًا يوميًا',
  'family.task.your.rewards': 'مكافآتك',
  'family.task.rules.first': 'بعد استيفاء شروط صندوق كنز العائلة، سيتم فتح المكافآت الموجودة في صندوق الكنز بشكل عشوائي.',
  'family.task.rules.second': 'الأعضاء الذين يكملون المهمة قبل فتح صندوق الكنز سيحصلون على مكافآت.',
  'family.task.rules.three': 'سيتم توزيع العناصر على حقيبة الفائزين، وسيتم توزيع المكافآت الأخرى مثل الخبرة أو العملات المعدنية على الحسابات.',
  'family.task.rules.four': 'سيتم توزيع المكافآت تلقائيًا بعد فتح صندوق الكنز',
  'family.task.captain.task': 'مهمة الكابتن',
  'family.task.treasure.chest': 'صندوق كنز العائلة',
  'family.task.interactive': 'مهام تفاعلية',
  'family.task.cumulative': 'مهام تراكمية',
  'family.task.others': 'آخرون',
  'family.task.your.rewards': 'مكافآتك',
  'family.task.family.level': 'يعتمد مستوى العائلة على الخبرة المتراكمة. كل مستوى يتوافق مع الامتياز المقابلة',
  'family.exp.title': 'خبرة العائلة',
  'family.exp.content.title.1': 'كيفية الحصول على خبرة العائلة',
  'family.exp.content1.text.title.1': ' يرسل أفراد الأسرة الهدايا',
  'family.exp.content1.text.1': '10 كوينز',
  'family.exp.content1.text.2': '1 خبرة',
  'family.exp.content1.text.3': '50 جوهرة ',
  'family.exp.content1.text.title.2': ' يتلقى أفراد الأسرة الهدايا',
  'family.exp.content1.text.title.3': ' اكمال مهام الاسرة',
  'family.exp.content.title.2': 'امتيازات مستوى الأسرة ومستوى المستخدم',
  'family.exp.table.title.1': 'خبرة',
  'family.exp.table.title.2': 'عضو',
  'family.exp.table.title.3': 'نائب الزعيم',
  'family.exp.table.title.4': 'ادمن',
  'game.task.center.get': 'استلام',
  'game.task.center.received': 'تم الجمع',
  'common.EXP': 'خبرة',
  "childrens.safety.policy": "سلامة الطفل",
};

export default AR;
