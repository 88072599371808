import axios from 'axios';
import Cookies from 'js-cookie';
// import { format } from '../js/i18n';
import { Toast } from 'antd-mobile';
import { systemErrCode, timeoutErrCode, cancelErrCode, networkErrCode } from '@/asset/config/constant';
// import AppCore from 'appcore';
import { getValueFromUA } from './utils';
const urlTest = /(?:https*:)*\/\/([^/]+)/;

const CancelToken = axios.CancelToken;
axios.defaults.headers.common['platform'] = 'wap';
axios.defaults.headers.common['appPlatform'] = getValueFromUA('appPlatform') || 3;
// axios.defaults.headers.common['Authorization'] = process.env.NODE_ENV === 'development' ? 'KtY3Wyf1YP7oqnR/mj2Ab4b7hl8+JsvEb9w1VP23i/eubPJxtGs5dDP4Ze178yW5' : getValueFromUA('Authorization') || Cookies.get('Authorization'); //  AppCore.getToken(getOsType()) 安卓是回调函数方式设置的，异步获取token会设置不成功
// axios.defaults.headers.common['Authorization'] = process.env.NODE_ENV === 'development' ? 'G7TITcBUnyt5yL0PXF+uwUGHD0teIhRLjvYupHXvsn2ubPJxtGs5dDP4Ze178yW5' : getValueFromUA('Authorization') || Cookies.get('Authorization'); //  AppCore.getToken(getOsType()) 安卓是回调函数方式设置的，异步获取token会设置不成功
axios.defaults.headers.common['Authorization'] = process.env.NODE_ENV === 'development' ? '57g90vj3ajSxGTdUrYcIMxiNcvA4uezxIffSZQrtjKmubPJxtGs5dDP4Ze178yW5' : getValueFromUA('Authorization') || Cookies.get('Authorization'); //  AppCore.getToken(getOsType()) 安卓是回调函数方式设置的，异步获取token会设置不成功
axios.defaults.headers.common['deviceid'] = getValueFromUA('deviceId') || '';
axios.defaults.headers.common['appVersionName'] = getValueFromUA('version') || '';
axios.defaults.headers.common['brand'] = getValueFromUA('manufacturer') || '';
axios.defaults.headers.common['model'] = getValueFromUA('model') || '';
axios.defaults.headers.common['appVersionCode'] = getValueFromUA('versionInt') || '';
axios.defaults.headers.common['apiLevel'] = getValueFromUA('apiLevel') || '13';
axios.defaults.headers.common['iosApiLevel'] = getValueFromUA('apiLevel') || '13';
axios.defaults.headers.common['gender'] = process.env.NODE_ENV === 'development' ? 0 : getValueFromUA('gender') || '-1'; // 开发环境写为0，可修改，测试线上取ua数据
axios.defaults.headers.common['packageName'] = getValueFromUA('pkg') || '';
axios.defaults.headers.common['country'] = getValueFromUA('country') || '';

axios.defaults.timeout = 30000;
window.axios = axios;

const lockUrl = {};
window.lockUrl = {};
// 后到优先策略：取消前一个未完成的ajax请求，然后发送新的ajax请求
const s1 = /^@.+/;

// 节约型策略：即共享类型，由同类型的第一个请求发送ajax，（在第一个ajax返回之前的）后续的同类型共享ajax返回结果
const s2 = /^\\!.+/;

const detailLockKey = (config, promise) => {
  const { lockKey } = config;
  if (!lockKey) {
    return promise;
  }
  lockUrl[lockKey] = lockUrl[lockKey] || [];
  const cur = lockUrl[lockKey].slice(0);
  // 取消前面的请求
  if (cur.length && s1.test(lockKey)) {
    cur.forEach(one => {
      one.config.source.cancel();
    });
    lockUrl[lockKey] = [];
  }
  if (cur.length && s2.test(lockKey)) {
    const p = cur[0].promise;
    p.then(
      () => {
        lockUrl[lockKey] = [];
      },
      () => {
        lockUrl[lockKey] = [];
      }
    );
    config.source.cancel();
    return cur[0].promise;
  }
  lockUrl[lockKey].push({
    promise,
    config,
  });
  return promise;
};

// 添加时间戳
axios.interceptors.request.use(
  function(config) {
    // 先从window上取默认值，如果取到就用window上的
    const globalConfig = window.config || {};
    const country = globalConfig.country;
    const lang = globalConfig.lang;
    const params = config.params || {};
    let headers = config.headers || {};
    // 先写死1，后期添加国家会有多种
    headers['country'] = country;
    headers['lang'] = lang;
    headers['language'] = lang;
    params._t = +new Date();
    // 传入的地址是绝对地址
    if (urlTest.test(config.url)) {
      const host = (config.url.match(urlTest) || [])[1];
      // 发出的请求是跨站的，则去掉所有header
      if (host && host !== window.location.host) {
        headers = {};
      }
    }
    config.params = params;
    config.headers = headers;
    return config;
  },
  function(error) {
    return Promise.reject(error);
  }
);

axios.interceptors.response.use(
  function(res) {
    const systemErrInfo = 'ajax.system';
    const data = res.data;
    const status = res.status;
    console.log(res, 'ajax res')
    // 将statusCode放上
    if (typeof data === 'object') {
      data.statusCode = status;
    }
    if ((status >= 200 && status < 300) || status === 401) {
      if (!data || (typeof data === 'string' && data.indexOf('<!DOCTYPE') >= 0)) {
        return {
          code: systemErrCode,
          message: systemErrInfo,
        };
      }
      // 返回数据没有bizCode字段认为出错了
      if (typeof data === 'object' && !data.code && !data.bizCode && !data.result) {
        return {
          code: systemErrCode,
          message: systemErrInfo,
        };
      }
      if (data.code !== 10000 && data.code !== 1 && data.message) {
        Toast.show({
          content: data.message,
        });
      }
      return data;
    }
    return {
      code: systemErrCode,
      message: systemErrInfo,
    };
  },
  function(err) {
    const timeoutErrInfo = 'ajax.timeout';
    const systemErrInfo = 'ajax.system';
    const networkErrInfo = 'ajax.network';
    if (axios.isCancel(err)) {
      return {
        code: cancelErrCode,
        message: '',
      };
    }
    // console.dir(err);
    const message = err.message;
    // 超时错误
    // message是axios写的
    if (message.startsWith('timeout of')) {
      return {
        code: timeoutErrCode,
        message: timeoutErrInfo,
      };
    }
    // 没有响应网络错误
    if (message.startsWith('Network Error')) {
      return {
        code: networkErrCode,
        message: networkErrInfo,
      };
    }
    // 请求被打断
    if (message.startsWith('Request aborted')) {
      return {
        code: systemErrCode,
        message: systemErrInfo,
      };
    }
    // 系统错误
    return {
      code: systemErrCode,
      message: systemErrInfo,
    };
  }
);

const req = axios.Axios.prototype.request;
/**
 * 覆盖全局request的方法，方便处理异常出现的情况
 */
axios.Axios.prototype.request = function(config) {
  if (config.lockKey) {
    const source = CancelToken.source();
    config.source = source;
    config.cancelToken = source.token;
  }
  const promise = req.call(this, config);
  return detailLockKey(config, promise);
};
