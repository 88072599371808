import { airWallexToken, validation } from '@/services/api';

const airWallex = {
  namespace: 'airWallex',
  state: {},
  effects: {
    *getClientSecret({ payload, userId = '' }, { call }) {
      const res = yield call(airWallexToken, payload, userId);
      return res;
    },
    *validation({ payload }, { call }) {
      yield call(validation, payload);
    },
  },
}

export default airWallex;
