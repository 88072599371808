import { getUrl } from '@/services/api';

const help =  {
  namespace: 'VIPHelp',
  state: {},
  reducers: {},
  effects: {
    *getUrl({ payload }, { call }) {
      console.log(payload, 'payload');
      const res = yield call(getUrl, payload);
      return res;
    },
  },
};

export default help;

