import { airtelPay, airtelCheck} from '@/services/api';

const airtel =  {
  namespace: 'airtel',
  state: {},
  reducers: {},
  effects: {
    *airtelPay({ payload }, { call }) {
      const res = yield call(airtelPay, payload);
      return res;
    },
    *airtelCheck({ payload }, { call }) {
      const res = yield call(airtelCheck, payload);
      return res?.data;
    },
  },
};

export default airtel;
