import global from './global'
import feedback from './feedback'
import report from './report'
import totalRank from './totalRank'
import goldCoin from './goldCoin'
import giftWall from './giftWall'
import haloLevel from './haloLevel'
import userLevel from './userLevel'
import coinMerchant from './coinMerchant'
import curAristocracyModel from './curAristocracyModel';
import vipPrivileges from './vipPrivileges';
import totalRankVoice from './totalRankVoice';
import diamondFlow from './diamondFlow';
import myEarning from './myEarning';
import giftRank from './giftRank';
import newTaskCenter from './newTaskCenter';
import luckyGiftList from './luckyGiftList';
import withdrawBind from './withdrawBind';
import share from './share';
import TVWall from './tvWall';
import recharge from './recharge';
import airtel from './airtel';
import newUserRewards from './newUserRewards';
import airWallex from './airWallex';
import familyTak from './familyTask';
import mpesa from './mpesa';
import help from './help';
import incomeRules from './incomeRules';


const models = [global, incomeRules, help, mpesa, familyTak, airWallex, newUserRewards, airtel, recharge, TVWall, share, withdrawBind, luckyGiftList, giftRank, myEarning, diamondFlow, totalRankVoice, feedback, report, totalRank, goldCoin, giftWall, haloLevel, userLevel, coinMerchant, curAristocracyModel, vipPrivileges, newTaskCenter]

export default models
