import { diamondToMoneyRatio } from '@/services/api';

const incomeRules =  {
  namespace: 'incomeRules',
  state: {},
  reducers: {},
  effects: {
    *diamondToMoneyRatio(_, { call }) {
      const res = yield call(diamondToMoneyRatio);
      return res;
    },
  },
};

export default incomeRules;

