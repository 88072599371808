const HI = {
  'lucky.darw.ruls.1': 'चैट रूम के अंदर उपहार भेजें और ड्रॉ में प्रवेश करने का मौका पाएं',
  'lucky.darw.ruls.2': 'प्रत्येक 5000 कॉइन मूल्य के उपहार आपको 1 ड्रॉ देते हैं',
  'lucky.darw.ruls.3': 'उदाहरण के लिए: 50,000 सिक्के = 10 ड्रॉ, 100,000 सिक्के = 20 ड्रॉ। अधिक ड्रॉ प्राप्त करने के लिए और उपहार भेजें',
  'lucky.darw.ruls.4': 'यदि दिन समाप्त हो जाता है और आपने अपने ड्रॉ का उपयोग नहीं किया, तो ईवेंट समाप्त होने के बाद आपके सभी ड्रॉ 0 हो जाएंगे',
  'lucky.darw.number': 'मेरे ड्रॉ की संख्या',
  'lucky.darw.reset.time': 'रीसेट समय',
  'lucky.darw.ranking': 'लकी ड्रा रैंकिंग',
  'lucky.darw.use': 'उपयोग करने के लिए आगे जाए ',
  'lucky.darw.no.times': 'ड्रा की वर्तमान संख्या पर्याप्त नहीं है, कमरे में जाएँ और उपहार भेजें',
  'lucky.darw.end': 'आयोजन समाप्त हुआ',
  'lucky.darw.coin.one.times': '1 बार लागत ड्रा करें',
  'lucky.darw.coin.draw': 'ड्रा',
  'lucky.darw.coin.more.times': 'समय खर्च होगा',
  'lucky.darw.coin.more.will.cost': 'इसकी कीमत यह होगा',
  'fission.coins': 'सिक्के',
  'common.recharge.text': 'सिक्के काफी नहीं हैं। क्या आप रिचार्ज करना चाहते हैं?',
  'fission.cancel': 'रद्द',
  'lucky.darw.title': 'लकी ड्रा',
  'gacha.play.3': 'बार',
  'gacha.play.1': 'खोलें',
  'gacha.play.2': 'बार',
  'common.cap.Congratulations': 'बधाई हो',
  'common.up.OK': 'ठीक है',
  'recharge.result.pending': 'ऑपरेशन पूरा हुआ',
  'recharge.result.tips': 'यदि सिक्के समय पर नहीं आते हैं, तो कृपया चिंता न करें, नेटवर्क में देरी हो सकती है। यदि आपके कोई प्रश्न हैं, तो कृपया सोल यू आधिकारिक ग्राहक SOYO संपर्क करें।',
  'recharge.result.tips.click': 'App पर लौटने के लिए क्लिक करें',
  '1': 'जनवरी',
  '2': 'फ़रवरी',
  '3': 'मार्च',
  '4': 'अप्रैल',
  '5': 'मई',
  '6': 'जून',
  '7': 'जुलाई',
  '8': 'अगस्त',
  '9': 'सितंबर',
  '10': 'अक्टूबर',
  '11': 'नवंबर',
  '12': 'दिसंबर',
  'coin.recharge.modal.title': 'भुगतान की विधि',
  'coin.recharge.change.country': 'देश का चयन करें',
  'coin.recharge.current.country': 'वर्तमान में भुगतान चैनल का उपयोग कर रहा है',
  'ajax.timeout': 'क्षमा करें, कुछ गलत हो गया। कृपया बाद में पुन: प्रयास करें',
  'ajax.system': 'क्षमा करें, कुछ गलत हो गया। कृपया बाद में पुन: प्रयास करें',
  'ajax.network': 'कोई नेटवर्क कनेक्शन नहीं. कृपया बाद में पुन: प्रयास करें.',
  blank: '<p>कोई संबंधित सामग्री नहीं</p> <p>कृपया अन्य चैनलों की जांच करें</p>',
  'pull.refresh.deactivate': 'ताज़ा करने के लिए खींचें',
  'pull.refresh.activate': 'ताज़ा करने के लिए रिलीज़ करें',
  'pull.refresh.release': 'अधिक लोड हो रहा है',
  'push.refresh.activate': 'अधिक लोड हो रहा है',
  'push.refresh.noMore': 'अधिक जानकारी नहीं',
  'click.refresh': 'ताज़ा करने के लिए क्लिक करें',
  'share.whatsapp.tip': 'हो सकता है कि आपके फ़ोन पर WhatsApp स्थापित न हो, इसे स्थापित करें और पुन: प्रयास करें.',
  'share.ok': 'ठीक है',
  'share.install': 'साउंडी रेडियो एपीपी अब स्थापित करें',
  'share.download': 'डाउनलोड',
  'share.play': 'साउंडी रेडियो पर खेलें',
  'share.live': 'रहना',
  'expection.oops': 'उफ़।।।',
  'expection.homeBtn': 'होम पेज',
  'ERR500.tips': 'कुछ गलत हो गया। डेवलपर्स को इस बारे में सूचित कर दिया गया है और वे इस समस्या को ठीक करेंगे। कृपया बाद में पुन: प्रयास करें. धन्यवाद।',
  'err404.tips': 'जिस समाचार को आप खोज रहे हैं वह मौजूद नहीं है। कृपया अन्य विषयों की जाँच करें। हम वहां आपका इंतजार कर रहे हैं!',
  commonErr: '<p>सिस्टम व्यस्त,</p> <p>कृपया बाद में पुन: प्रयास करें</p>',
  loading: 'लोड।।।',
  retry: 'पुनर्प्रयास',
  viewMore: 'अधिक देखें',
  more: 'अधिक',
  'month.select': 'महीने का चयन करें',
  hot: 'गरम',
  all: 'सब',
  'month.select.day': 'दिनांक का चयन करें',
  'coin.merchant.title': 'सिक्का विक्रेता',
  'coin.merchant.set.password': 'अपना ट्रेडिंग पासवर्ड सेट करें',
  'coin.merchant.confirm.again': 'लेन-देन पासवर्ड की पुन: पुष्टि करें',
  'coin.merchant.enter.password': 'अपना लेन-देन पासवर्ड दर्ज करें',
  'coin.merchant.enter.original.password': 'मूल पासवर्ड दर्ज करें',
  'coin.merchant.enter.new.password': 'नया पासवर्ड दर्ज करें',
  'coin.merchant.confirm.new.again': 'नए पासवर्ड की पुन: पुष्टि करें',
  'coin.merchant.change.password': 'परिवर्तन।\nपासवर्ड',
  'coin.merchant.history.title': 'लेन-देन\nइतिहास',
  'coin.merchant.history': 'इतिहास',
  'coin.merchant.user': 'उपभोक्ता',
  'coin.merchant.chat': 'गपशप',
  'coin.merchant.coins.num': 'सिक्कों की सूची:',
  'coin.merchant.transfer.coins': 'सिक्कों का स्थानांतरण',
  'coin.merchant.enter.user.id': 'उपयोगकर्ता ID दर्ज करें',
  'coin.merchant.search': 'पूछताछ',
  'coin.merchant.not.found': 'उपयोगकर्ता नहीं मिला',
  'coin.merchant.enter.amount': 'राशि दर्ज करें',
  'coin.merchant.tips':
    'आप उपयोगकर्ता को एक अतिरिक्त राशि हस्तांतरित करने वाले हैं, कृपया नाम और सिक्कों की मात्रा की फिर से पुष्टि करें। यह ऑपरेशन अपरिवर्तनीय है, और अधिकारी किसी भी गलत ऑपरेशन के लिए जिम्मेदार नहीं है।',
  'coin.merchant.enter.transaction.password': 'लेन-देन पासवर्ड दर्ज करें',
  'common.cap.Confirm': 'पुष्टि',
  'coin.merchant.enter': 'घुसना',
  'coin.merchant.to': 'तक:',
  'coin.merchant.amount': 'राशि:',
  'coin.merchant.id': 'परिचय',
  'coin.merchant.password.tips': 'पासवर्ड कम से कम 8 वर्णों का होना चाहिए, और इसमें बड़े और छोटे अक्षर और संख्याएं शामिल होनी चाहिए।',
  'coin.merchant.password.error': 'गलत पासवर्ड।कृपया पुन: प्रयास करें।',
  'coin.merchant.success': 'प्रेषण सफल रहा',
  'coin.merchant.list.title': 'राशि का चयन करें',
  'coin.merchant.list.desc': 'इस यूजर को सिक्के ट्रांसफर करना रिचार्ज के बराबर है, जिससे वीआईपी एक्सपीरियंस भी बढ़ेगा।',
  'coin.merchant.transfer': 'तबादला',
  'coin.merchant.recharge.title': 'सिक्का विक्रेता\nरिचार्ज करें',
  'coin.merchant.min.input': 'न्यूनतम राशि 1000',
  'coin.merchant.der.channel': 'भुगतान विधि:',
  'coin.merchant.der.channel.tips': 'यदि आप निम्नलिखित विधियों के माध्यम से भुगतान पूरा नहीं कर सकते हैं, तो कृपया खरीद के बारे में पूछताछ करने के लिए आधिकारिक टीम के व्हाट्सएप से संपर्क करें:',
  'coin.merchant.der.channel.tips.link': 'प्रतिलिपि',
  'coin.merchant.der.current.country': 'वर्तमान में भुगतान चैनल का उपयोग कर रहा है',
  'coin.merchant.der.change.country': 'देश का चयन करें',
  'coin.merchant.der.modal.title': 'भुगतान की विधि',
  'common.cap.Recharge': 'पुनर्भरण',
  'coin.merchant.der.id': 'TalkTalk ID',
  'coin.merchant.der.name': 'आपका टॉकटॉक उपनाम:',
  'coin.merchant.der.error': '* कोई परिणाम नहीं मिला',
  'coin.merchant.der.amount': 'रिचार्ज राशि:',
  'coin.merchant.der.redeem': 'मुक्त करना',
  'coin.merchant.der.fail': 'रिचार्ज करने में विफल',
  'coin.merchant.der.fail.tips': 'अपने खाते को रिचार्ज करने में विफल, कृपया हमसे संपर्क करें: TT_support@molelive.com यदि आपके कोई प्रश्न हैं!',
  'coin.merchant.der.complete': 'रिचार्ज पूरा',
  'coin.merchant.der.complete.tips': '{संख्या} सिक्कों को {name} पर सफलतापूर्वक रिचार्ज किया गया है! अधिक मज़ा का आनंद लेने के लिए लॉग इन करें!',
  'coin.merchant.der.another': 'किसी अन्य खाते को रिचार्ज करें',
  'coin.merchant.der.more': 'अधिक रिचार्ज करें',
  'coin.merchant.der.problem': 'समस्याओं का सामना करें?',
  'coin.merchant.der.bind.phone': 'मोबाइल नंबर बांधें',
  'coin.merchant.der.sms.code': 'सत्यापन कोड दर्ज करें',
  'coin.merchant.der.sms.tips': 'एक असामान्य गतिविधि का पता चला है। अपने खाते की सुरक्षा सुनिश्चित करने के लिए, कृपया नीचे दी गई सत्यापन प्रक्रिया को पूरा करें।',
  'coin.merchant.der.bind.phone.placeholder': 'फोन संख्या',
  'coin.merchant.der.resend': 'भेजें',
  'common.cap.Continue': 'जारी रखना',
  'coin.merchant.der.code.tips': 'यदि आप सत्यापन कोड प्राप्त नहीं कर सकते हैं, तो कृपया आधिकारिक टीम के व्हाट्सएप से संपर्क करें:',
  'total.rank.gift': 'साप्ताहिक<बीआर >/',
  'total.rank.hour': 'घंटेवार',
  'total.rank.day': 'दिन',
  'total.rank.week': 'साप्ताहिक',
  'total.rank.month': 'मासिक',
  'total.rank.last.hour': 'अंतिम घंटे TOP3',
  'total.rank.last.day': 'अंतिम दिन TOP3',
  'total.rank.last.week': 'पिछले हफ्ते TOP3',
  'total.rank.last.month': 'पिछले महीने TOP3',
  'total.rank.this.hour': 'इस घड़ी',
  'total.rank.this.day': 'इस दिन',
  'total.rank.this.week': 'इस सप्ताह',
  'total.rank.this.month': 'इस महीने',
  'total.rank.gap': 'Gap{X}',
  'total.rank.hide': 'रैंक में छिपना',
  'total.rank.hide.name': 'मिस्टर मिस्ट्री',
  'total.rank.hide.info': 'छुपी हुई जानकारी',
  'total.rank.help.title': 'रैंकिंग के नियम',
  'total.rank.help': `<h1>रैंकिंग प्रकार</h1>
  <p>रैंक &lt;कमरा&gt;: समय अवधि के दौरान, सभी कमरों को कमरे में भेजे गए सिक्के उपहारों की मात्रा के अनुसार क्रमबद्ध किया जाएगा।</p>
  <p>रैंक &lt;गिफ्टर&gt;: समय अवधि के दौरान, सभी उपयोगकर्ताओं को उपयोगकर्ता द्वारा भेजे गए सिक्के उपहारों की मात्रा के अनुसार रैंक किया जाएगा।</p>
  <p>रैंक &lt;CHARMER&gt;: समय अवधि के दौरान, सभी उपयोगकर्ताओं को आपके द्वारा प्राप्त सिक्के उपहारों की मात्रा के अनुसार रैंक किया जाएगा।</p>
  <h1>रैंक समय चक्र</h1>
  <p>रैंकिंग चक्र आमतौर पर घंटे, दिन, सप्ताह और महीने का होता है।</p>
  <p>रैंक पिछले चक्र के शीर्ष 3 के साथ-साथ वर्तमान चक्र के शीर्ष 50 को वास्तविक समय में अपडेट करती है।
  (1 मिनट से कम की देरी के साथ)</p>
  <p>रीफ्रेश टाइम (UTC+3): दैनिक रैंक सुबह 5 बजे रिफ्रेश; साप्ताहिक रैंक प्रत्येक सोमवार सुबह 5 बजे ताज़ा होती है; मासिक रैंक हर महीने की पहली तारीख को सुबह 5 बजे ताज़ा होती है।</p>
  <h1>गोपनीयता की सुरक्षा</h1>
  <p>कुछ रैंक विशिष्ट संख्याएं नहीं दिखाएंगी; </p>
  <p>हालाँकि, पिछली रैंकिंग के बीच का अंतर प्रदर्शित होता है; </p>
  <p>शीर्ष 3 एक दूसरे के बीच अंतर नहीं दिखाएंगे।</p>`,
  'total.rank.help.eu': `
  <h1>रैंक समय चक्र</h1>
  <p>रैंकिंग चक्र आमतौर पर घंटे, दिन, सप्ताह और महीने का होता है।</p>
  <p>रैंक पिछले चक्र के शीर्ष 3 के साथ-साथ वर्तमान चक्र के शीर्ष 50 को वास्तविक समय में अपडेट करती है।
  (1 मिनट से कम की देरी के साथ)</p>
  <p>रीफ्रेश टाइम (UTC+3): दैनिक रैंक सुबह 5 बजे रिफ्रेश; साप्ताहिक रैंक प्रत्येक सोमवार सुबह 5 बजे ताज़ा होती है; मासिक रैंक हर महीने की पहली तारीख को सुबह 5 बजे ताज़ा होती है।</p>
  <h1>गोपनीयता की सुरक्षा</h1>
  <p>कुछ रैंक विशिष्ट संख्याएं नहीं दिखाएंगी; </p>
  <p>हालाँकि, पिछली रैंकिंग के बीच का अंतर प्रदर्शित होता है; </p>
  <p>शीर्ष 3 एक दूसरे के बीच अंतर नहीं दिखाएंगे।</p>`,
  'common.cap.Ranking': 'रैंकिंग',
  'total.rank.room': 'कमरा',
  'total.rank.sent': 'गिफ्टर',
  'common.up.RECEIVED': 'जादूगार',
  'fission.relation.by.amount': 'राशि के अनुसार',
  'fission.relation.by.time': 'समय के अनुसार',
  'common.cap.Countdown': 'उलटी गिनती',
  'common.up.RULES': 'नियम',
  'common.up.DAYS': 'दिन',
  'common.up.HOURS': 'घंटे',
  'common.up.MINS': 'मिनट',
  'common.cap.Successfully.Collected': 'सफलतापूर्वक एकत्र किया गया',
  'common.cap.Countdown.to.Start': 'उलटी गिनती शुरू होगी',
  'common.cap.Day': 'दिन',
  'common.cap.Hour': 'घंटा',
  'common.cap.Min': 'मिनट',
  'common.cap.Sec': 'सेकण्ड',
  'common.cap.Rules': 'नियम',
  'common.cap.event.over': 'घटना समाप्त हो गई है',
  'recharge.event.tab.1': 'रिचार्ज प्रगति',
  'recharge.event.amount.reached': 'सिक्कों की राशि पहुंची',
  'recharge.event.ranking.list': 'रैंकिंग सूची',
  'recharge.event.group': 'समूह',
  'recharge.event.next.1': 'केवल जरूरत है',
  'recharge.event.next.2': 'अगले स्तर तक पहुंचेंगे सिक्के',
  'recharge.event.rewards.for': 'पुरस्कार के लिए',
  'recharge.event.trouble': 'क्या आपको रिचार्ज करने में परेशानी हो रही है?',
  'recharge.event.done': 'बधाइयाँ! आप उच्चतम स्तर पर पहुंच गए हैं! अब अपने पुरस्कार का आनंद लें!',
  'fission.trouble': 'क्या आपको रिचार्ज करने में परेशानी हो रही है?',
  'common.cap.No': 'नहीं',
  'common.cap.Unfortunately.Yes': 'दुर्भाग्य से हाँ',
  'common.cap.Waiting': 'प्रतीक्षारत',
  'common.cap.update': 'कृपया नई सुविधाओं का उपयोग करने के लिए संस्करण अद्यतन करें',
  'common.cap.Customer.Server': 'ग्राहक सेवा',
  'common.cap.Ranking.click': 'क्लिक करना',
  'common.cap.Ranking.click.text': 'विभिन्न पुरस्कारों की जांच करने के लिए',
  'common.cap.Collect': 'इकट्ठा करना',
  'common.cap.Collected': 'एकत्र',
  'common.cap.Limited.Gifts': 'सीमित उपहार',
  'family.football.target': 'सिक्के पुरस्कार लक्ष्य',
  'dream.city.rule.reward': 'नियम और\nपुरस्कार',
  'dream.city.city.map': 'शहर का नक्शा',
  'dream.city.rules': 'इवेंट के नियम',
  'dream.city.supply.reward': 'आपूर्ति स्टेशन पुरस्कार',
  'dream.city.wealth': 'धन',
  'dream.city.charm': 'मंत्रित कर',
  'dream.city.wealth.rank': 'धन',
  'dream.city.charm.rank': 'मंत्रित कर',
  'dream.city.current.coins': 'वर्तमान सिक्के',
  'dream.city.current.diamonds': 'वर्तमान हीरे',
  'dream.city.go': 'जा',
  'dream.city.will.get': 'आपको मिलेगा',
  'dream.city.received': 'बधाइयाँ! आपने प्राप्त किया है',
  'dream.city.top5': 'सोनेस्ट में आपूर्ति स्टेशन तक पहुंचने वाले शीर्ष 5 उपयोगकर्ताओं को आपूर्ति पुरस्कार मिलेगा।',
  'dream.city.waiting': 'प्रतीक्षारत',
  'about.title': 'करीबन',
  'Terms of Service': 'सेवा की शर्तें',
  'Privacy Policy': 'गोपनीयता नीति',
  'Return Policy': 'वापसी नीति',
  'Intellectual Property Rights': 'बौद्धिक संपदा अधिकार',
  'Contact Us': 'हमसे संपर्क करें',
  'About Us': 'हमारे बारे में',
  'Supported Payment Methods': 'समर्थित भुगतान विधियाँ',
  subTitle: 'सत्यापित व्यापारियों के अलावा अन्य रिचार्ज चैनलों पर भरोसा न करें।',
  chat: 'गपशप',
  id: 'आत्मा यू आईडी',
  contact: 'संपर्क',
  whatsapp: 'WhatsApp',
  right: 'सोल यू टीम अंतिम व्याख्या का अधिकार सुरक्षित रखती है।',
  check: 'पासवर्ड में 8 अंक और अपरकेस और लोअरकेस अक्षर होने चाहिए',
  same: 'कृपया वही पासवर्ड दर्ज करें',
  copied: 'प्रतिलिपि',
  details: 'हीरे का विवरण',
  miss: 'मिस्ड डायमंड्स',
  profit: 'आय के आंकड़े',
  missToday: 'मिस-आज',
  cumulativeMiss: 'मिस्ड-टोटल',
  distributionOfDiamondIncome: 'हीरे की आय वितरण',
  getDiamondsMonth: 'इस महीने के हीरे:',
  getDiamondsDay: 'हीरे आज हैं:',
  cancel: 'रद्द करना',
  confirm: 'पुष्टि',
  daily: 'दैनिक',
  month: 'महीना',
  tips: 'अधिक डेटा नहीं',
  voiceChat: 'एंकर के साथ वॉयस चैट के लिए यूजर रेटिंग्स',
  voiceChat1: 'मेरे साथ वॉयस चैट के लिए यूजर रेटिंग्स',
  quickCall: 'एंकर के साथ त्वरित कॉल के लिए यूजर रेटिंग्स',
  quickCall1: 'मेरे साथ त्वरित कॉल के लिए यूजर रेटिंग्स',
  chatting: 'संदेश चैट प्रदर्शन',
  chatting1: 'मेरा चैट प्रदर्शन',
  chatSpeed: 'उत्तर की गति',
  chatSpeed1: 'संदेशों का जवाब देने की मेरी गति',
  like: 'पसंद',
  boring: 'उबाऊ',
  Hate: 'घृणा करना',
  chatConversation: '💬 चैट राउंड',
  reached: '🙋 उपयोगकर्ताओं की संख्या',
  count: '🙋 संदेश',
  speed: 'उत्तर की गति',
  quality: 'चैट की गुणवत्ता',
  call: 'कॉल & रेटिंग',
  currentCycle: 'वर्तमान चक्र',
  speedTitle: 'संदेश आय नियमों का उत्तर दें',
  roundTitle: 'चैट राउंड आय नियम',
  modalTips: 'संदेशों का जवाब देने से तेजी से उपयोगकर्ता अनुभव में सुधार होगा, और आप जितने अधिक हीरे कमाते हैं।',
  modalTipsTwo: 'आपकी उत्तर सामग्री की उच्च गुणवत्ता और अधिक चैट राउंड उपयोगकर्ता अनुभव में सुधार करेंगे, और आप जितने अधिक हीरे कमाते हैं:',
  modalSpeed: 'प्रतिक्रिया की गति',
  modalIncome: 'आय दर',
  modalRound: 'चैट राउंड',
  modalReward: 'अतिरिक्त पुरस्कार',
  modalBtn: 'चैट करना जारी रखें',
  cycleDiamonds: 'हीरे इस चक्र',
  'terms.help': 'अनुप्रयोग समस्याएँ',
  'terms.bug': 'खटमल',
  'terms.suggestions': 'सुझाव',
  'contacts.whatsapp': 'WhatsApp',
  'contacts.email': 'ईमेल',
  'contacts.phone': 'फ़ोन',
  'submit.success': 'आपकी प्रतिक्रिया के लिए धन्यवाद',
  'terms.title': 'वर्ग',
  'details.placeholder': 'कृपया अपनी समस्या के विवरण का वर्णन करें',
  'contacts.title': 'आपसे कैसे संपर्क करें',
  'contact.placeholder': 'अपनी संपर्क जानकारी यहाँ छोड़ दें[गोपनीय]',
  'submit.btn': 'जमा करें',
  'country.select.hot': 'गरम',
  'country.select.all': 'सब',
  'feedback.details': 'ब्यौरा',
  'feedback.screenshot': 'स्क्रीनशॉट (वैकल्पिक)',
  'feedback.title': 'प्रतिपुष्टि',
  flowWater: 'सिक्कों का विवरण',
  coinBack: 'सिक्के लौटाए गए',
  'home.recharge': 'पुनर्भरण',
  'home.slogan': 'अपने सोलमेट से मिलें यहां<br / > हमेशा आपके साथ',
  'home.download': 'डाउनलोड',
  'income.rules.rules.1': '1. वेतन नियम: प्रत्येक महीने की 16 तारीख और अगले महीने की पहली तारीख 00:00 खाते के डायमंड बैलेंस से काट लिया जाएगा।',
  'income.rules.rules.2': '2. वेतन अनुपात: 1 USD = 0 हीरे',
  'income.rules.rules.3': '3. निपटान समय: (1) प्रत्येक महीने की 16 तारीख 00:00 (2) अगले महीने की पहली तारीख 00:00',
  'income.rules.rules.4': '4. निपटान अवधि: 7 ~ 14 कार्य दिवस (छुट्टियां स्थगित हैं)',
  'income.rules.chat': 'ग्राहक सेवा से संपर्क करें',
  'income.rules.important': 'बल:',
  'income.rules.title': 'वेतन के नियम',
  'income.rules.rules.extra':
    'वेतन का निपटान होने पर हम आपको "सोल यू टीम" के माध्यम से सूचित करेंगे। डायमंड कटौती का विवरण "आय" में जांचा जा सकता है, कृपया सोल यू टीम अधिसूचनाओं पर ध्यान दें। सामान्य निपटान 7-14 कार्य दिवसों के भीतर पूरा हो जाएगा, यदि छुट्टी है, तो बैंक रिलीज की अवधि में देरी होगी, कृपया धैर्य रखें और भुगतान की प्रतीक्षा करें। यदि आपके कोई प्रश्न हैं, तो कृपया SOYO ग्राहक सेवा से संपर्क करें, आपके समर्थन के लिए धन्यवाद!',
  'income.rules.usd': 'USD',
  'income.rules.diamonds': 'हीरे',
  'my.earning.title': 'मेरी कमाई',
  'my.earning.tips': 'हीरे का संतुलन',
  'my.earning.daimonds': 'हीरे',
  'my.earning.withdrawal.rules': 'निकासी के नियम',
  'my.earning.salary.rules': 'वेतन के नियम',
  'new.task.go': 'जा',
  'new.task.done': 'समाज-सम्मत',
  'task.center.title': 'कार्य केंद्र',
  'recharge.result.success': 'भुगतान की सफलता',
  'recharge.result.error': 'भुगतान विफल रहा',
  'report.option.Porn': 'अश्लील साहित्य',
  'report.option.Spam': 'स्पैम',
  'report.option.Fraud': 'धोखा',
  'report.option.Violence': 'हिंसा',
  'report.option.Illegal': 'अवैध',
  'report.option.Other': 'अन्य',
  'report.option.child': 'बच्चे से संबंधित',
  'report.option.room': 'मुझे यह पसंद नहीं है',
  'report.option.user': 'मुझे उपयोगकर्ता पसंद नहीं है',
  'report.reason': 'वर्ग',
  'report.details': 'ब्यौरा',
  'report.details.placeholder': 'अपनी रिपोर्ट के विवरण का वर्णन करें',
  'report.screenshot': 'स्क्रीनशॉट (वैकल्पिक)',
  'report.submit': 'जमा करें',
  'report.submit.success': 'सफलतापूर्वक रिपोर्ट किया गया',
  'report.room': 'रिपोर्ट',
  'report.user': 'रिपोर्ट उपयोगकर्ता',
  'retirar.rules.title': 'निकासी के नियम',
  'retirar.rules.rules.1': '1. निकासी सेवा का उपयोग करने से पहले, आपको अपने खाते की सुरक्षा सुनिश्चित करने के लिए वास्तविक पहचान जानकारी जमा करने की आवश्यकता है',
  'retirar.rules.rules.2': '2. कृपया वास्तविक पहचान की जानकारी प्रदान करें, जिसका उपयोग आपके सोल यू खाते का उपयोग करने के अधिकार की पहचान और निर्धारण के लिए आधार के रूप में किया जाएगा',
  'retirar.rules.rules.3': '3. आपके द्वारा प्रस्तुत पहचान की जानकारी को सख्ती से गोपनीय रखा जाएगा और अन्य उद्देश्यों के लिए उपयोग नहीं किया जाएगा',
  'retirar.rules.chat': 'ग्राहक सेवा से संपर्क करें',
  'retirar.rules.important': 'बल:',
  'retirar.rules.rules.extra': 'आहरण सेवा का उपयोग करने की आवश्यकता के साथ कृपया ग्राहक सेवा से संपर्क करें और निम्नलिखित जानकारी प्रदान करें:',
  'retirar.rules.rules.extra.1': '1) असली नाम',
  'retirar.rules.rules.extra.2': '2) व्हाट्सएप नंबर',
  'retirar.rules.rules.extra.3': '3) पहचान दस्तावेज (पहचान पत्र, पासपोर्ट, आदि)',
  'retirar.rules.rules.extra.4': '4) आपकी भुगतान विधि (बैंकट्रांसफर, पेओनर, फॉरी, आदि)',
  'task.center.retirar': 'अब दावा करें',
  'task.center.complete': 'समाज-सम्मत',
  'lucky.darw.end': '\r\nइवेंट प्रगति पर नहीं है',
  'total.rank.daily': 'दैनिक',
  'task.center.common.level': 'लेवल',
  'common.cap.Event.To.Start': 'इवेंट शुरू होने वाला है',
  'task.center.limit.task': 'Limited Task',
  'task.center.normal.task': 'Normal Task',
  'task.center.limit.today': 'Today',
  'task.center.limit.Total': 'Total',
  'task.center.limit.Diamonds.Received': 'Diamonds Received',
  'task.center.limit.Reward.Received': 'Reward Received',
  'task.center.limit.receive': 'Receive',
  'task.center.limit.extra': 'Extra',
  'task.center.limit.to.start': 'Event has not started yet',
  'gift.wall.title': 'उपहार',
  'gift.wall.help.text1': 'उपहार जो एक बार में {num} सिक्कों से अधिक हो जाते हैं, उपहार दीवार पर दिखाई देंगे',
  'gift.wall.help.text2': 'सबसे ऊपर प्रदर्शित होगा वह उपहार जिसमें अधिक सिक्के होंगे।',
  'gift.wall.help.text3': 'गिफ्ट वॉल पर रिकॉर्ड 24 घंटे तक रखा जाएगा',
  'level.user': 'उपयोगकर्ता स्तर',
  'level.halo': 'Halo स्तर',
  'level.halo.question1.title': 'उच्च स्तर के लाभ',
  'level.halo.question1.answer1': 'उच्च स्तर, उज्ज्वल Halo',
  'level.halo.question2.title': 'स्तर चिह्न प्रदर्शन',
  'level.halo.question2.answer1': 'उच्च स्तर, अधिक अनोखा आइकन',
  'level.halo.question3.title': 'स्तर ऊपर इनाम',
  'level.halo.question3.answer1': 'अद्भुत पुरस्कार पाने के लिए लेवल अप करें',
  'level.halo.question4.title': 'लेवल अप करने के तरीके',
  'level.halo.question4.answer1': '1 सिक्के का उपहार प्राप्त करें, 1 अनुभव प्राप्त करें।\nउच्च अनुभव, तेजी से अपग्रेड।',
  'level.user.question1.title': 'उच्च स्तर के लाभ',
  'level.user.question1.answer1': 'आपको विशेष वस्तुएं और इनाम मिलेगा।',
  'level.user.question2.title': 'लेवल अप करने के तरीके',
  'level.user.question2.answer1': 'कमरे में चैट करें, गेम खेलें और उपहार भेजें',
  'level.user.question2.answer2': 'दैनिक कार्यों को पूरा करें, स्तर ऊपर करने के लिए अनुभव प्राप्त करें',
  'level.user.question2.answer7': 'कमरे में पार्टी करें',
  'level.user.question2.answer8': 'सिक्कों द्वारा उपहार भेजें',
  'level.user.question2.answer9': 'कार्यों को पूरा करें और अनुभव प्राप्त करें',
  'level.user.question3.title': 'स्तर चिह्न प्रदर्शन',
  'level.user.question3.answer1': 'उच्च स्तर, अधिक अनोखा आइकन',
  'level.user.question4.title': 'स्तर ऊपर करने का इनाम',
  'level.user.question4.answer1': 'अद्भुत पुरस्कार पाने के लिए स्तर ऊपर करें',
  'level.user.level': 'स्तर',
  'level.user.icon': 'आइकन',
  'level.user.tips': 'जैसे-जैसे आप अनुभव अंक प्राप्त करना जारी रखेंगे, आपका स्तर धीरे-धीरे बढ़ता जाएगा',
  'level.user.way.up': 'अनुभव अंक बढ़ाने के तरीके:',
  'about.badges.title': 'बैज के बारे में',
  'about.badges.0': 'बैज पहनें',
  'about.badges.1': 'सभी बैज पहने जा सकते हैं, और एक ही समय में अधिकतम पांच बैज पहने जा सकते हैं।',
  'about.badges.2': 'सम्मान बैज',
  'about.badges.3': 'संचयी मासिक मूल्यों द्वारा अनलॉक किए गए बैज की गणना प्रत्येक महीने की पहली तारीख को की जाती है, चाहे उनका स्वामित्व हो या न हो। वैधता अवधि एक महीने तक चलती है।',
  'vip.privileges.title.privacy': 'निजता एवं सुरक्षा',
  'vip.privileges.label.privacy': 'अन्य लोग आपको/आपके वॉयस रूम को खोज नहीं सकते',
  'vip.privileges.title.invisible': 'इनविजिबल मोड़',
  'vip.privileges.label.invisible': 'इनविजिबल रहें और दूसरों की सिफारिशों में खुद को छिपाएं',
  'vip.privileges.title.blockMessages': 'शांत मोड',
  'vip.privileges.label.blockMessages': 'अजनबियों के संदेशों को ब्लॉक करें और अविभाजित रहें।',
  'vip.privileges.title': 'SOYO VIP विशेषाधिकार',
  'vip.privileges.activate': 'VIP{N} सक्रिय करें',
  'vip.privileges.stealth.text': 'मेरा एक्सेस इतिहास छुपाएं',
  'vip.privileges.stealth.open': 'खुला',
  'vip.privileges.stealth.close': 'बंद करें',
  'vip.privileges.title.icon': 'वीआईपी लेबल',
  'vip.privileges.label.icon': 'उल्लेखनीय VIP योग्य, VIP उपयोगकर्ताओं की विशिष्ट पहचान, आपके सम्मान को दर्शाती है।',
  'vip.privileges.title.visitor': 'यात्रा के निशान',
  'vip.privileges.label.visitor': 'सभी आगंतुकों के निशान देखें ताकि आप किसी भी इच्छुक व्यक्ति और प्रशंसकों को याद न करें।',
  'vip.privileges.title.list': 'प्राथमिकता प्रदर्शन',
  'vip.privileges.label.list': 'खोज परिणामों का प्राथमिकता प्रदर्शन।',
  'vip.privileges.title.follower': 'उच्चतर ऊपरी सीमा के बाद।',
  'vip.privileges.label.follower':
    'दोस्तों का दायरा बढ़ाने के लिए आप और दोस्तों को फॉलो कर सकते हैं। गैर-वीआईपी 500 लोगों को फॉलो कर सकते हैं VIP1-VIP3 1000 लोगों को फॉलो कर सकते हैं VIP4-VIP6 2000 लोगों को फॉलो कर सकते हैं VIP7 और ऊपर 3000 लोगों को फॉलो कर सकते हैं',
  'vip.privileges.title.sign': 'वीआईपी चेक-इन पुरस्कार',
  'vip.privileges.label.sign': 'VIP डबल चेक-इन पुरस्कार का दावा कर सकता है',
  'vip.privileges.title.stealth': 'अदृश्य दौरा',
  'vip.privileges.label.stealth': 'जब आप अन्य उपयोगकर्ताओं के पास जाते हैं, तो कोई इतिहास रिकॉर्ड नहीं किया जाएगा',
  'vip.privileges.title.name': 'उपयोगकर्ता नाम हाइलाइट',
  'vip.privileges.label.name': 'यूजरनेम को सजाने के लिए यूजर्स को स्पेशल इफेक्ट मिलेंगे',
  'vip.privileges.title.avatar': 'वीआईपी फ्रेम',
  'vip.privileges.label.avatar': 'आपकी पहचान दिखाने के लिए विशेष फ्रेम।',
  'vip.privileges.title.bubble': 'वीआईपी चैट बबल',
  'vip.privileges.label.bubble': 'एक्सक्लूसिव चैट बबल आपके भाषण को सबसे अलग बनाता है।',
  'vip.privileges.title.room': 'वीआईपी मिनी कार्ड',
  'vip.privileges.label.room': 'विशेष वीआईपी रूम कार्ड',
  'vip.privileges.title.picture': 'आपके सम्मान को उजागर करने के लिए विशेष मिनी कार्ड।',
  'vip.privileges.label.picture': 'बुलेट चैट पर चित्र भेजने का विकल्प',
  'vip.privileges.title.message': 'शीर्ष संदेश सूची',
  'vip.privileges.label.message': 'आप अन्य उपयोगकर्ताओं के संवादों को संदेश सूची के शीर्ष पर रख सकते हैं।',
  'vip.privileges.title.vehicle': 'वीआईपी वाहन',
  'vip.privileges.label.vehicle': 'विशेष वीआईपी वाहन',
  'vip.privileges.title.rank': 'पहचान छुपाएं',
  'vip.privileges.label.rank': 'आप रैंकिंग में एक रहस्य पुरुष के रूप में प्रकट होने के लिए [पहचान छुपाएं] बटन चालू कर सकते हैं।',
  'vip.privileges.title.broadcast': 'अनुप्रयोग अधसुचना',
  'vip.privileges.label.broadcast': 'जब आप VIP 8 और उससे ऊपर पहुंच जाते हैं, तो अपग्रेड ऐप नोटिफिकेशन को ट्रिगर करेगा।',
  'vip.privileges.title.id': 'एक्सक्लूसिव आईडी',
  'vip.privileges.label.id':
    'अपनी अनन्य आईडी प्राप्त करें और दूसरों को आपको एक नज़र में याद रखने दें! VIP8: ABABAB(A और B अलग होना चाहिए) VIP9: AAABB(A और B अलग होना चाहिए) VIP10: ABAB(A और B अलग होना चाहिए) VIP11: AABB(A और B अलग होना चाहिए) सभी फॉर्म नहीं हो सकते 0 से शुरू करें। विशिष्ट आईडी प्राप्त करने के लिए आपको VIP ग्राहक सेवा से संपर्क करना होगा।',
  'vip.privileges.title.protection': 'निष्कासन रोकें',
  'vip.privileges.label.protection': 'माइक या किसी भी कमरे से निकाले जाने की चिंता कभी न करें।',
  'vip.privileges.title.gif': 'अनुकूलित गतिशील फ्रेम',
  'vip.privileges.label.gif':
    'अपने अनन्य गतिशील फ्रेम को अनुकूलित करें। जब आप VIP 9 तक पहुँचते हैं, तो अनुकूलित डायनामिक फ़्रेम प्राप्त करने के लिए कृपया ग्राहक सेवा से संपर्क करें, प्रत्येक उपयोगकर्ता के पास केवल अनुकूलित करने का एक मौका होता है।',
  'vip.privileges.title.gift': 'अनुकूलित उपहार',
  'vip.privileges.label.gift':
    'अपने विशेष उपहार को अनुकूलित करें। जब आप VIP 10 तक पहुँचते हैं, तो अनुकूलित उपहार प्राप्त करने के लिए कृपया ग्राहक सेवा से संपर्क करें, प्रत्येक उपयोगकर्ता के पास अनुकूलित करने का केवल एक मौका होता है।',
  'vip.privileges.title.exclusivevehicles': 'अनुकूलित वाहन',
  'vip.privileges.label.exclusivevehicles':
    'अपने अनन्य माउंट को अनुकूलित करें। जब आप VIP 11 तक पहुँचते हैं, तो अनुकूलित वाहन प्राप्त करने के लिए कृपया ग्राहक सेवा से संपर्क करें, प्रत्येक उपयोगकर्ता के पास अनुकूलित करने का केवल एक मौका होता है।',
  'vip.privileges.title.open': 'अनुकूलित खुला पृष्ठ',
  'vip.privileges.label.open':
    'ऐप खोलने के बाद अन्य लोग आपको स्वचालित रूप से देख सकते हैं, अपराजेय महिमा! जब आप VIP12 तक पहुँचते हैं, तो कृपया 24 घंटे का अनुकूलित खुला पृष्ठ प्राप्त करने के लिए ग्राहक सेवा से संपर्क करें, प्रत्येक उपयोगकर्ता के पास अनुकूलित करने का केवल एक मौका होता है।',
  'vip.privileges.update.info': 'VIP सिस्टम अपडेट कर दिया गया है, नवीनतम सुविधाओं को देखने के लिए आपका संस्करण बहुत कम है। कृपया नवीनतम संस्करण में अपडेट करें और नए VIP फ़ंक्शन का आनंद लें!',
  'aristocracy.vip.title': 'शिष्टजन',
  'vip.help.1': 'वीआईपी नियम',
  'vip.help.2': 'वीआईपी क्या है?',
  'vip.help.3': 'VIP TalkTalk में विशेष वर्ग है। इसमें 16 पहचान स्तर शामिल हैं। वीआईपी के मालिक होने के बाद आपके पास संबंधित विशेषाधिकार हो सकते हैं।',
  'vip.help.4': 'VIP लेवल को अपग्रेड कैसे करें?',
  'vip.help.5':
    '1.Recharge $1 1 VIP EXP प्राप्त कर सकता है।\n2. VIP EXP उस महीने में अपग्रेड आवश्यकता तक पहुंचने पर आपका VIP स्तर तुरंत अपग्रेड हो जाएगा।\n3. प्रत्येक महीने के पहले दिन से, प्रत्येक व्यक्ति का प्रारंभिक VIP EXP 0 पर रीसेट हो जाएगा। और आपका VIP स्तर संचित EXP राशि द्वारा तय किया जाएगा।',
  'vip.help.6': 'वीआईपी स्तर के नियम अपग्रेड करें',
  'vip.help.7': 'अपग्रेड नियम',
  'vip.help.8':
    'एक बार जब आप इस महीने अपग्रेड की स्थिति में पहुंच जाते हैं, तो आपका वीआईपी स्तर तुरंत अपडेट हो जाएगा। और अगले महीने के अंत तक वीआईपी स्तर बनाए रखा जाएगा। उदाहरण के लिए, उपयोगकर्ता 10 मार्च को VIP5 पर पहुंच गया और उसका VIP5 30 अप्रैल तक जारी रहेगा।',
  'vip.help.9': 'रिटेन/डाउनग्रेड नियम',
  'vip.help.10':
    'यदि आपका संचित ऍक्स्प मूल्य इस महीने में आपके वर्तमान वीआईपी स्तर की आवश्यकताओं तक पहुँचता है, तो आप अगले महीने में अपना वीआईपी स्तर बनाए रखेंगे; अन्यथा, आपका VIP स्तर अगले महीने डाउनग्रेड हो जाएगा। उदाहरण के लिए: यदि आप मई में VIP5 हैं, लेकिन आपने केवल मई में VIP3 जमा किया है, तो आपका VIP स्तर जून से VIP3 में डाउनग्रेड हो जाएगा।',
  'vip.help.11': 'वीआईपी स्तर की छूट',
  'vip.help.12': 'प्रत्येक महीने की पहली तारीख को VIP स्तर के आधार पर, हम प्रत्येक माह के पहले दिन उपयोगकर्ताओं को उनके VIP स्तरों के अनुसार VIP EXP की एक निश्चित राशि भेजेंगे।',
  'vip.help.13': 'स्तर',
  'vip.help.14': 'EXP मूल्य अंतराल',
  'vip.help.15': 'EXP मूल्य छूट',
  'vip.help.update.info': 'VIP सिस्टम अपडेट कर दिया गया है, नवीनतम सुविधाओं को देखने के लिए आपका संस्करण बहुत कम है। कृपया नवीनतम संस्करण में अपडेट करें और नए VIP फ़ंक्शन का आनंद लें!',
  'to.exchange.page.button': 'विनिमय',
  'ranking.daily': 'रोजाना',
  'ranking.weekly': 'साप्ताहिक',
  'ranking.contributed': 'योगदान:',
  'ranking.blank.daily': 'आज किसी ने उपहार नहीं भेजा है',
  'ranking.blank.weekly': 'इस सप्ताह किसी ने उपहार नहीं भेजा है',
  'lucky.packet.title': 'लकी पैकेट',
  'lucky.packet.1': 'लकी पैकेट भेजें',
  'lucky.packet.2': 'रूम में मौजूद सभी यूजर्स लकी पैकेट भेज और क्लेम कर सकते हैं',
  'lucky.packet.3': 'जब एक कमरे में कई लकी पैकेट होंगे, तो उन्हें ढेर करके प्रदर्शित किया जाएगा। जितनी जल्दी लकी पैकेट भेजे जाते हैं, उतना ही अधिक यह सूची के शीर्ष पर दिखाया जाता है।',
  'lucky.packet.4': 'प्रतिबंध और शर्तें',
  'lucky.packet.5': '1. केवल सिक्के भेजे जा सकते हैं',
  'lucky.packet.6':
    '2. जारी किए जा सकने वाले सिक्कों की मात्रा की सीमा: 1000 ~ 9999999\nसिक्कों की डिफ़ॉल्ट राशि 3000 सिक्के हैं।\n- जारी किए जा सकने वाले लोगों की संख्या: 5-500\nडिफ़ॉल्ट संख्या 10 लोग हैं',
  'lucky.packet.7': '3. प्रति व्यक्ति राशि (सिक्कों की संख्या/लोगों की संख्या) 100 सिक्कों से अधिक या उसके बराबर होनी चाहिए।',
  'lucky.packet.8': '4. जब पैकेट की मात्रा 10000 सिक्कों से अधिक हो और लोगों की संख्या 10 से अधिक हो, तो यह विश्व सूचना को ट्रिगर करेगा, और कमरे की सूची में एक विशेष आइकन प्रदर्शित होगा।',
  'lucky.packet.9': 'इसे कैसे खेलें',
  'lucky.packet.10': '1. लकी पैकेट पासवर्ड के साथ\n-इसे पाने  के लिए आपको कमरे में पासवर्ड भेजने की जरूरत है।',
  'lucky.packet.11': '2. लकी पैकेट के लिए फॉलोअर्स की आवश्यकता होती है\n-आपको इसे पाने के लिए लकी पैकेट भेजने वाले को फॉलो करने की आवश्यकता है।',
  'lucky.packet.12': 'सामान्य समस्याएं',
  'lucky.packet.13': '1. जब पैकेट विश्व नोटिफिकेशन को ट्रिगर करता है या जब लकी पैकेट आइकन प्रदर्शित होता है, तो अन्य लोग उस पर क्लिक करके आपके कमरे में प्रवेश कर सकते हैं।',
  'lucky.packet.14': '2. यदि आप अपने कमरे को बंद कर देते हैं, तो विश्व नोटिफिकेशन और आइकन दिखाई नहीं देंगे।',
  efficient_text_2: 'प्रभावी समय',
  efficient_text_5: 'प्रभावी दिन',
  efficient_text_6: '1. कमरे में वैध समय',
  efficient_text_7:
    'वॉयस रूम में, माइक पर समय - माइक का समय> = %$ मिनट का समय माइक पर एक प्रभावी समय है। हम माइक पर व्यक्ति को चैट करने और लंबे समय तक अच्छे दोस्तों के साथ बातचीत करने की पहल करने के लिए प्रोत्साहित करते हैं, जैसे कि:',
  efficient_text_8: 'माइक पर समय',
  efficient_text_9: 'माइक बंद समय',
  efficient_text_10: 'चाहे मानक तक पहुंच गया',
  efficient_text_11: 'व्याख्या करना',
  efficient_text_12: 'मानक तक पहुंचें',
  efficient_text_13: 'मानक तक नहीं पहुंचा',
  efficient_text_14: 'यदि माइक पर समय अगले दिन तक रहता है, तो इसे पिछले दिन के समय के रूप में गिना जाता है',
  efficient_text_15: 'माइक पर माइक -टाइम ऑफ टाइम <%$min',
  efficient_text_16: 'माइक पर माइक-टाइम बंद करें> = %$min',
  efficient_text_17: '12/1 9:00:00 माइक पर समय मानक तक नहीं पहुंचा, इसे एक प्रभावी समय के रूप में नहीं गिना जा सकता है',
  efficient_text_18: '12/1 23:00:00 एमआईसी पर समय 12/2 तक जारी रहा और एक पंक्ति में 90 मिनट तक चला, इसलिए इसे 12/1 के दौरान समय के रूप में गिना जाता है',
  efficient_text_19: '2. कमरे के प्रभावी दिन',
  efficient_text_20:
    'वॉयस रूम में, दिन के दौरान सभी प्रभावी अवधि को जोड़ा जाता है> = %$ मिनट एक प्रभावी दिन है। अधिक दिनों की वैध संख्या, उच्च गतिविधि, और गतिविधि दर सीधे आपके द्वारा प्राप्त पुरस्कारों को प्रभावित करती है।',
  efficient_text_21: 'कुल प्रभावी समय',
  efficient_text_22: 'दिन के दौरान माइक पर 12/1 3 बार: 15min +35min +90min। क्योंकि 15 मिनट मानक को पूरा नहीं करता है, मान्य समय = 90 +35 = 125min = 02h 05min',
  efficient_text_23: '12/1 कुल = 02h 05min> = %$min में दिन की वैध अवधि, जिसे मान्य दिन के रूप में गिना जाता है',
  efficient_text_24: '3. माइक पर समय',
  efficient_text_25:
    'माइक पर समय प्रभावी समय होना चाहिए, और प्रभावी समय> = %$min होना चाहिए। माइक पर समय केवल कमरे में माइक पर चैटिंग के समय को इंगित करता है। इसे एक प्रभावी समय के रूप में माना जाएगा जब माइक पर समय> = %$min, जैसे: जैसे:',
  efficient_text_26: '4. डेटा विवरण',
  efficient_text_27: 'डेटा को चयनित चक्र के अनुसार प्रदर्शित किया जाएगा',
  efficient_text_28: 'हीरे',
  efficient_text_29: 'बात करना',
  efficient_text_30: 'आवाज',
  efficient_text_31: 'विवरण',
  efficient_text_32: 'तारीख',
  efficient_text_33: 'माइक पर समय',
  efficient_text_34: 'वॉयस रूम का प्रभावी समय',
  efficient_text_35: 'वॉयस रूम के प्रभावी दिन',
  share_text23: 'डाउनलोड करना',
  share_text24: 'रूम में शामिल हों',
  share_text25: 'मैं इस रूम में हूँ, आओ और हमसे जुड़ो',
  share_text26: 'रूम आईडी',
  OfficialTask_Card_common_text1: 'ऑफिशियल टास्क ',
  OfficialTask_Card_common_text2: 'ऑफिशियल टास्क पूरा करने के बाद, आप बहोत {%s} हीरे प्राप्त कर सकते हैं!',
  OfficialTask_dailyCard_text1: 'दैनिक हीरा आय पुरस्कार ({%s1}/{%s2})',
  OfficialTask_dailyCard_text2: 'दैनिक हीरे की आय ({%s1}) {%s2} 💎 तक पहुंचती है, {%s3} प्राप्त करें 💎',
  OfficialTask_dailyCard_text3: 'दैनिक हीरा आय पुरस्कार ({%s3}) {%s1} 💎 तक पहुंचता है, {%s2} प्राप्त करें 💎',
  OfficialTask_dailyCard_text4: 'रिवार्ड टाइम',
  OfficialTask_dailyCard_text5: 'जाओ ',
  OfficialTask_PeriodCard_text1: 'कार्य अवधि डायमंड इनाम ({%s1}/{%s2})',
  OfficialTask_PeriodCard_text2: 'कार्य अवधि डायमंड इनाम({%s3}) {%s1} 💎 तक पहुंचता है, {%s2} प्राप्त करें 💎',
  OfficialTask_ActiveCard_text1: 'सक्रिय इनाम',
  OfficialTask_ActiveCard_text2: 'चक्र में संचयी लॉगिन {%s1} tian ({%s2}/{%s3})',
  OfficialTask_ActiveCard_text3: 'यदि दैनिक हीरे की आय {%s1} 💎 तक पहुंच जाती है, और आप {%s2} 💎 प्राप्त कर सकते हैं।  (आज: {%s3}/{%s4})',
  OfficialTaskPic_Level_text1: 'दैनिक डायमंड इनकम रिवार्डली डायमंड इनकम रिवार्ड्स (IM संदेश)',
  OfficialTaskPic_Level_text2: 'स्तर',
  OfficialTaskPic_Level_text3: 'डायमंड टारगेट',
  OfficialTaskPic_Level_text4: 'डायमंड इनाम',
  OfficialTaskPic_Level_text5: 'दैनिक डायमंड इनकम रिवार्ड （वॉयस रूम गिफ्ट: नॉर्मल वॉयस रूम + फैमिली वॉयस रूम)',
  OfficialTaskPic_Level_text6: 'कार्य अवधि डायमंड आय पुरस्कार (आईएम संदेश + प्राप्त सभी उपहार)',
  OfficialTaskPic_Level_text7: 'टास्क अवधि सक्रिय पुरस्कार',
  OfficialTaskPic_Level_text8: '{%S1} दिनों के लिए लॉग इन करें और हर दिन {%S2} हीरे प्राप्त करें',
  OfficialTaskPic_Level_text9: 'मांग',
  OfficialTaskPic_Level_text10: 'डायमंड इनाम',
  OfficialTaskPic_Level_text11: 'सूचना:',
  OfficialTaskPic_Level_text12: '1. वॉयस रूम गिफ्ट: सामान्य वॉयस रूम और फैमिली वॉयस रूम सहित;',
  OfficialTaskPic_Level_text13: '2. टास्क अवधि गतिविधि इनाम: अवधि समाप्त होने के बाद, सक्रिय दिनों की संख्या गिना जाएगा, और आपको जो अधिकतम इनाम मिल सकता है वह 350 हीरे हैं;',
  OfficialTaskPic_Level_text14: '3. इस टास्क अवधि के दौरान सभी डायमंड रिवार्ड सीधे आपके खाते में भेजे जाएंगे;',
  OfficialTaskPic_Level_text15: '4. हर 70 हीरे = 1 USD',
  OfficialTaskPic_Level_text16: 'दैनिक इनाम (चैट, ऑडियो- और वीडियो कॉल, ऑडियो मैच में उपहार के लिए)',
  OfficialTaskPic_Level_text17:
    'यदि आप चैट, ऑडियो-एंड वीडियो कॉल, ऑडियो मैच में उपहारों के लिए हीरे प्राप्त करते हैं और दैनिक लक्ष्य को पूरा करते हैं, तो आपको इसी हीरे का इनाम प्राप्त होगा। डायमंड रिवार्ड्स को अगले दिन स्वचालित रूप से आपके अकाउंट में जोड़ा जाएगा।',
  OfficialTaskPic_Level_text18: 'दैनिक इनाम (चैट और वॉयस रूम में उपहार के लिए)',
  OfficialTaskPic_Level_text19: 'टास्क अवधि इनाम (ऑडियो और वीडियो कॉल के लिए)',
  OfficialTaskPic_Level_text20:
    '1. यदि आप चैट और वॉयस रूम में उपहारों के लिए हीरे प्राप्त करते हैं और दैनिक लक्ष्य को पूरा करते हैं, तो आपको इसी हीरे का इनाम प्राप्त होगा। डायमंड रिवार्ड्स को अगले दिन स्वचालित रूप से आपके अकाउंट में जोड़ा जाएगा।',
  OfficialTaskPic_Level_text21:
    '2. यदि आप ऑडियो और वीडियो कॉल के लिए हीरे प्राप्त करते हैं और काम की अवधि के अंत तक लक्ष्य को पूरा करते हैं, तो आपको इसी हीरे का इनाम प्राप्त होगा। काम की अवधि समाप्त होने के बाद अगले दिन डायमंड रिवार्ड्स को आपके अकाउंट में जोड़ा जाएगा।',
  OfficialTaskPic_Level_text22: '3. 2 काम की अवधि होती है जो प्रत्येक महीने के 1 से 15 वीं तक होती है, और 16 वीं से प्रत्येक माह 30/31 वीं तक।',
  OfficialTaskPic_Level_text23: 'दैनिक रिवार्ड 1 (ऑडियो पार्टी रूम और फैमिली गिफ्ट डायमंड)',
  OfficialTaskPic_Level_text24: 'दैनिक रिवार्ड 2 (वॉयस कॉल, वॉयस मैच डायमंड)',
  OfficialTaskPic_Level_text25: 'वर्क पीरियड रिवार्ड (डेटिंग चैट, ऑडियो पार्टी रूम, वॉयस कॉल, ऑडियो मैच)',
  OfficialTaskPic_Level_text26:
    '1. यदि आप चैट, ऑडियो रूम और वीडियो कॉल, ऑडियो मैच में उपहारों के लिए हीरे प्राप्त करते हैं और साप्ताहिक लक्ष्य को पूरा करते हैं, तो आपको इसी हीरे का इनाम प्राप्त होगा। डायमंड रिवार्ड्स को इस वेतन शब्द में स्वचालित रूप से आपके अकाउंट में जोड़ा जाएगा।',
  OfficialTaskPic_Level_text27: '2. वेतन दर: 100 हीरा = 1 USD = 80 INR;',
  OfficialTaskPic_Level_text28: '3. दैनिक पुरस्कारों से प्राप्त हीरे इस हीरे के लक्ष्य में नहीं गिने जायेंगे;',
  OfficialTaskPic_Level_text29: 'SOYO आधिकारिक इनाम प्रणाली 02.2024',
  OfficialTaskPic_Level_text30: 'दैनिक टास्क 1: सभी कॉल + सभी उपहार',
  OfficialTaskPic_Level_text31: 'दैनिक मिशन 2: संदेश उत्तर',
  OfficialTaskPic_Level_text32: 'दैनिक हीरा लक्ष्य',
  OfficialTaskPic_Level_text33: 'हमारे दैनिक हीरे का लक्ष्य ऊपर दिखाया गया है, और हमारे इनाम प्रणाली में एक दिन तुर्की स्थानीय समय पर आधारित है।',
  cms_report_text3: 'रिपोर्ट कारण:',
  cms_report_text4: 'रिपोर्ट विवरण:',
  cms_report_text5: 'रिपोर्ट स्क्रीनशॉट:',
  cms_report_text6: '*कृपया उपरोक्त जानकारी सत्यापित करें, ताकि हम जानकारी के लिए जुर्माना लगा सकें।',
  cms_report_text7: 'रिपोर्टर शोड:',
  cms_report_text8: 'रिपोर्टर शोड:',
  cms_report_text9: 'पेनल्टी विधि:',
  cms_report_text10: 'जुर्माना परिणाम:',
  cms_report_text11: 'सजा इतिहास',
  cms_report_text12: 'एक चेतावनी का कटौती',
  cms_report_text13: 'बैन मैथड:',
  cms_report_text14: 'बैन के कारण:',
  cms_report_text15: 'बैन का समय:',
  cms_report_text16: 'राजनीतिक संवेदनशीलता',
  cms_report_text17: 'अशिष्ट',
  cms_report_text18: 'हमला और दुर्व्यवहार',
  cms_report_text19: 'खूनी हिंसा',
  cms_report_text20: 'विज्ञापन निकासी',
  cms_report_text21: 'संदिग्ध धोखाधड़ी',
  cms_report_text22: 'अवैध सूचना',
  cms_report_text23: 'नाबालिग',
  cms_report_text24: 'अन्य',
  cms_report_text25: 'पुष्टीकरण',
  cms_report_text26: 'खाता संवेदनशील संचालन, कृपया जानकारी के बाद जानकारी की पुष्टि करें',
  OfficialTask_dailyCard_text6: 'पुरा हुआ',
  OfficialTask_rewardTime_text1: 'घंटे',
  OfficialTask_rewardTime_text2: 'मिनट',
  delete_account_web_title:
    'प्रिय आत्मा यू उपयोगकर्ता। हमारे उत्पाद का उपयोग करने और समर्थन करने के लिए धन्यवाद। यदि आप अपना खाता हटाना चाहते हैं, तो कृपया प्रक्रिया के लिए निम्नलिखित चरणों की जाँच करें:',
  delete_account_web_title_bibo: 'प्रिय आत्मा यू उपयोगकर्ता। हमारे उत्पाद का उपयोग करने और समर्थन करने के लिए धन्यवाद। यदि आप अपना खाता हटाना चाहते हैं, तो कृपया प्रक्रिया के लिए निम्नलिखित चरणों की जाँच करें:',
  delete_account_web1: '1. आवेदन खोलें और मुख्य पृष्ठ दर्ज करें, मुझे टैब दर्ज करने के लिए नीचे दाईं ओर क्लिक करें।',
  delete_account_web2: '2. मुझे टैब दर्ज करने के बाद सेटिंग्स पर क्लिक करें।',
  delete_account_web3: '3. सेटिंग्स दर्ज करने के बाद DELETE ACCOUNT पर क्लिक करें।',
  delete_account_web4: '4. स्थायी रूप से खाता हटाने के लिए पहले चरण की पुष्टि करें।',
  delete_account_web5: '5. एक ही खाते का उपयोग नहीं कर सकते हैं दूसरे चरण की पुष्टि करें।',
  delete_account_web6: '6. सभी संबंधित व्यक्तिगत जानकारी को हटाने के लिए अंतिम चरण की पुष्टि करें।',
  delete_account_web7:
    '7. उपरोक्त सभी चरणों को पूरा करने के बाद। आपकी सभी व्यक्तिगत जानकारी और खाता डेटा के साथ आपका खाता सफलतापूर्वक हटा दिया गया है। हमारा प्लेटफ़ॉर्म अब आपके डेटा को हमारे डेटाबेस में नहीं रखेगा।',
  delete_account_web: 'खाता हटाएं',
  svip_introduce_1: '1.SVIPक्या है?',
  svip_introduce_text1: 'एसवीआईपी प्लेटफॉर्म की विशिष्ट स्तर की प्रणाली है। कुल मिलाकर 8 स्तर हैं, SVIP1-SVIP8। स्तर जितना ऊँचा होगा, स्थिति उतनी ही अधिक प्रतिष्ठित होगी, और आपको उतने ही अधिक उन्नत अधिकार और हित प्राप्त होंगे।',
  svip_introduce_2: '2. SVIP कैसे बनें',
  svip_introduce_text2: 'आप सोने के सिक्कों को रिचार्ज करके SVIP अनुभव अंक प्राप्त कर सकते हैं, 1 सोने का सिक्का = 1 अनुभव बिंदु। जब अनुभव बिंदु SVIP के विभिन्न स्तरों तक पहुंचते हैं, तो आप संबंधित SVIP स्तर प्राप्त कर सकते हैं।',
  svip_introduce_3: '3. SVIP की वैधता अवधि की गणना कैसे करें',
  svip_introduce_text31: 'SVIP प्रत्येक प्राकृतिक माह को एक चक्र के रूप में लेता है, और प्रत्येक प्राकृतिक माह की पहली तारीख को, SVIP अनुभव अंक 0 से जमा होते हैं। SVIP अनुभव मूल्य द्वारा प्राप्त स्तर के अनुसार संबंधित SVIP स्तर प्राप्त किया जाएगा।',
  svip_introduce_text32: 'जिस समय से आप SVIP स्थिति प्राप्त करते हैं, आपकी स्थिति और विशेषाधिकार अगले महीने के अंत तक बरकरार रहेंगे। (उदाहरण के लिए: यदि SVIP3 18 मई को पहुंच जाता है, तो SVIP3 स्थिति 30 जून तक बढ़ा दी जाएगी।)',
  svip_introduce_text33: 'यदि आपका SVIP अनुभव मूल्य किसी प्राकृतिक महीने के भीतर उच्च स्तर पर पहुंच जाता है, तो इसे तुरंत अपग्रेड कर दिया जाएगा। (उदाहरण के लिए: यदि आप 18 मई को SVIP3 और 25 मई को SVIP4 पर पहुंचते हैं, तो आप तुरंत 25 मई को SVIP4 स्थिति प्राप्त कर लेंगे और 30 जून के अंत तक जारी रहेंगे।)',
  svip_introduce_text34: 'जब प्राकृतिक महीना समाप्त हो जाता है, तो पहचान और विशेषाधिकार बरकरार रहेंगे, लेकिन अगले महीने के लिए SVIP अनुभव अंक 0 से जमा हो जाएंगे। (उदाहरण के लिए: यदि आप 25 मई को SVIP4 पर पहुंचते हैं, तब भी आप पहचान और अधिकारों का आनंद लेंगे पूरे जून में SVIP4, लेकिन आप जून में 0 से SVIP अनुभव अंक जमा करना शुरू कर देंगे)',
  svip_introduce_4: '4.SVIP का पदावनति और पदावनति',
  svip_introduce_text41: 'प्रत्येक प्राकृतिक माह की शुरुआत में, यदि आपके पास एक SVIP स्तर है और आप अगले महीने में इस SVIP स्तर को बनाए रखना चाहते हैं, तो आपको प्राकृतिक माह के अंत से पहले SVIP स्तर के अनुभव मूल्य के 50% तक पहुंचने की आवश्यकता है, अर्थात आप अगले महीने इस स्तर को बरकरार रख सकते हैं, पहचान और अधिकार बरकरार रख सकते हैं। (उदाहरण के लिए: जब मई शुरू होता है, आप SVIP4 हैं, तो आपको जून के अंत तक SVIP4 स्तर को बनाए रखने के लिए पूरे मई में SVIP4 स्तर के अनुभव मूल्य के केवल 50% तक पहुंचने की आवश्यकता है।)',
  svip_introduce_text42: 'यदि SVIP स्तर के अनुभव मूल्य का 50% एक प्राकृतिक महीने के भीतर नहीं पहुंच पाता है, तो इसे अगले महीने में 1 स्तर से कम कर दिया जाएगा। (उदाहरण के लिए: मई की शुरुआत में, आप SVIP4 हैं, और पूरे मई में अनुभव मूल्य SVIP4 स्तर के 50% तक नहीं पहुंचता है, तो जून SVIP3 से शुरू होकर 1 स्तर तक गिर जाएगा।)',
  noble_introduce_title: 'महान नियमों का परिचय',
  noble_introduce_welcome: 'हमारी कुलीन व्यवस्था में शामिल होने के लिए आपका स्वागत है! एक कुलीन बनने से आप अधिक अद्वितीय विशेषाधिकारों और लाभों का आनंद ले सकेंगे। निम्नलिखित हमारी उत्कृष्ट व्यवस्था का उपयोगी परिचय है:',
  noble_introduce_1: '1.बड़प्पन क्या है?',
  noble_introduce_text1: 'कुलीनता एक विशेष स्थिति का प्रतीक है और इसे सात स्थिति स्तरों में विभाजित किया गया है, अर्थात् नाइट, बैरन, विस्काउंट, अर्ल, मार्क्विस, ड्यूक और किंग। बड़प्पन के विभिन्न स्तरों को अलग-अलग विशेषाधिकार प्राप्त हैं।',
  noble_introduce_2: '2.नेक कैसे बनें',
  noble_introduce_text2: 'सोने के सिक्कों को रिचार्ज करके रईसों को खरीदा जा सकता है, और खरीदारी को बड़प्पन पृष्ठ पर संबंधित स्तर का चयन करके पूरा किया जा सकता है। साथ ही, आप दूसरों से उपहार के माध्यम से भी महान स्थिति प्राप्त कर सकते हैं।',
  noble_introduce_3: '3. वैधता अवधि की गणना',
  noble_introduce_text3: 'नोबल स्टेटस खरीदारी की तारीख से 30 दिनों के लिए वैध है, और आप वैधता अवधि के दौरान नोबल विशेषाधिकारों का आनंद लेंगे। वैधता अवधि के अंत में विशेषाधिकार समाप्त हो जाएंगे।',
  noble_introduce_4: '4. दैनिक सिक्का वितरण निर्देश',
  noble_introduce_text4: 'सोने के सिक्कों के माध्यम से रईस खरीदने के बाद, आप हर दिन उपहार के रूप में सोने के सिक्के प्राप्त करने के पात्र हैं। हर दिन साइन इन करने के बाद आपको सोने के सिक्के प्राप्त होंगे। यदि आप उस दिन साइन इन नहीं करते हैं, तो आप उस दिन के सोने के सिक्के प्राप्त नहीं कर पाएंगे। दूसरों को रईस उपहार देते समय, प्राप्तकर्ता को दैनिक सिक्के प्राप्त करने का अधिकार प्राप्त होगा।',
  noble_introduce_5: '5. लगातार खरीदारी पर छूट',
  noble_introduce_text5: 'नोबल स्टेटस प्राप्त करने की अवधि के दौरान, और नोबल स्टेटस समाप्त होने के 10 दिनों के भीतर, वही नोबल स्टेटस अधिक अनुकूल कीमत पर फिर से खरीदा जा सकता है। यह योजना स्वचालित नहीं है और इसे प्रभावी बनाने के लिए आपको इसे मैन्युअल रूप से खरीदना होगा।',
  noble_introduce_6: '6. कुलीनों का उन्नयन',
  noble_introduce_text6: 'जब आपके पास पहले से ही अभिजात वर्ग का दर्जा है, तो आप उच्च-स्तरीय अभिजात वर्ग खरीद सकते हैं और सीधे नवीनतम लाभ प्राप्त कर सकते हैं। मौजूदा अभिजात वर्ग के शेष दिनों को शेष मूल्य के अनुसार नवीनतम स्तर में विलय कर दिया जाएगा, और नए अभिजात वर्ग के स्तर की वैधता अवधि बढ़ा दी जाएगी।',
  noble_introduce_7: '7. उपहार के रूप में अभिजात वर्ग भेजना',
  noble_introduce_text7: 'किसी अन्य व्यक्ति को नोबल देते समय, मूल कीमत दूसरे व्यक्ति को दी जाएगी, और प्राप्तकर्ता को दैनिक सिक्के और स्तर के अनुरूप अन्य अधिकार प्राप्त होंगे। यदि प्राप्तकर्ता के पास पहले से ही महान स्थिति है, तो वह सीधे उच्च-स्तरीय महान स्थिति का आनंद लेगा, और अन्य स्तरों को संबंधित अवधि के अनुसार परिवर्तित किया जाएगा।',
  vip_introduce_title: 'VIP नियमों का परिचय',
  vip_introduce_welcome: 'VIP बनने के लिए आपका स्वागत है! VIP आपको अधिक विशेषाधिकारों का आनंद लेने की अनुमति देगा। VIP के बारे में सहायता परिचय निम्नलिखित है:',
  vip_introduce_1: '1.VIP क्या है?',
  vip_introduce_text1: 'VIP एक उपयोगकर्ता विकास प्रणाली है। वर्तमान में 16 स्तर हैं, VIP-VIP6। VIP स्तर जितना ऊंचा होगा, आपको उतने ही अधिक अधिकार प्राप्त होंगे और प्रॉप्स भी उतने ही समृद्ध होंगे।',
  vip_introduce_2: '2.VIP कैसे बनें',
  vip_introduce_text2: 'आप सोने के सिक्कों को रिचार्ज करके VIP अनुभव अंक प्राप्त कर सकते हैं, 1 सोने का सिक्का = 1 अनुभव बिंदु। आप जितने अधिक सोने के सिक्के रिचार्ज करेंगे, आपको उतने अधिक अनुभव अंक मिलेंगे और VIP उच्च स्तर तक बढ़ जाएगा।',
  vip_introduce_3: '3.VIP वैधता अवधि',
  vip_introduce_text3: 'एक बार VIP दर्जा प्राप्त हो जाने के बाद, यह स्थायी रूप से मान्य होता है और VIP अनुभव अंक स्थायी रूप से जमा हो जाते हैं और इन्हें साफ़ नहीं किया जाएगा। जैसे-जैसे अनुभव अंक बढ़ते हैं, आपको उच्च VIP स्तर पर पदोन्नत किया जाएगा और अधिक स्तर के लाभों का आनंद लिया जाएगा।',
  vip_introduce_4: '4. VIP प्रॉप्स का उपयोग',
  vip_introduce_text4: 'आपके द्वारा प्राप्त किए गए वीआईपी स्तर के प्रॉप्स हमेशा आपके बैकपैक में संग्रहीत किए जाएंगे और किसी भी समय पहने जा सकते हैं। जब आप अपग्रेड करते हैं, तो आपके द्वारा प्राप्त निम्न-स्तरीय प्रॉप्स का उपयोग अभी भी किया जा सकता है।',
  svip_introduce_title: 'SVIP नियमों का परिचय',
  svip_introduce_welcome: 'SVIP बनने के लिए आपका स्वागत है!SVIP आपको अधिक विशेषाधिकारों का आनंद लेने की अनुमति देगा। SVIP का उपयोगी परिचय निम्नलिखित है:',
  svip_introduce_text43: 'यदि किसी प्राकृतिक महीने के भीतर कोई SVIP अनुभव अंक उत्पन्न नहीं होता है, तो अगले महीने में SVIP स्तर 3 स्तर तक गिर जाएगा। (उदाहरण के लिए: जब मई शुरू होता है, आप SVIP4 हैं, और पूरे मई में कोई अनुभव अंक उत्पन्न नहीं होते हैं, तो जून SVIP1 से शुरू होकर 3 स्तरों तक गिर जाएगा)',
  svip_introduce_5: '5. SVIP के अधिकार और हित',
  svip_introduce_text5: 'SVIP स्तर जितना ऊंचा होगा, आपको उतने ही अधिक अधिकार प्राप्त होंगे। जब स्तर बदलता है, तो आप नए स्तर के लाभों का आनंद लेंगे, और आपको प्राप्त होने वाले प्रोप पुरस्कारों को नए स्तर से अधिलेखित कर दिया जाएगा। अनुकूलित प्रॉप्स को अपग्रेड या डाउनग्रेड करते समय, आपको सक्रिय रूप से ग्राहक सेवा से संपर्क करना होगा और जारी होने से पहले अनुकूलन आवश्यकताओं को सामने रखना होगा। हालाँकि, यदि आप डाउनग्रेड करते हैं तो उन्हें जारी नहीं किया जाएगा। लेवल बढ़ने पर फ्री मैसेज और फ्री कॉल की संख्या बढ़ जाएगी। जब आप अपग्रेड करते हैं या स्तर बनाए रखते हैं तो संबंधित राशि जारी की जाएगी, लेकिन डाउनग्रेड करने पर जारी नहीं की जाएगी। प्रत्येक स्तर पर वितरित राशियाँ इस प्रकार हैं:',
  svip_table1: 'SVIP स्तर',
  vip_table1: 'VIP स्तर',
  svip_table2: 'मुफ़्त संदेश',
  svip_table3: 'मुफ्त कॉल',
  Anonymity_name: 'गुमनामी',
  Noble_Change_2: 'खरीद की शर्तें: निचली रैंक उच्च रैंक खरीद सकती है, लेकिन उच्च रैंक निचली रैंक नहीं खरीद सकती। उदाहरण के लिए, यदि आप वर्तमान में काउंट हैं, तो आप केवल मार्क्विस, ड्यूक या किंग ही खरीद सकते हैं।',
  Noble_Change_3: 'रूपांतरण तर्क: यदि आप पहले से ही एक कुलीन हैं, तो एक और कुलीन पद खरीदने के बाद, पुराने कुलीन पद की शेष वैधता अवधि सिक्का मूल्य अनुपात के आधार पर नए कुलीन पद में परिवर्तित हो जाएगी, और पुराना कुलीन पद अमान्य हो जाएगा।',
  Noble_Change_4: 'वैधता अवधि की गणना सूत्र: नए खरीदे गए अभिजात वर्ग की वैधता अवधि = वर्तमान अभिजात पद के शेष दिन * (वर्तमान अभिजात पद मूल्य / खरीदे गए अभिजात वर्ग रैंक मूल्य) + 30, दिनों की अंतिम संख्या को पूर्णांकित किया जाता है।',
  Noble_Change_5: 'उदाहरण: यदि आपके पास वर्तमान में एक विस्काउंट (99990 सिक्के/माह) है, 13 दिन बचे हैं, और आप आज एक काउंट (199990 सिक्के/माह) खरीदते हैं, तो भुगतान के बाद, काउंट की वैधता अवधि 13 * (99990/199990) होगी + 30 = 6.5 पूर्णांकित + 30 = 36 दिन।',
  rich_list: 'समृद्ध रैंकिंग',
  lucky_list: 'भाग्यशाली रैंकिंग',
  lucky_daily: 'दैनिक',
  lucky_weekly: 'साप्ताहिक',
  lucky_faq1: 'कैसे खेलने के लिए',
  lucky_faq2: 'खेल के नियमों',
  lucky_faq3: '1. एक भाग्यशाली उपहार देने के बाद, आपके पास 1-1000 गुना सोने का सिक्का इनाम पाने का मौका है;',
  lucky_faq4: '2. आप जितने अधिक भाग्यशाली उपहार कॉम्बो या बैच में भेजेंगे, जीतने की संभावना उतनी ही अधिक होगी;',
  lucky_faq5: '3. सोने के सिक्के के पुरस्कार वास्तविक समय में खाते की शेष राशि में वितरित किए जाएंगे, और जीतने का विवरण भाग्यशाली उपहार रिकॉर्ड में देखा जा सकता है;',
  lucky_faq6: '4. हर बार जब कोई भाग्यशाली उपहार दिया जाता है, तो लंगर को एक निश्चित अनुपात में हीरे भी मिलेंगे।',
  lucky_faq7: 'रैंकिंग नियम',
  lucky_faq8: '1. सांख्यिकीय नियम',
  lucky_faq9: 'दैनिक और साप्ताहिक गेमप्ले में भाग लेने वाले सबसे अधिक सोने के सिक्के खर्च करने वाले दस उपयोगकर्ता समृद्ध रैंकिंग पर होंगे, और सबसे अधिक जीतने वाले गुणक वाले दस उपयोगकर्ता भाग्यशाली रैंकिंग पर होंगे।',
  lucky_faq10: '2. सांख्यिकीय चक्र',
  lucky_faq11: 'दैनिक रैंकिंग: प्रतिदिन 0:00 से 24:00 बजे तक डेटा',
  lucky_faq12: 'साप्ताहिक रैंकिंग: प्रत्येक सोमवार 0:00 बजे से रविवार 24:00 बजे तक का डेटा',
  lucky_faq13: '3. रैंकिंग पुरस्कार',
  lucky_faq14: 'दैनिक और साप्ताहिक रैंकिंग में शीर्ष दस को नीचे दिखाए अनुसार पुरस्कार प्राप्त होंगे',
  lucky_faq15: 'दैनिक रैंकिंग पुरस्कार',
  lucky_faq16: 'श्रेणी',
  lucky_faq17: 'साप्ताहिक रैंकिंग पुरस्कार',
  Charge_Same_1: 'क्या आपने इस उपयोगकर्ता को कम समय में समान राशि का रिचार्ज भेजा है और लेनदेन सफल रहा है? क्या आप फिर से आगे बढ़ना चाहते हैं?',
  Charge_Same_2: 'भुगतान रद्द करें',
  Charge_Same_3: 'भुगतान के साथ आगे बढ़ें',
  Noble_Change_2: 'खरीद की शर्तें: निचली रैंक उच्च रैंक खरीद सकती है, लेकिन उच्च रैंक निचली रैंक नहीं खरीद सकती। उदाहरण के लिए, यदि आप वर्तमान में काउंट हैं, तो आप केवल मार्क्विस, ड्यूक या किंग ही खरीद सकते हैं।',
  Noble_Change_3: 'रूपांतरण तर्क: यदि आप पहले से ही एक कुलीन हैं, तो एक और कुलीन पद खरीदने के बाद, पुराने कुलीन पद की शेष वैधता अवधि सिक्का मूल्य अनुपात के आधार पर नए कुलीन पद में परिवर्तित हो जाएगी, और पुराना कुलीन पद अमान्य हो जाएगा।',
  Noble_Change_4: 'वैधता अवधि की गणना सूत्र: नए खरीदे गए अभिजात वर्ग की वैधता अवधि = वर्तमान अभिजात पद के शेष दिन * (वर्तमान अभिजात पद मूल्य / खरीदे गए अभिजात वर्ग रैंक मूल्य) + 30, दिनों की अंतिम संख्या को पूर्णांकित किया जाता है।',
  Noble_Change_5: 'उदाहरण: यदि आपके पास वर्तमान में एक विस्काउंट (99990 सिक्के/माह) है, 13 दिन बचे हैं, और आप आज एक काउंट (199990 सिक्के/माह) खरीदते हैं, तो भुगतान के बाद, काउंट की वैधता अवधि 13 * (99990/199990) होगी + 30 = 6.5 पूर्णांकित + 30 = 36 दिन।',
  Registr_optimization_1: 'पंजीकरण और लॉगिन मुद्दे',
  AnchorCenter_biList_interaction_text1: 'इंटरएक्टिव',
  AnchorCenter_biList_interaction_text2: 'गठबंधन बनाने वाले लोगों की संख्या (यूवी)',
  AnchorCenter_biList_interaction_text3: 'उत्तर दर (यूवी)',
  AnchorCenter_biList_interaction_text4: 'प्रतिक्रिया दर (पीवी)',
  AnchorCenter_biList_interaction_text5: 'प्रतिक्रिया दर (यूवी)',
  AnchorCenter_biList_interaction_text6: 'संदेश प्राप्त करें(यूवी)',
  AnchorCenter_biList_interaction_text7: 'उत्तर संदेश(यूवी)',
  AnchorCenter_biList_interaction_text8: 'उत्तर दर(यूवी)',
  AnchorCenter_biList_interaction_text9: 'संदेश भेजें(यूवी)',
  AnchorCenter_biList_interaction_text10: 'उत्तर दिया गया संदेश(यूवी)',
  AnchorCenter_biList_interaction_text11: 'उत्तर दर (यूवी)',
  AnchorCenter_biList_interaction_text12: 'संदेश भेजें(पीवी)',
  AnchorCenter_biList_interaction_text13: 'उत्तर दिया गया संदेश(पीवी)',
  AnchorCenter_biList_interaction_text14: 'उत्तर दर (पीवी)',
  AnchorCenter_biList_interaction_Popup1: "आपको 'x' उपयोगकर्ताओं से संदेश प्राप्त हुए हैं",
  AnchorCenter_biList_interaction_Popup2: "आपको 'x' उपयोगकर्ताओं से संदेश प्राप्त हुए हैं, और आपने 'n' उपयोगकर्ताओं को उत्तर दिया है।",
  AnchorCenter_biList_interaction_Popup3: 'उदाहरण: आपको 100 उपयोगकर्ताओं से जानकारी प्राप्त हुई, और आपने इन 100 उपयोगकर्ताओं में से 90 को प्रभावी ढंग से उत्तर दिया। आपकी प्रभावी प्रतिक्रिया दर (यूवी) = 90/100 = 90%।',
  AnchorCenter_biList_interaction_Popup4: 'संदेश उत्तर प्रतिशत (यूवी)',
  AnchorCenter_biList_interaction_Popup5: 'प्रभावी उत्तर दर: केवल उन उपयोगकर्ताओं की संख्या की गणना करें जिन्होंने समय-सीमा के भीतर उत्तर नहीं दिया',
  AnchorCenter_biList_interaction_Popup6: 'अच्छा ऐसा है',
  AnchorCenter_biList_interaction_Popup7: "आपने 'x' उपयोगकर्ताओं को अनचाहे संदेश भेजे हैं",
  AnchorCenter_biList_interaction_Popup8: "आपके द्वारा भेजे गए 'x' उपयोगकर्ताओं में से, 'n' उपयोगकर्ताओं ने आपको जवाब दिया",
  AnchorCenter_biList_interaction_Popup9: 'अन्य उपयोगकर्ताओं के उत्तरों का प्रतिशत (यूवी)',
  AnchorCenter_biList_interaction_Popup10: 'उदाहरण: आपने सक्रिय रूप से 100 उपयोगकर्ताओं को जानकारी भेजी है, और इन 100 उपयोगकर्ताओं में से 50 ने आपको उत्तर दिया है। आपकी प्रभावी उत्तर दर (यूवी) = 50/100 = 50%।',
  AnchorCenter_biList_interaction_Popup11: 'प्रभावी उत्तर दर: केवल उन उपयोगकर्ताओं की संख्या की गणना करें जिन्होंने समय-सीमा के भीतर उत्तर नहीं दिया',
  AnchorCenter_biList_interaction_Popup12: "आपने अनचाहे 'x' संदेश भेजे",
  AnchorCenter_biList_interaction_Popup13: "आपने जिन 'x' संदेशों को भेजने की पहल की, उनमें से 'n' का प्रभावी ढंग से उत्तर दिया गया।",
  AnchorCenter_biList_interaction_Popup14: 'अन्य उपयोगकर्ताओं के उत्तरों का प्रतिशत (पीवी)',
  AnchorCenter_biList_interaction_Popup15: 'उदाहरण: आपने सक्रिय रूप से 100 संदेश भेजे, और इन 100 संदेशों में से 70 का प्रभावी ढंग से उत्तर दिया गया। आपकी प्रभावी उत्तर दर (पीवी)=70/100=70%।',
  AnchorCenter_biList_interaction_Popup16: 'प्रभावी उत्तर दर: केवल उन संदेशों की संख्या गिनें जिनके उत्तर का समय समाप्त नहीं हुआ है',
  program_list_24_ios: 'मिटाना',
  withdraw_text_26: 'कृपया सही भुगतान जानकारी सत्यापित करें। गलत जानकारी के परिणामस्वरूप निकासी विफल हो जाएगी, और अतिरिक्त शुल्क उपयोगकर्ता को वहन करना होगा।',
  withdraw_text_29: 'संकेत देना:',
  withdraw_text_43: 'इस भुगतान विधि के ऑर्डर अधूरे हैं और इन्हें अस्थायी रूप से संशोधित या हटाया नहीं जा सकता है।',
  withdraw_text_44: 'सफलतापूर्वक जमा!',
  payment_bind_2: 'बैंक कार्ड',
  payment_bind_2_GPAY: 'बैंक कार्ड',
  payment_bind_3: 'बैंक का नाम',
  payment_bind_4: 'नाम',
  payment_bind_5: 'मेल',
  payment_bind_6: 'पता',
  payment_bind_7: '10 अंक या पीएल प्लस 10 अंक',
  payment_bind_8: 'TR + 24 अंकों से प्रारंभ होता है',
  payment_bind_9: '{n} आवश्यक',
  payment_bind_10: 'सफलतापूर्वक हटा दिया गया',
  payment_bind_11: 'पहला नाम',
  payment_bind_12: 'उपनाम',
  Tvwall_gift_9: ' 2,000 सोने के सिक्कों से अधिक या उसके बराबर मूल्य की संपूर्ण उपहार इच्छा सूची टीवी दीवार पर प्रदर्शित की जाएगी',
  Tvwall_gift_10: ' प्रवेश लॉक स्क्रीन शर्तें:',
  Tvwall_gift_32: 'क्या उपहार देना टीवी पर दिखाया जाना चाहिए?',
  Tvwall_gift_33: '(तुरंत प्रभावी होने के लिए चयन करने के लिए क्लिक करें)',
  Tvwall_gift_11: '10 सेकंड के लिए स्क्रीन लॉक करें: एक इच्छा उपहार पूरा हो गया है और इच्छा उपहार का मूल्य 2000 सोने के सिक्कों और 9999 सोने के सिक्कों के बीच है;',
  Tvwall_gift_12: '20 सेकंड के लिए लॉक स्क्रीन: एक इच्छा उपहार पूरा किया और इच्छा उपहार का मूल्य 10,000 सोने के सिक्कों और 49,999 सोने के सिक्कों के बीच है;',
  Tvwall_gift_13: '60 सेकंड के लिए लॉक स्क्रीन: एक इच्छा उपहार पूरा किया और इच्छा उपहार का मूल्य 50,000 सोने के सिक्कों से अधिक है',
  Tvwall_gift_14: 'वर्ल्ड टीवी वॉल पर प्रदर्शित होने की शर्तें:',
  Tvwall_gift_15: 'साधारण रिकॉर्ड: एक इच्छा उपहार पूरा किया और इच्छा उपहार का मूल्य 2000 सोने के सिक्कों और 9999 सोने के सिक्कों के बीच है',
  Tvwall_gift_16: 'शानदार रिकॉर्ड: एक इच्छा उपहार पूरा हुआ और इच्छा उपहार का मूल्य 10,000 सोने के सिक्कों से अधिक या उसके बराबर है',
  Tvwall_gift_17: 'टीवी की दीवार पर',
  Tvwall_gift_18: 'टीवी वॉल पर गुमनाम',
  Tvwall_gift_19: 'टीवी वॉल पर नहीं',
  Admob_text7: 'तैयारी में',
  Admob_text8: 'वीडियो अभी तैयार नहीं है',
  EXP: 'EXP',
  Experience_Card_Statement: '*अनुभव कार्ड में यह लाभ नहीं है',
  Official_website_payment_1: 'सोया ऑनलाइन रिचार्ज',
  Official_website_payment_2: 'रिचार्ज आईडी',
  Official_website_payment_3: 'आईडी दर्ज करें',
  Official_website_payment_4: 'आईडी क्या है?',
  Official_website_payment_5: 'कृपया खाते की गोपनीयता और सुरक्षा की रक्षा के लिए पहले जांच लें।',
  Official_website_payment_6: 'सवाल',
  Official_website_payment_7: 'उपयोगकर्ता निर्देश',
  Official_website_payment_8: '1. अनावश्यक विवादों से बचने के लिए कृपया अनौपचारिक या अनधिकृत वेबसाइटों के माध्यम से SOYO सिक्के न खरीदें।',
  Official_website_payment_9: '2. कृपया अन्य वेबसाइटों, संचार चैनलों या संचार समूहों पर प्रकाशित अनौपचारिक जानकारी पर विश्वास न करें। सभी घटना संबंधी जानकारी आधिकारिक वेबसाइट या सिस्टम में घोषणाओं के अधीन होगी।',
  Official_website_payment_10: '3. किसी भी ऑफ़लाइन लेनदेन का समर्थन नहीं करता।',
  Official_website_payment_11: '4. कृपया किसी भी कारण से या किसी भी तरह से अपने खाते की जानकारी दूसरों को न बताएं।',
  Official_website_payment_12: '5. SOYO उपयोगकर्ताओं और तीसरे पक्षों के बीच विवादों के लिए कोई ज़िम्मेदारी नहीं लेता है।',
  Official_website_payment_13: '6. यदि आपको रिचार्जिंग में समस्या आती है, तो कृपया ग्राहक सेवा से संपर्क करें या हमें ईमेल करें।',
  Official_website_payment_14: 'मनी लॉन्ड्रिंग विरोधी',
  Official_website_payment_15: '1. उपयोगकर्ताओं को यह सुनिश्चित करना होगा कि उनके पास कानूनी पहचान और हमारे द्वारा प्रदान की जाने वाली सेवाओं का उपयोग करने की क्षमता है, और वे किसी भी आपराधिक गतिविधियों में भाग नहीं लेंगे या उनसे जुड़े नहीं होंगे।',
  Official_website_payment_16: '2. उपयोगकर्ता खाता केवल आपके व्यक्तिगत उपयोग के लिए है और व्यक्तिगत रूप से आपका है। आप अपने खाते को किसी तीसरे पक्ष को हस्तांतरित, पट्टे पर या अन्यथा निपटान नहीं कर सकते हैं।',
  Official_website_payment_17: '3. उपयोगकर्ताओं को यह सुनिश्चित करना होगा कि रिचार्जिंग के लिए उपयोग किए गए सभी फंड कानूनी चैनलों के माध्यम से प्राप्त किए गए हैं और किसी भी समय आपराधिक गतिविधियों या व्यवहार से संबंधित नहीं हैं।',
  Official_website_payment_18: 'भुगतान विधि',
  Official_website_payment_19: 'वर्तमान में {%s} भुगतान चैनल का उपयोग कर रहा हूँ',
  Official_website_payment_20: 'रिचार्ज विकल्प',
  Official_website_payment_21: 'परिवर्तन',
  Official_website_payment_22: 'खाता आईडी मौजूद नहीं है!',
  Official_website_payment_23: 'अभी तक कोई समर्थित भुगतान विधि नहीं है',
  Official_website_payment_24: 'अपनी इच्छित भुगतान विधि पर प्रतिक्रिया दें',
  Official_website_payment_25: 'लेन-देन के परिणाम पूछना',
  Official_website_payment_26: 'रिचार्ज परिणाम की पुष्टि की जानी है',
  Official_website_payment_27: 'रिचार्ज परिणाम में देरी हो सकती है। खाता रिचार्ज रिकॉर्ड की जांच करने के लिए कृपया ऐप दर्ज करें। यदि खाता लंबे समय तक नहीं आता है, तो कृपया ग्राहक सेवा से संपर्क करें।',
  Official_website_payment_28: 'ताज़ा',
  Official_website_payment_29: 'रिचार्ज विफल रहा',
  Official_website_payment_30: 'सहायता जानकारी:',
  Official_website_payment_31: '1. कृपया पुष्टि करें कि व्यक्तिगत जानकारी, क्रेडिट कार्ड नंबर, वैधता अवधि, सीवीवी कोड इत्यादि सहित राशि और भुगतान विधि सही है या नहीं।',
  Official_website_payment_32: '2. कृपया जांच लें कि खाते में शेष राशि पर्याप्त है या नहीं।',
  Official_website_payment_33: '3. जांचें कि नेटवर्क कनेक्शन स्थिर है या नहीं। कभी-कभी नेटवर्क समस्याओं के कारण भुगतान विफल हो सकता है।',
  Official_website_payment_34: '4. अन्य भुगतान विधियों को बदलने का प्रयास करें या बाद में रिचार्ज करने का प्रयास करें।',
  Official_website_payment_35: '5. यदि समस्या अभी भी हल नहीं हुई है, तो आगे की मदद के लिए रिचार्ज प्लेटफ़ॉर्म की ग्राहक सेवा से संपर्क करने की अनुशंसा की जाती है।',
  Official_website_payment_36: 'रिचार्ज सफल',
  Official_website_payment_37: 'खाता रिचार्ज रिकॉर्ड देखने के लिए कृपया ऐप दर्ज करें',
  Official_website_payment_38: 'लिंक टाइमआउट विफल रहता है',
  Official_website_payment_39: 'लिंक की समय सीमा समाप्त हो गई है। कृपया ऐप में भुगतान लिंक पुनः बनाएं।',
  payment_bind_13: 'Contract Address',
  payment_bind_14: 'Epay Email',
  payment_bind_15: 'Note: TRC-20 protocol',
  payment_bind_16: 'Note: Ensure your Epay account is verified',
  payment_bind_17: 'Contract address starts with T',
  payment_bind_18: 'Cannot exceed 40 characters',
  payment_bind_19: 'Must match your Epay account email',
  payment_bind_20: 'Enter email address',
  payment_bind_21: 'Gcash account',
  payment_bind_22: 'Must be 11 digits starting with 09',
  payment_bind_23: 'Only allow English letters, spaces, hyphens (-), periods (.), and commas (,) and less than 100 characters.',
  task_miss: 'याद',
  re_signing: 'फिर से हस्ताक्षर करने के',
  anchor_task_txt1: '({%s1}) बार पुराने मित्रों से मिलें (और उनसे उत्तर प्राप्त करें) और ({%s2}) हीरे प्राप्त करें',
  anchor_task_txt2: 'नए उपयोगकर्ताओं के साथ सत्रों की संख्या > 5 है, {%s1} गुना तक पहुँचना, और {%s2} हीरे प्राप्त करना',
  task_txt21: 'दैनिक चेक-इन',
  task_txt22: 'आकाश',
  task_txt23: 'दाखिल करना',
  task_txt24: 'कार्य सूची',
  task_txt25: 'एंकर कार्य',
  task_txt31: 'साइन इन किया गया',
  sign_in_text3_new: 'साइन-इन करें 2x इनाम पाएं',
  Double_Rewards_text1: 'डबल इनाम पाने के लिए SVIP बनें!',
  program_faq_2: 'कार्यक्रम अनुसूची परिचय',
  program_faq_3:
    'मज़ा और अन्तरक्रियाशीलता बढ़ाने के लिए, हम एक कार्यक्रम अनुसूची सुविधा प्रदान करते हैं। प्रोग्राम शेड्यूल कमरे के आगामी कार्यक्रमों के पूर्वावलोकन के रूप में कार्य करता है। दर्शक कार्यक्रम अनुसूची के आधार पर उपहार भेज सकते हैं और कमरे के कार्यक्रमों के लिए अपनी प्राथमिकताएं और उत्साह व्यक्त करते हुए, कार्यक्रम अनुसूची के आधार पर उपहार भेज सकते हैं।',
  program_faq_4: 'कार्यक्रम अनुसूची प्रवेश',
  program_faq_5:
    'कमरे के मालिक या व्यवस्थापक सेटिंग्स मेनू के माध्यम से प्रोग्राम शेड्यूल तक पहुंच सकते हैं। कार्यक्रम लाइव होने पर कमरे के शीर्ष दाएं कोने पर क्लिक करके दर्शक कार्यक्रम अनुसूची में प्रवेश कर सकते हैं।',
  program_faq_6: 'प्रोग्राम शेड्यूल कैसे सेट करें',
  program_faq_7: '"प्रोग्राम जोड़ें" पर क्लिक करें और उस कार्यक्रम का नाम दर्ज करें जिसे आप प्रदर्शन करना चाहते हैं।',
  program_faq_8:
    'प्रोग्राम शेड्यूल बनाने और समीक्षा करने के बाद, इसे दर्शकों को दृश्यमान बनाने के लिए "स्टार्ट प्रोग्राम शेड्यूल" पर क्लिक करें। एक बार सक्षम होने के बाद, दर्शक प्रोग्राम शेड्यूल देख पाएंगे।',
  program_faq_9: 'कार्यक्रम अनुसूची के साथ कैसे बातचीत करें',
  program_faq_10:
    'कमरे में हर कोई शीर्ष दाएं कोने पर क्लिक करके कार्यक्रम अनुसूची का उपयोग कर सकता है और कार्यक्रम की लोकप्रियता बढ़ाने के लिए "इच्छुक" या "उपहार भेजें" पर क्लिक करके अपने पसंदीदा कार्यक्रमों के लिए समर्थन दिखा सकता है।',
  program_faq_11: 'एक कार्यक्रम हटाएं या प्रोग्राम शेड्यूल को बंद करें।',
  program_faq_12:
    'कमरे के मालिक या व्यवस्थापक एक कार्यक्रम पर बाईं ओर स्वाइप कर सकते हैं और कार्यक्रम को हटाने के लिए "हटाएं" पर क्लिक कर सकते हैं। प्रोग्राम शेड्यूल 7 दिनों के बाद स्वचालित रूप से बंद हो जाएगा, लेकिन होस्ट या व्यवस्थापक भी इसे मैन्युअल रूप से बंद कर सकते हैं। किसी प्रोग्राम को हटाने या प्रोग्राम शेड्यूल को बंद करने से प्रोग्राम लोकप्रियता डेटा को साफ कर दिया जाएगा।',
  program_faq_13: 'सुझावों',
  program_faq_14: '1. कार्यक्रम अनुसूची अधिकतम 15 कार्यक्रमों को जोड़ने की अनुमति देती है।',
  program_faq_15: '2. एक प्रोग्राम शेड्यूल जो समीक्षा में विफल हो गया है, उसे नहीं खोला जा सकता है।',
  program_faq_16: '3. प्रोग्राम शेड्यूल खुलने के 7 दिन बाद अपने आप बंद हो जाएगा।',
  program_faq_part1: 'पार्ट 1',
  program_faq_part2: 'पार्ट 2',
  program_faq_part3: 'पार्ट 3',
  program_faq_part4: 'पार्ट 4',
  'family.level.instructions': 'परिवार लेवल का विवरण',
  'family.division.privilege': 'लेवल & लेवल विशेषाधिकार',
  'family.division.privilege.describe':
    'लेवल मासिक EXP पर आधारित है, प्रत्येक माह की पहली तारीख को रीसेट किया जाता है। लेवल अपग्रेड, रिटेन और डाउनग्रेड कर सकता है। वर्तमान लेवल को बनाए रखने के लिए केवल संबंधित अपग्रेड EXP का 100% प्राप्त करना आवश्यक है।',
  'family.diamonds.exch': 'डायमंड एक्सचेंज.',
  'family.new.diamonds.exch': 'बैज',
  'family.label.tag': 'फैमली टैग',
  'family.label.frame': 'पारिवारिक फ़्रेम',
  'family.label.chatroom': 'चैटरूम Bg',
  'family.label.familyBg': 'परिवार Bg',
  'family.bronze.family': 'कांस्य परिवार',
  'family.silver.family': 'रजत परिवार',
  'family.golden.family': 'स्वर्ण परिवार',
  'family.diamond.family': 'हीरा परिवार',
  'family.king.family': 'राजा परिवार',
  'family.task.daily.exp': 'दैनिक EXP',
  'family.task.monthly.exp': 'मासिक EXP',
  'family.task.exp.describe': 'जो EXP आप परिवार में योगदान करते हैं',
  'family.task.tips': 'जिन लोगों को परिवार में शामिल होते हैं, 2 दिन के अंदर में जब टास्क को पूरा करते हैं, तब दोहरा EXP पाते हैं',
  'family.task.invite.join': 'मित्रों को परिवार में शामिल होने के लिए आमंत्रित करें',
  'family.task.treasure.unlocked': 'यदि सदस्यों की संख्या %s तक पहुंचती है, तो परिवार की खजाने की संदूक हर दिन सुबह 0 बजे खोली जाएगी।',
  'family.task.your.rewards': 'आपके पुरस्कार',
  'family.task.rules.first': 'परिवार खजाने की शर्तों को पूरा करने के बाद, खजाने के बक्से में पुरस्कार यादृच्छिक रूप से खोले जाएंगे।',
  'family.task.rules.second': 'खजाना खुलने से पहले कार्य पूरा करने वाले सदस्यों को पुरस्कार मिलेगा।',
  'family.task.rules.three': 'आइटम विजेता बैग में वितरित किए जाएंगे, और अन्य पुरस्कार जैसे EXP या सिक्के खातों में वितरित किए जाएंगे।',
  'family.task.rules.four': 'खजाना खुलने के बाद पुरस्कार स्वचालित रूप से वितरित किए जाएंगे',
  'family.task.captain.task': 'कप्तान टास्क',
  'family.task.treasure.chest': 'परिवार खजाना संदूक',
  'family.task.interactive': 'इंटरएक्टिव टास्क',
  'family.task.cumulative': 'संचयी कार्य',
  'family.task.others': 'अन्य',
  'family.task.your.rewards': 'आपके पुरस्कार',
  'family.task.family.level': 'परिवार लेवल संचित व्यय पर आधारित है। प्रत्येक स्तर निम्नलिखित विशेषाधिकार से मेल खाता है।',
  'family.exp.title': 'पारिवारिक अनुभव',
  'family.exp.content.title.1': 'परिवार EXP कैसे प्राप्त करें?',
  'family.exp.content1.text.title.1': 'परिवार के सदस्य उपहार देते हैं',
  'family.exp.content1.text.1': '10 सिक्के',
  'family.exp.content1.text.2': '1 अनुभव',
  'family.exp.content1.text.3': '50 रत्न',
  'family.exp.content1.text.title.2': 'परिवार के सदस्यों को उपहार मिलते हैं',
  'family.exp.content1.text.title.3': 'पारिवारिक कार्य पूर्ण करें',
  'family.exp.content.title.2': 'पारिवारिक स्तर और स्तर का विशेषाधिकार',
  'family.exp.table.title.1': 'अनुभव',
  'family.exp.table.title.2': 'सदस्य',
  'family.exp.table.title.3': 'सह कप्तान',
  'family.exp.table.title.4': 'प्रशासन',
  'game.task.center.get': 'पाएं',
  'game.task.center.received': 'प्राप्त हो गया',
  'common.EXP': 'EXP',
  "childrens.safety.policy": "Child Safety",
};

export default HI;
