// @ts-nocheck
import React, { Suspense, lazy } from 'react';
import { Navigate } from 'react-router-dom';
import history from '@/asset/js/history';
import Loading from '@/component/loading';
import { getValueFromUA } from '@/asset/js/utils';

// const Home = lazy(() => import('@/page/home'));
const Main = lazy(() => import('@/page/main'));
// const PrivacyPolicy = lazy(() => import('@/page/privacyPolicy'));
const PrivacyPolicyBobo = lazy(() => import('@/page/privacyPolicyBobo'));
// const TermsAndConditions = lazy(() => import('@/page/terms&Conditions'));
const TermsAndConditionsBobo = lazy(() => import('@/page/terms&ConditionsBobo'));
// const About = lazy(() => import('@/page/about'));
// const Report = lazy(() => import('@/page/report'));
// const Feedback = lazy(() => import('@/page/feedback'));
// const TotalRankVoice = lazy(() => import('@/page/totalRank'));
// const GiftWall = lazy(() => import('@/page/giftWall'));
// const GoldCoin = lazy(() => import('@/page/goldCoin'));
// const Level = lazy(() => import('@/page/level'));
// const AboutBadges = lazy(() => import('@/page/aboutBadges'));
// const CoinMerchant = lazy(() => import('@/page/coinMerchant'));
// const NewAristocracy = lazy(() => import('@/page/curAristocracy'));
// const VipPrivileges = lazy(() => import('@/page/vipPrivileges'));
// const VipHelp = lazy(() => import('@/page/vipHelp'));
// const TotalRank = lazy(() => import('@/page/totalRankAll'));
// const MyEarning = lazy(() => import('@/page/myEarning'));
// const DiamondsFlow = lazy(() => import('@/page/diamondsFlow'));
// const Share = lazy(() => import('@/page/share'));
// const RankingList = lazy(() => import('@/page/rankingList'));
// const LuckyPacket = lazy(() => import('@/page/luckyPacketHelp'));
// const NewTask = lazy(() => import('@/page/newTask'));
// const CommunityRules = lazy(() => import('@/page/communityRules'));
// const NobleRules = lazy(() => import('@/page/nobleRules'));
// const VipRules = lazy(() => import('@/page/vipRules'));
// const SVipRules = lazy(() => import('@/page/sVipRules'));
// const LuckyGiftList = lazy(() => import('@/page/luckyGiftList'));
// const NewcomerGuide = lazy(() => import('@/page/newcomerGuide'));
// const WithdrawBind = lazy(() => import('@/page/withdrawBind'));
// const OfficialRule = lazy(() => import('@/page/officialRule'));
// const TvWallRule = lazy(() => import('@/page/tvWallRule'));
// const TiktokLogin = lazy(() => import('@/page/tiktokLogin'));
// const Recharge = lazy(() => import('@/page/recharge'));
// const RechargeResult = lazy(() => import('@/page/rechargeResult'));
// const LumosPrivacyPolicy = lazy(() => import('@/page/lumosPrivacyPolicy'));
const Airtel = lazy(() => import('@/page/airtel'));
const Concat = lazy(() => import('@/page/concat'));
const Home = lazy(() => import(/* webpackChunkName: "home" */'@/page/home'));
const PrivacyPolicy = lazy(() => import(/* webpackChunkName: "privacyPolicy" */'@/page/privacyPolicy'));
const TermsAndConditions = lazy(() => import(/* webpackChunkName: "terms&Conditions" */'@/page/terms&Conditions'));
const About = lazy(() => import(/* webpackChunkName: "about" */'@/page/about'));
const Report = lazy(() => import(/* webpackChunkName: "report" */'@/page/report'));
const Feedback = lazy(() => import(/* webpackChunkName: "feedback" */'@/page/feedback'));
const TotalRankVoice = lazy(() => import(/* webpackChunkName: "totalRank" */'@/page/totalRank'));
const GiftWall = lazy(() => import(/* webpackChunkName: "giftWall" */'@/page/giftWall'));
const GoldCoin = lazy(() => import(/* webpackChunkName: "goldCoin" */'@/page/goldCoin'));
const Level = lazy(() => import(/* webpackChunkName: "level" */'@/page/level'));
const AboutBadges = lazy(() => import(/* webpackChunkName: "aboutBadges" */'@/page/aboutBadges'));
const CoinMerchant = lazy(() => import(/* webpackChunkName: "coinMerchant" */'@/page/coinMerchant'));
const NewAristocracy = lazy(() => import(/* webpackChunkName: "curAristocracy" */'@/page/curAristocracy'));
const VipPrivileges = lazy(() => import(/* webpackChunkName: "vipPrivileges" */'@/page/vipPrivileges'));
const VipHelp = lazy(() => import(/* webpackChunkName: "vipHelp" */'@/page/vipHelp'));
const TotalRank = lazy(() => import(/* webpackChunkName: "totalRankAll" */'@/page/totalRankAll'));
const MyEarning = lazy(() => import(/* webpackChunkName: "myEarning" */'@/page/myEarning'));
const DiamondsFlow = lazy(() => import(/* webpackChunkName: "diamondsFlow" */'@/page/diamondsFlow'));
const Share = lazy(() => import(/* webpackChunkName: "share" */'@/page/share'));
const RankingList = lazy(() => import(/* webpackChunkName: "rankingList" */'@/page/rankingList'));
const LuckyPacket = lazy(() => import(/* webpackChunkName: "luckyPacketHelp" */'@/page/luckyPacketHelp'));
const NewTask = lazy(() => import(/* webpackChunkName: "newTask" */'@/page/newTask'));
const CommunityRules = lazy(() => import(/* webpackChunkName: "communityRules" */'@/page/communityRules'));
const NobleRules = lazy(() => import(/* webpackChunkName: "nobleRules" */'@/page/nobleRules'));
const VipRules = lazy(() => import(/* webpackChunkName: "vipRules" */'@/page/vipRules'));
const SVipRules = lazy(() => import(/* webpackChunkName: "sVipRules" */'@/page/sVipRules'));
const LuckyGiftList = lazy(() => import(/* webpackChunkName: "luckyGiftList" */'@/page/luckyGiftList'));
const NewcomerGuide = lazy(() => import(/* webpackChunkName: "newcomerGuide" */'@/page/newcomerGuide'));
const WithdrawBind = lazy(() => import(/* webpackChunkName: "withdrawBind" */'@/page/withdrawBind'));
const OfficialRule = lazy(() => import(/* webpackChunkName: "officialRule" */'@/page/officialRule'));
const TvWallRule = lazy(() => import(/* webpackChunkName: "tvWallRule" */'@/page/tvWallRule'));
const TiktokLogin = lazy(() => import(/* webpackChunkName: "tiktokLogin" */'@/page/tiktokLogin'));
const Recharge = lazy(() => import(/* webpackChunkName: "recharge" */'@/page/recharge'));
const RechargeResult = lazy(() => import(/* webpackChunkName: "rechargeResult" */'@/page/rechargeResult'));
const LumosPrivacyPolicy = lazy(() => import(/* webpackChunkName: "lumosPrivacyPolicy" */'@/page/lumosPrivacyPolicy'));
const ChatSkill = lazy(() => import(/* webpackChunkName: "chatSkill" */'@/page/chatSkill'));
const NewUserRewards = lazy(() => import(/* webpackChunkName: "newUserRewards" */'@/page/newUserRewards'));
const ChildrenPolicy = lazy(() => import(/* webpackChunkName: "childrenPolicy" */'@/page/childrenPolicy'));
const ProgramList = lazy(() => import(/* webpackChunkName: "programList" */'@/page/programList'));
const Airwallex = lazy(() => import(/* webpackChunkName: "airwallex" */'@/page/airwallex'));
const FamilyTask = lazy(() => import(/* webpackChunkName: "familyTask" */'@/page/familyTask'));
const NewFamilyExp = lazy(() => import(/* webpackChunkName: "newFamilyExp" */'@/page/newFamilyExp'));
const Convention = lazy(() => import(/* webpackChunkName: "convention" */'@/page/convention'));
const Mpesa = lazy(() => import(/* webpackChunkName: "mpesa" */'@/page/mpesa'));
const AccountIllustrate = lazy(() => import(/* webpackChunkName: "accountIllustrate" */'@/page/accountIllustrate'));
const Help = lazy(() => import(/* webpackChunkName: "help" */'@/page/help'));
const IncomeRules = lazy(() => import(/* webpackChunkName: "incomeRules" */'@/page/incomeRules'));
const RetirarRules = lazy(() => import(/* webpackChunkName: "retirarRules" */'@/page/retirarRules'));

const BiboTerms = lazy(() => import(/* webpackChunkName: "biboTerms" */'@/page/biboTerms'));
const BiboPrivacyPolicy = lazy(() => import(/* webpackChunkName: "biboPrivacyPolicy" */'@/page/biboPrivacyPolicy'));
const BiboChildrenPolicy = lazy(() => import(/* webpackChunkName: "biboChildrenPolicy" */'@/page/biboChildrenPolicy'));
const BiboHome = lazy(() => import(/* webpackChunkName: "biboHome" */'@/page/biboHome'));
const BiboAccountIllustrate = lazy(() => import(/* webpackChunkName: "biboAccountIllustrate" */'@/page/biboAccountIllustrate'));

const pkg = window.location.origin;
const isBibo = pkg && pkg.includes("bibo");
// const isBibo = true;

const authcheck = Com => {
  return (
    // <ErrorBoundary>
    <Suspense fallback={<Loading />}>
      <Com history={history} />
    </Suspense>
    // </ErrorBoundary>
  );
};

const suspense = (Com, params) => {
  return (
    <Suspense fallback={<Loading />}>
      <Com {...params} />
    </Suspense>
  )
}

const routeList = [
  {
    path: '/',
    element: <Navigate to={isBibo ? '/biboHome' : '/home'} />,
  },
  {
    path: '/lucky_gift_list/*',
    element: suspense(LuckyGiftList),
  },
  {
    path: '/termService',
    element: suspense(TermsAndConditions)
  },
  {
    path: '/coin_record',
    element: authcheck(GoldCoin),
  },
  {
    path: '/home_rank/*',
    element: suspense(TotalRankVoice),
  },
  {
    path: '/home_giftWall/*',
    element: authcheck(GiftWall),
  },
  {
    path: '/privacyPolicy',
    element: suspense(PrivacyPolicy),
  },
  {
    path: '/home',
    element: suspense(Home),
  },
  {
    path: '/AppInfoPage',
    element: suspense(About),
  },
  {
    path: '/userReport',
    element: suspense(Report, { type: 'user' }),
  },
  {
    path: '/report',
    children: [
      {
        path: 'user',
        element: suspense(Report, { type: 'user' }),
      },
      {
        path: 'room',
        element: suspense(Report, { type: 'room' }),
      },
      {
        path: 'family',
        element: suspense(Report, { type: 'family' }),
      },
    ],
  },
  {
    path: '/roomReport',
    // element: <Report type='room' />,
    element: suspense(Report, { type: 'room' }),
  },
  {
    path: '/familyReport',
    // element: <Report type='room' />,
    element: suspense(Report, { type: 'family' }),
  },
  {
    path: '/userFeedBack',
    element: suspense(Feedback),
  },
  {
    path: '/feed_back',
    element: suspense(Feedback),
  },
  {
    path: '/mine_level',
    element: authcheck(Level),
  },
  {
    path: '/mine_badges',
    element: authcheck(AboutBadges),
  },
  {
    path: '/mine_coins/*',
    element: suspense(CoinMerchant),
  },
  {
    path: '/new_aristocracy',
    element: suspense(NewAristocracy),
  },
  {
    path: '/vip_privileges',
    element: authcheck(VipPrivileges),
  },
  {
    path: '/vip_help',
    element: authcheck(VipHelp),
  },
  {
    path: '/home_rank_voice/*',
    element: authcheck(TotalRankVoice),
  },
  {
    path: '/mine_received',
    element: authcheck(MyEarning),
  },
  {
    path: '/mine_received_records/*',
    element: authcheck(DiamondsFlow),
  },
  {
    path: '/room_share',
    element: authcheck(Share),
  },
  {
    path: '/gift_ranking_list',
    element: authcheck(RankingList),
  },
  {
    path: '/packet_rule',
    element: authcheck(LuckyPacket),
  },
  {
    path: '/official_rules',
    element: authcheck(OfficialRule),
  },
  {
    path: '/home_rank_all/*',
    element: suspense(TotalRank),
  },
  {
    path: '/mine_task',
    element: suspense(NewTask),
  },
  {
    path: '/community_norms',
    element: suspense(CommunityRules),
  },
  {
    path: '/noble_rules',
    element: authcheck(NobleRules),
  },
  {
    path: '/vip_rules',
    element: authcheck(VipRules),
  },
  {
    path: '/svip_rules',
    element: authcheck(SVipRules),
  },
  {
    path: '/newcomer_guide',
    element: authcheck(NewcomerGuide),
  },
  {
    path: '/withdraw_bind',
    element: authcheck(WithdrawBind),
  },
  {
    path: '/tv_wall_rules',
    element: suspense(TvWallRule),
  },
  {
    path: '/tiktok_login',
    element: authcheck(TiktokLogin),
  },
  {
    path: '/recharge/*',
    element: suspense(Recharge),
  },
  {
    path: '/recharge_result',
    element: suspense(RechargeResult),
  },
  {
    path: '/lumos_privacyPolicy',
    element: suspense(LumosPrivacyPolicy),
  },
  {
    path: '/airtel/*',
    element: suspense(Airtel),
  },
  {
    path: '/chat_skill',
    element: suspense(ChatSkill),
  },
  {
    path: '/new_user_rewards',
    element: suspense(NewUserRewards),
  },
  {
    path: '/child_policy',
    element: suspense(ChildrenPolicy),
  },
  {
    path: '/program_list',
    element: suspense(ProgramList),
  },
  {
    path: '/airwallex',
    element: suspense(Airwallex),
  },
  {
    path: '/family_task',
    element: suspense(FamilyTask),
  },
  {
    path: '/new_family_exp',
    element: suspense(NewFamilyExp),
  },
  {
    path: '/community_convention',
    element: suspense(Convention),
  },
  {
    path: '/mpesa/*',
    element: suspense(Mpesa),
  },
  {
    path: '/account_illustrate',
    element: suspense(AccountIllustrate),
  },
  {
    path: '/help/*',
    element: suspense(Help),
  },
  {
    path: '/income_rules',
    element: suspense(IncomeRules),
  },
  {
    path: '/retirar_rules',
    element: suspense(RetirarRules),
  },
  {
    path: '/biboTerms',
    element: suspense(BiboTerms),
  },
  {
    path: '/biboPrivacyPolicy',
    element: suspense(BiboPrivacyPolicy),
  },
  {
    path: '/biboChildrenPolicy',
    element: suspense(BiboChildrenPolicy),
  },
  {
    path: '/biboHome',
    element: suspense(BiboHome),
  },
  {
    path: '/biboAccount',
    element: suspense(BiboAccountIllustrate),
  },
];

export default routeList;
