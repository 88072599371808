import { mpesaPay, mpesaCheck } from '@/services/api';

const mpesa = {
  namespace: 'mpesa',
  state: {},
  reducers: {},
  effects: {
    *mpesaPay({ payload }, { call }) {
      const res = yield call(mpesaPay, payload);
      return res;
    },
    *mpesaCheck({ payload }, { call }) {
      const res = yield call(mpesaCheck, payload);
      return res?.data;
    },
  },
};

export default mpesa;

